import { Component, OnInit, Pipe, PipeTransform, ViewChild, ElementRef, Input, Inject } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Lightbox } from 'ngx-lightbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';
import { MatDialog, MatTable } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-ged',
  templateUrl: './ged.component.html',
  styleUrls: ['./ged.component.scss']
})
export class GedComponent implements OnInit {

  @ViewChild('paginator', {static: true}) paginator: MatPaginator;
  @ViewChild('targetGED', {static: true}) targetGED: ElementRef;

  perPage = 12;
  resultsLength = 0;
  files = null;
  chosenFilter = null;
  projectId = null;
  expanded:boolean = false;
  @Input() modeProjet: any;

  constructor(private authService: AuthService,private _lightbox: Lightbox, private dialog: MatDialog) { 
  }

  ngOnInit() {
    //this.paginator.pageIndex = 0;
  }

  filterFichier(chosenFilter, ID, scroll){
    //console.log({chosenFilter})
    this.chosenFilter = chosenFilter;
    this.projectId = ID;
    this.resultsLength = 0;
    this.files = null;
    this.paginator.pageIndex = 0;
    this.fetchFiles(0, scroll);
  }

  reset(){
    this.chosenFilter = null;
    this.projectId = null;
    this.resultsLength = 0;
    this.files = null;
    this.paginator.pageIndex = 0;
    this.expanded = false;
  }

  fetchFiles(page, scroll){
    this.expanded = true;
    this.authService.getFiles(this.projectId, page, this.perPage, null, this.chosenFilter).subscribe(res => {
      //console.log({res})
      this.resultsLength = res['hydra:totalItems']; 
      this.files = res["hydra:member"];
      if(scroll)
        this.targetGED.nativeElement.scrollIntoView({behavior:"smooth"});
      //console.log(res);
    })
  }

  getMediaLink(mediaUrl){
    return this.authService.getMediaData(mediaUrl);
  }

  open(url){
    

    url = this.getMediaLink(url);
    // alert(url)
    const album = {
      src: url
   };
   let albums = [];
   albums.push(album);
   this._lightbox.open(albums, 0);

   setTimeout(() => {
    document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
    if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
    if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
   }, 1500);

  }

  handlePage(e: any) {
    this.fetchFiles(e.pageIndex, true);
  }


  getIcon(extension){
    let filelink = "";
    if(extension.includes("spreadsheetml.sheet") || extension.includes("application/vnd.ms-excel")
    || extension.includes("application/octet-stream")){
      filelink = "../../assets/icons/excel.png";
    }else if(extension.includes("presentationml.presentation")){
      filelink = "../../assets/icons/ppt.png";
    }else if(extension.includes("application/msword") || extension.includes("wordprocessingml.document")){
      filelink = "../../assets/icons/word.png";
    }else if(extension == 'application/pdf'){
      filelink = "../../assets/icons/pdf.png";
    }else if(extension.includes("application/zip")){
      filelink = "../../assets/icons/zip.png";
    }else if(extension.includes("video/quicktime")){
      filelink = "../../assets/icons/video.png";
    }

    return filelink ? filelink : false;
  }

  openModal(media){

    const dialogRef = this.dialog.open(scrollPjDialog, {
      width: '90%',
      height: '90%',
      data:{files: this.files, currentFile: media}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result => '+ result);
      if (result) {

      }

    });

  }

}

@Pipe({
  name: 'truncate'
 })
 export class TruncatePipe implements PipeTransform {
 transform(value: string, args: string[]): string {
     const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
     const trail = args.length > 1 ? args[1] : '...';
     return value.length > limit ? value.substring(0, limit) + trail : value;
    }
 }



@Component({
  templateUrl: './scrollPjDialog.html',
  styleUrls: ['./scrollPjDialog.scss']
})
export class scrollPjDialog {  

  docsUrl = environment.server_url;
  seletedMedia: any;
  currentIndex = 0;
  fileList: any;
  currentMedia: any;

  constructor(
    public dialogRef: MatDialogRef<scrollPjDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    protected _sanitizer: DomSanitizer) {

    console.log({medialiste: this.data});

    this.fileList = this.data.files;
    this.currentMedia = this.data.currentFile;

    for (var i = 0; i < this.fileList.length; i++) {
      if(this.fileList[i] == this.currentMedia){
        this.currentIndex = i;
      }
    }

    this.seletedMedia = this.fileList[this.currentIndex];

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  validate(): void {
    this.dialogRef.close();
  }

  onClickPrevious(){
    if ((this.currentIndex-1) >= 0) {
      this.currentIndex -= 1; 
      this.seletedMedia = this.fileList[this.currentIndex];
    }
  }

  onClickNext(){
    if ((this.currentIndex+1) < this.fileList.length) {
      this.currentIndex += 1; 
      this.seletedMedia = this.fileList[this.currentIndex];
    }
  }
 
  getPdfLink(mediaUrl){
      return this._sanitizer.bypassSecurityTrustResourceUrl(this.getMediaLink(mediaUrl));
  }

  getMediaLink(mediaUrl){
    return this.authService.getMediaData(mediaUrl);
  }

  getMediaExtension(media){

    if(media.extension == 'com.adobe.pdf' || media.extension == 'application/pdf'){
      return 1;
    }
    else if (media.extension.includes('image/')){
      return 2;
    }
    else if(media.extension == 'audio/mpeg' || media.extension == 'audio/ogg'){
      return 3;
    }
    else if(media.extension == 'video/mp4' || media.extension == 'video/webm'){
      return 4;
    }
    else
    {
      return 5;
    }

  }

  getIcon(extension){
    let filelink = "";
    if(extension.includes("spreadsheetml.sheet") || extension.includes("application/vnd.ms-excel")
    || extension.includes("application/octet-stream")){
      filelink = "../../assets/icons/excel.png";
    }else if(extension.includes("presentationml.presentation")){
      filelink = "../../assets/icons/ppt.png";
    }else if(extension.includes("application/msword") || extension.includes("wordprocessingml.document")){
      filelink = "../../assets/icons/word.png";
    }else if(extension == 'application/pdf'){
      filelink = "../../assets/icons/pdf.png";
    }else if(extension.includes("application/zip")){
      filelink = "../../assets/icons/zip.png";
    }else if(extension.includes("video/quicktime")){
      filelink = "../../assets/icons/video.png";
    }

    return filelink ? filelink : false;
  }

}



 