import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardcontentComponent } from '../content/dashboardcontent.component';
import { SingleProjectComponent } from '../single-project/single-project.component';
import { FaceTofacesProposalComponent } from 'src/app/face-tofaces-proposal/face-tofaces-proposal.component';
import { FacetofacesReunionComponent } from 'src/app/facetofaces-reunion/facetofaces-reunion.component';
import { SearchComponent } from '../search/search.component';
import { GedComponent } from '../ged/ged.component';
import { CoreServiceService } from 'src/app/services/core/core-service.service';
import { MatSidenav } from '@angular/material';
import { MessageService } from 'src/app/services/message/message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  type:any
  //num = 0;
  ID: any = '';
  GED: boolean = false;
  hideNav = localStorage.getItem('hideNav') ? localStorage.getItem('hideNav') : 0;
  subscription: Subscription;

  @ViewChild('dashboardcontent', {static: false}) dashboardcontent: DashboardcontentComponent;
  @ViewChild('singleproject', {static: false}) singleproject: SingleProjectComponent;
  @ViewChild('facetofacesproposal', {static: false}) FaceTofacesProposal: FaceTofacesProposalComponent;
  @ViewChild('facetofacesreunion', {static: false}) Facetofacesreunion: FacetofacesReunionComponent;
  //@ViewChild('appsearch') appsearch: SearchComponent;
  @ViewChild('appged', {static: false}) appged: GedComponent;
  @ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;
  innerWidth:any;
  constructor(private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private coreService: CoreServiceService) {
    // this.Arr = Array;
    //this.num = 20;
  }

  ngOnInit() {
    this.onResize(event)
    this.route.url.subscribe((res)=>{
      this.type=res
      console.log("type "+this.type);
      if(this.type[2]){
        if(this.type[1] == 'details'){
          this.type=this.type[0].path
        }else{
          this.type = this.type[2].path
        }
      }else{
        this.type=this.type[0].path
      }
      console.log('type de route '+this.type);
    })

    if (!this.ID) {
      this.ID = this.route.params['value'].ID;
      //this.dashboardcontent.iDProjet = this.ID;
      if (this.singleproject) {
        this.singleproject.iDProjet = this.ID;
      }
    }

    if (this.hideNav == 1) {
      //console.log("close");
      setTimeout(() => {
        this.sidenav.close();
      }, 1000);

    } else {
      //console.log("open");
      setTimeout(() => {
        this.sidenav.open();
      }, 1000);
    }
    //console.log("ngOnInit")
    this.coreService.toggleSidebar.subscribe((res) => {
      localStorage.setItem('hideNav', res);
      //console.log(res)
      this.hideNav = res;
      this.sidenav.toggle();
    });

    this.authService.obCommonProjectChosen.subscribe((res) => {
      this.onChosenProject(res);
    });

    this.subscription = this.messageService.getRefreshFiltre().subscribe(() => {
      if (this.dashboardcontent) {
        this.dashboardcontent.refreshInfoFilter();
      }

      if (this.singleproject) {
        this.singleproject.refreshInfoFilter();
      }
    });



  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if(this.innerWidth<1200){
      this.sidenav.close()
    }else{
      this.sidenav.open()
    }
    //console.log("window size", this.innerWidth)
  }
  
  onLaunchSearch(chosenFilter) {

    //console.log(' chosenFilter -)--) '+ JSON.stringify(chosenFilter));

    this.GED = false;

    if (this.ID) {
      if (this.singleproject) {
        this.singleproject.filterMessage(chosenFilter);
      }
      if (this.FaceTofacesProposal) {
        this.FaceTofacesProposal.filterMessage(chosenFilter);
      }
      if (this.Facetofacesreunion) {
        this.Facetofacesreunion.filterMessage(chosenFilter);
      }
      if (this.appged) {
        this.appged.reset();
      }
    }
    else {
      //console.log('dans le else dashboardcontent --> '+ this.dashboardcontent);
      if (this.dashboardcontent) {
        this.dashboardcontent.getAllMessages(chosenFilter);
      }

      if (this.appged) {
        this.appged.reset();
      }
    }

  }


  actionsSearchDoc(chosenFilter){
    this.GED = true;
    this.ID = null;
    if (this.appged) {
      this.appged.filterFichier(chosenFilter, chosenFilter.projectId, true);
    }
  }

  onLaunchSearchDoc(chosenFilter) {
    //console.log({chosenFilter})
    this.GED = true;
    this.ID = this.route.params['value'].ID;
    if (this.appged) {
      //console.log(' le this.appged a la valeur '+this.appged);
     this.appged.filterFichier(chosenFilter, this.ID, true);
    }
  }

  onLaunchSearchDocDashboard(chosenFilter) {
    this.GED = true;
    this.ID = null;
    if (this.appged) {
      this.appged.filterFichier(chosenFilter, chosenFilter.projectId, true);
    }
  }

  onLaunchSearchDocReunion(chosenFilter) {
    this.GED = true;
    this.ID = null;
    if (this.appged) {
      this.appged.filterFichier(chosenFilter, chosenFilter.projectId, true);
    }
  }

  onChosenProject(IdProjectChosen) {
    this.GED = false;
    this.ID = IdProjectChosen;
    if (this.appged) {
      this.appged.reset();
    }
    //this.dashboardcontent.projecthasChange(IdProjectChosen);
    if (this.singleproject) {
      this.singleproject.projecthasChange(IdProjectChosen);
    }
    //this.appsearch.iDProjet = IdProjectChosen;
    //this.appsearch.resetSearch();
  }

}
