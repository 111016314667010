import { Component, OnInit, ViewChild, ElementRef, Inject, ChangeDetectorRef, OnDestroy, Input, QueryList, EventEmitter, Output} from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import { MatButton, MatOption, MatSelect } from '@angular/material';
import {merge, Observable, of as observableOf, Subject, Subscription} from 'rxjs';
import {catchError, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import { requiredFileType } from '../../../app-file-upload/upload-file-validators';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ErrorStateMatcher} from '@angular/material/core';
import { ConfirmationDialogComponent } from '../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import { Lightbox } from 'ngx-lightbox';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SubjectsListComponent } from '../subjects-list/subjects-list.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { SocketService } from 'src/app/services/socket/socket.service';
import { Message } from 'src/app/services/socket/model/message';
import { Event } from 'src/app/services/socket/model/event';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { OtherOptionsComponent } from '../other-options/other-options.component';

export interface searchFilter {
  transmitter: string;
  recipient: string;
  projectId: string;
  dateBefore: any;
  dateAfter: any;
  tag: string;
  statut: boolean;
  type: string;
  keyWord: string;
  smartNumber: string;
}

@Component({
  selector: 'app-single-project',
  templateUrl: './single-project.component.html',
  styleUrls: ['./single-project.component.scss']
})
export class SingleProjectComponent implements OnInit, OnDestroy {
 
  displayedColumns: string[] = ['Message'];
  @Input() ged: boolean = false;

  isLoadingResults = true;
  isRateLimitReached = false;
  private _album = [];
  searchContact: any;
  writeMode = -1; // 1 = write answer, 2 = comment
  openWriteFace = false;
  showMessages = true;
  divopened = false;
  icons = ["ok.png", "merci.png", "non.png", "pas-dac.png"]

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('divHeaderTable', {static: false}) divHeaderTable: ElementRef;
  @ViewChild('divComSpecMsg', {static: false}) divComSpecMsg: ElementRef;
  @ViewChild('theForm', {static: true}) theForm: FormGroupDirective;
  @ViewChild('inputMedia', {static: false}) inputMedia: ElementRef;
  //@ViewChild('writeMsg') writeMsg: ElementRef;
  @ViewChild('allSelected', {static: true}) private allSelected: MatOption;
  @ViewChild('selectRecipient', {static: true}) private selectRecipient: MatSelect;
  @ViewChild('selectSubject', {static: true}) private selectSubject: MatSelect;
  @ViewChild('oneToOneOption', {static: true}) private oneToOneOption: MatOption;
  @ViewChild('descp', {static: false}) descp: ElementRef;
  @ViewChild('sendmsgdiv', {static: false}) sendmsgdiv: ElementRef;
  @ViewChild('missionname', {static: false}) missionname: ElementRef;
  

  hideSelectCopy = true;
  hideSelectCopyMenu = false;
  resultsLength = 0;
  perPage = 20;
  userAdmin = false;
  dataSource : any[];
  specMsg: any;
  openmsg: any;
  opened = false;
  specMsgType: any;
  ID: any;
  projectFull: any;
  projectUsers = [];
  projectTags  = [];
  arrayQuickAnswer = [];
  newTagsArray: any;
  project: string;
  isCommentActivated = true;
  messageInfo: any;
  chosenFilterProject = null;
  expanded: boolean = true;
  limit: number = 200;
  hasMessage = false;
  currentUser: any;
  currentUserInfo: any;
  sendMessageForm: FormGroup;
  sendanswerForm: FormGroup;
  formComment: FormGroup;
  currentDate = null;
  submitted = false;
  secteur = "";
  files: any = [];
  tempMember = [];
  lastFaceNumber = 1;
  showRedBar = false;
  showTool = false;
  hiddenSearchEngin = true;
  ISendMessage = false;
  copiesusers = [];
  addmissionclicked = 0;
  newProjectSubject = "";
  /*private envoyerObs = new Subject();
  public envoyerObs$ = this.envoyerObs.asObservable();*/

  private refreshPageObs = new Subject();
  public refreshPageObs$ = this.refreshPageObs.asObservable(); 
  public isReady:boolean = false;
  lang='fr';
  public curUser = null;


  public iDProjet: any;

  @Output() EventLaunchSearch = new EventEmitter();
  @Output() EventLaunchSearchDoc = new EventEmitter();

  transmitter = null;
  recipient = null;
  projectId = null;
  dateBefore = null;
  dateAfter = null;
  tag = null;
  statut = null;
  type = null;
  keyWord = null;
  smartNumber = null;
  modeDoc = false;
  sujectPlaceholder : any;
  optionselected : any;
  //contacts: Array<any> = [];
  contactsExp: Array<any> = [];
  contactsDest: Array<any> = [];
  projectLists: Array<any> = [];
  userData : any
  chosenFilter: searchFilter;
  public recipientCtrl: FormControl = new FormControl();
  public recipientSearch: FormControl = new FormControl();
  public themeCtrl: FormControl = new FormControl();
  public transmitterCtrl: FormControl = new FormControl();
  public subjectCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  saveCtc = null;
  saveCtcTransmitter = null;
  saveRecipient = null;
  saveTheme = null;
  echeance = null;
  private = false;
  relanceOne = null;
  relanceTwo = null;
  relanceOneDate = null;
  relanceTwoDate = null;
  firstLoad = true;
  socket_service_connection: Subscription;
  socket_service_disconnected: Subscription;
  socket_service_onMessage: Subscription;
  ioConnection: any;
  new_message = false;
  project_isparent = "PARENT";
  arraySubject = [];
  isUpdate : any;
  isAdmin = false;
  focus_text = true;
  allcopies = false;
  
  changeRecipient(){
    //this.textArea.focus();
    /*
    if(this.projectTags.length > 0){
      this.selectSubject.open();
      this.toggleAllSelection();
    } else {
      this.toggleAllSelection();
      this.selectSubject.open();
    }*/
    this.allcopies = true;
    this.private = false;
    this.selectSubject.open();
    this.toggleAllSelection();
    //document.getElementById("descp").focus();
    //this.elementRef.nativeElement.focus();
    //alert("yeees");
  }

  resetMsgForm(){
    this.sendanswerForm.get('files').clearValidators();
    this.sendanswerForm.get('files').updateValueAndValidity();
    this.files = [];
    const elementtous: HTMLElement = document.getElementById('divtous') as HTMLElement
    elementtous.innerHTML = '';
    this.copiesusers = [];
    this.sendMessageForm.controls.inCopy.patchValue([]);
    this.sendMessageForm.reset();
  }

  closeMsgBloc(){
    this.divopened = !this.divopened;
    this.hiddenSearchEngin = true;
    if(this.divopened === true){
      setTimeout(() => {
          this.selectRecipient.open();
      }, 0);
    }
  }

  addProjectToArray(){
    // this.arraySubject.push({libelle:this.newProjectSubject})
    var newProjectSubject = (<HTMLInputElement>document.getElementById("newProjectSubject")).value;
    if(newProjectSubject.trim() == "" || newProjectSubject.trim().length == 0){
      alert("Veuillez entrer un sujet"+this.project);
      return;
    }
    this.authService.addTagToProject({
      libelle:newProjectSubject,
      project:this.project
    }).then((res)=>{
      this.newProjectSubject = "";
      this.addmissionclicked = 0;
      console.log({resSujet:res});
      this.projectTags.push(res);
      this.projectTags.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })
      this.sendMessageForm.get('subProject').setValue(res); 
      this.selectSubject.close();
      setTimeout(() => this.descp.nativeElement.focus(), 0);
      //this.getProjectInfo();
    }).catch(err=>{
      console.log(err)
    });
  }


  changesub(){
    //this.textArea.focus();
    this.toggleAllSelection();
    setTimeout(() => this.descp.nativeElement.focus(), 0);
    //document.getElementById("descp").focus();
    //this.elementRef.nativeElement.focus();
    //alert("yeees");
  }
  /*
  async mainFunction() {
    await this.ngAfterViewInit();
    setTimeout(() => this.selectRecipient.open(), 7000);
    }*/
  

  ngAfterViewInit() { 
    /*
    if (document.getElementById('selectRecipient') && (this.openWriteFace == true)) {
      setTimeout(() => this.selectRecipient.open(), 7000);
    }*/
    // setTimeout(() => this.selectSubject2.open(), 1800);
    //this.selectSubject2.open();
    //this.mainFunction();
    //setTimeout(() => this.selectRecipient.open(), 3000);
    /*
    this.elementRef.focus();
    this.mySelect.open();
    document.getElementById("btnsub").click();
    console.log("okkk");
    let el: HTMLElement = this.btnsub;
    this.btnsub.click();*/
    //this.selectBtn.nativeElement.click();
    //let el: HTMLElement = this.btnsub.nativeElement;
    //el.click();
    //this.selectSubject.nativeElement.click();

    
  }


  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public  dialog: MatDialog,
    private messageService: MessageService,
    private socketService: SocketService,
    private translate: TranslateService,
    private _lightbox: Lightbox) {
    
    this.userData = JSON.parse(localStorage.getItem('currentUser'))

    this.echeance = null;
    //this.divopened = true;

    this.sendMessageForm = this.formBuilder.group({
      subProject: ['', Validators.required],
      newSubProject: '',
      recipient: ['', Validators.required],
      inCopy: [],
      private: false,
      deadLine: null,
      relanceOne: null,
      relanceTwo: null,
      relanceTree: null,
      messageToSend: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    this.sendanswerForm = this.formBuilder.group({
      answerMessage: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    this.formComment = this.formBuilder.group({
      commentMessage: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    delete currentUser.token;
    delete currentUser.refresh_token;
    delete currentUser.roles;
    this.curUser = currentUser;

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id'];
    this.currentUserInfo = JSON.parse(localStorage.getItem('currentUser'));

  }

  ngOnDestroy() {
    if (this.socket_service_connection) {
      this.socket_service_connection.unsubscribe();
    }
    if (this.socket_service_disconnected) {
      this.socket_service_disconnected.unsubscribe();
    }
    if (this.socket_service_onMessage) {
      this.socket_service_onMessage.unsubscribe();
    }
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  clickaddmission(){
    this.addmissionclicked = 1;
    setTimeout(() => this.missionname.nativeElement.focus(), 0);
  }


  protected themeProject() {
    if(this.saveTheme == null){
      this.saveTheme = this.projectTags;
    }

    let search = this.themeCtrl.value;
    if (!search) {
      this.projectTags = this.saveTheme;
      return;
    } else {
      search = search.toLowerCase();
      this.projectTags = this.saveTheme.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }

  protected filterSubjectSearch() {
    if(this.saveTheme == null){
      this.saveTheme = this.projectTags;
    }

    let search = this.subjectCtrl.value;
    if (!search) {
      this.projectTags = this.saveTheme;
      return;
    } else {
      search = search.toLowerCase();
      this.projectTags = this.saveTheme.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }

  protected filterRecipient() {
    if(this.saveCtc == null){
      this.saveCtc = this.contactsDest;
    }

    let search = this.recipientCtrl.value;
    if (!search) {
      this.contactsDest = this.saveCtc;
      return;
    } else {
      search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.contactsDest = this.saveCtc.filter(bank => bank.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
  }

  protected filterRecipientSearch(){
    if(this.saveRecipient == null){
      this.saveRecipient = this.projectUsers;
    }

    let search = this.recipientSearch.value;
    if (!search) {
      this.projectUsers = this.saveRecipient;
      return;
    } else {
      search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.projectUsers = this.saveRecipient.filter(bank => bank.user.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.user.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
  }

  protected filterTransmitter(){
    if(this.saveCtcTransmitter == null){
      this.saveCtcTransmitter = this.contactsExp;
    }

    let search = this.transmitterCtrl.value;
    if (!search) {
      this.contactsExp = this.saveCtcTransmitter;
      return;
    } else {
      search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.contactsExp = this.saveCtcTransmitter.filter(bank => bank.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
  }

  

  


  ngOnInit() {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    this.secteur = localStorage.getItem('secteur');

    this.currentDate = dd + '/' + mm + '/' + yyyy;

    
    this.recipientCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRecipient();
      });

    this.transmitterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTransmitter();
    });


    this.themeCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.themeProject();
      });

    this.recipientSearch.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRecipientSearch();
    });
    
    this.subjectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSubjectSearch();
    });

    this.route.paramMap.subscribe(params => {
      //console.log('getCurrentProject paramètre id ==> '+params.get('ID'));
      //console.log('getCurrentProject paramètre specMsg ==> '+params.get('specMsg'));
      this.specMsg = params.get('specMsg');
      this.openmsg = params.get('openmsg');
      //this.divopened = true;
      //alert("open "+this.openmsg);
      this.specMsgType = params.get('type');
      if (this.specMsg) {
        //console.log({specMsg : this.specMsg});
        this.showRedBar = true;
        this.authService.obprojectChosen.next(this.specMsg);
      }
      this.ID = params.get('ID');
      this.iDProjet = params.get('ID');
      this.resetQuestionFrom();
      this.resetAnswerFrom();
      this.resetCommentForm();
      this.writeMode = -1;
      this.openWriteFace = false;
      this.showMessages = true;
      this.submitted = false;
      this.paginator.pageIndex = 0;
      this.dataSource = [];
      this.resultsLength = 0; 
      this.lastFaceNumber = 1;
      this.chosenFilterProject = null;
      this.isReady = false;
      this.userAdmin = false;

      if (this.messageService.getShowCreateProjectTuto() == false) {
        this.showTool = this.messageService.getShowProjectTutobis();
      }

      this.getCurrentProject();
      this.getProjectInfo();

      //info du filtre
      //this.getInfoProject(); 


      this.hiddenSearchEngin = true;
      //this.selectSubject.open();
      if (this.openmsg) {
        this.divopened = true;

        this.fctEcrire();
      }
      
      
      //document.getElementById('selectSubject').addEventListener("click", this.selectSubject.open());
      //document.getElementById('selectSubject').click();

      setTimeout(() => {
       window.scroll(0,0);
      }, 500);

      //let el: HTMLElement = this.btnsub.nativeElement;
      
      //document.getElementById("btnsub").click();
      //this.btnsub.nativeElement.click();

      /*const eventSource = new EventSource('https://hub.neoface.app/.well-known/mercure?topic=https://api.neoface.app/api/projects/'+this.iDProjet);
      eventSource.addEventListener('message', message => {
        if ((this.isLoadingResults == false) && (this.ISendMessage == false) && (this.paginator.pageIndex == 0) && (this.specMsg == null) && (this.chosenFilterProject == null)) {
          this.messageService.messageRefreshConv();//reload message page
          this.messageService.messageUpdated();//update header notification
          this.messageService.needRefreshFiltre(); //update filter elements
        }    
      });*/

    });


    this.messageService.getShowCreateProjectTutoIsFalse().subscribe(( ) =>{ 
      this.showTool = this.messageService.getShowProjectTutobis();
    });

    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });

    this.messageService.getLibelleProject().subscribe((data) => {
      this.projectFull.libelle = data
    });


    //this.divHeaderTable.nativeElement.scrollIntoView();
    
    this.expanded = true;
    this.ged = false;
    

    this.messageService.getWriteMessage()
    .subscribe(
      (data)=>{
        // this.fctEcrire();
      }
    )

    this.getAnswerListe();

    /*this.getContact();
    this.getSujet(this.iDProjet);*/
    //this.getInfoProject(); 

    this.authService.obprojectChosen.subscribe((idMessage) =>{ 
      this.smartNumber = idMessage;
      //console.log('dans le smarnumber idmessage ==> '+idMessage);
    });

    this.messageService.getMessageIsSend().subscribe(() => {
       this.showTool = false; 
       this.messageService.setShowProjectTutobis(false);
    });

    this.hiddenSearchEngin = true;
    

  }

  private initIoConnection(id_project): void {
    this.socketService.initSocket();
    this.socketService.joinRoom(id_project);
    this.socket_service_onMessage = this.ioConnection = this.socketService.onMessage()
    .subscribe((message: Message) => {
      console.log('message reçu ==> '+ JSON.stringify(message));
      if (message.type == "CONVERSATION" && message.id !== this.currentUser) {
        this.new_message = true;
      }
    });


    this.socket_service_connection = this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {
        //console.log('connected');
      });

     this.socket_service_disconnected = this.socketService.onEvent(Event.DISCONNECT)
      .subscribe(() => {
        //console.log('disconnected');
      });
  }

  reloadScreen() {
    //console.log('reloadScreen!!!!');
    this.chosenFilterProject = null;
    this.specMsg = null;
    this.showRedBar = false;
    this.specMsgType = null;
    this.messageService.messageRefreshConv();//reload message page
    this.messageService.messageUpdated();//update header notification
    this.messageService.needRefreshFiltre();//update filter elements
  }

  fcthiddenSearchEngin(){
    if (this.hiddenSearchEngin == false)
      this.hiddenSearchEngin = true;
    else
      this.hiddenSearchEngin = false;
      this.divopened = false;
    }

  fullTextWithLink(value: string) {
    return value.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");
  }
  
  uploadFile(event) {
    //console.log('item récupéré '+JSON.stringify(event));
    /*for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files[this.files.length] = element;
      this.inputMedia.nativeElement.value = "";
    }  */

    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element);
    } 
    this.inputMedia.nativeElement.value = "";
  }

  onRemoveAnswer(event) {
    //console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  selectCopyClosefct(opened: boolean){
    if (!opened) {
      this.hideSelectCopy = true;
      this.hideSelectCopyMenu = false;
    }
  }

  openSelectCopy(){
    this.hideSelectCopy = false;
    this.hideSelectCopyMenu = true;
    this.oneToOneOption.deselect();
    this.allSelected.deselect();
    this.sendMessageForm.controls.inCopy.patchValue([]);
    this.sendMessageForm.get('private').setValue(false);
  }

  loadFilterElement(){
    //console.log("on loadFilterElement");
    if (this.firstLoad == true) {
      this.getInfoProject();
    }
    this.firstLoad = false;
  }


  pendingDev(){
    if(this.lang == "fr")
      alert("Processus action délai bientôt disponible");
    else
      alert("Deadline action process soon available");
  }

  pendingDevReunion(){
    if(this.lang == "fr")
      alert("Compte rendu de réunion bientôt disponible");
    else
      alert("Meeting minutes coming soon");
  }

  onSelectResponse(event){
    event.addedFiles.map((item)=>{
      this.files.push(item);
    })   
  }
  
  clickSuppMethod() {
    if(confirm("Voulez vraiment suppprimer le projet "+this.projectFull.libelle)) {
      this.onClickConfirmeDelete();
    }
  }

  onClickConfirmeDelete(): void{
    this.authService.deleteProject(this.iDProjet).subscribe(
      res => {  
         this.authService.obRefreshProjet.next();
         this.messageService.messageUpdated();
         this.router.navigate(['/']);
        //this.stepper.selectedIndex = 1;
      },
      (error) => {
        alert('Erreur ! : ' + error)
      }
    );
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

 
  fctEcrire(){

    this.writeMode = -1;
    this.resetCommentForm();
    this.resetAnswerFrom();
    this.hideSelectCopy = true;
    this.hideSelectCopyMenu = false;

    if(this.openWriteFace == false)
    {
      this.hiddenSearchEngin = true;
      this.showRedBar = false;
      this.openWriteFace = true;
      this.showMessages = true;
      this.getLastFaceNumber();
      setTimeout(() => {
        if (this.sendmsgdiv) {
          this.selectRecipient.open();
          this.sendmsgdiv.nativeElement.scrollIntoView({block: "center"});
        }
        else{
          this.selectRecipient.open();
          this.sendmsgdiv.nativeElement.scrollIntoView({behavior: "smooth", block: "center"});
        }
      }, 1000);
      
      //this.descp.nativeElement.scrollIntoView({block: "center"});
      //this.sendmsgdiv.nativeElement.scrollIntoView({behavior: "smooth", block: "center"});
    }
    else
    {
      this.resetQuestionFrom();
      this.openWriteFace = false;
      this.showMessages = true;
    }
     
  }


  exiteWriteMode(){
    this.writeMode = -1;
    this.resetCommentForm();
    this.resetAnswerFrom();
    this.resetQuestionFrom();
    this.openWriteFace = false;
    this.showMessages = true;
    this.messageService.getCloseWriteMessage(true);
  }

  getLastFaceNumber(){
     this.authService.getLastFaceNumber(this.ID)
      .subscribe(res => {
        //console.log(' retour getLastFaceNumber => '+res);
        let arrayMessage = res['hydra:member']

        if (arrayMessage.length > 0) {
           this.lastFaceNumber = arrayMessage[0].smartNumber;
        }

        // console.log(this.projectUsers);
        //console.log('fin chargement projet ');
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });   
  }

  onSelectPrivate(){
    if (this.sendMessageForm.controls.private.value === false)
    {
       this.sendMessageForm.get('private').setValue(true); 
    }
    else
    {
      this.sendMessageForm.get('private').setValue(false); 
    } 
  }

  getAnswerListe(){
     this.authService.getAnswerListe( )
      .subscribe(res => {
        //console.log(' retour getAnswerListe => '+JSON.stringify(res));
        this.arrayQuickAnswer = res['hydra:member'];
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });  
  }

  sendQuickAnswer(answerItem,  infoMessage) {
    const msg = this.lang == 'fr' ? answerItem.message : answerItem.title
    this.authService.sendQuickAnswer(
      msg,
      infoMessage.tags[0],
      infoMessage.isPrivate,
      this.project,
      infoMessage.id
    )
    .subscribe(res => {
      //console.log('réponse answer => '+res);
      //this.writeMode = 0;
      this.writeMode = -1;
      //this.envoyerObs.next();
      this.messageService.messageRefreshConv();
      this.messageService.messageUpdated();
      //scroll in view
      //this.divHeaderTable.nativeElement.scrollIntoView();

      this.socketService.send({
        id: this.currentUser,
        action: this.ID,
        type: "CONVERSATION"
      });

       window.scroll(0,0);

    });
  }

  getRecipient(){
    for (var i = 0; i < this.projectUsers.length; i++) {
      if(this.projectUsers[i].user.id == this.sendMessageForm.controls.recipient.value){
        return this.projectUsers[i].user.fullName;
      }
    }
    return false;
    //console.log('Your order has been submitted ---> ', this.sendMessageForm.value);
  }

  getDeadline(){
    if(this.sendMessageForm.controls.deadLine.value){
      return this.sendMessageForm.controls.deadLine.value;
    }
    return '';
    //console.log('Your order has been submitted ---> ', this.sendMessageForm.value);
  }


  transfertByMail(messageData){
    const dialogRef = this.dialog.open(transfertdialog, {
      width: '600px',
      data: {message: messageData}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');       
    });
  }

  
  

  open(file){

    const reader = new FileReader();
    reader.onload = (e: any) => {
       //console.log('csv content', e.target.result);
       const album = {
          src: e.target.result
       };
       let albums = [];
       albums.push(album);
       this._lightbox.open(albums, 0);

       setTimeout(() => {
        document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
        if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
        if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
       }, 1500);

    };
    reader.readAsDataURL(file);

  }

  playVideoOrAudio(file, source){
    source.src = URL.createObjectURL(file);
  }

  createFileUrl(file, source){
    source.href = URL.createObjectURL(file);
  }

  getFiletype(file){

    if (file.type.includes("image/") == true) {
      return 1;
    }
    else if (file.type.includes("video/") == true) {
      return 2; 
    }
    else if (file.type.includes("application/") == true) {
      return 3;
    }
    else if (file.type.includes("audio/") == true) {
      return 4;
    }
    else
    {
      return -1;
    }

  }


  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
    //console.log(this.files)
  }

  tosslePerOne(all){ 
    if (this.allSelected.selected) {  
      this.allSelected.deselect();
      return false;
    }
    if(this.sendMessageForm.controls.inCopy.value.length==this.projectUsers.length){ 
      this.allSelected.select();
    }
  }

  /*copyTemp = [];
  toggleAllSelection() {
    //console.log(this.allSelected)
    this.projectUsers.map(item=>this.copyTemp.push(item.user.id))
    if (this.allSelected.selected) {
      this.sendMessageForm.controls.inCopy.patchValue(this.copyTemp);
    } else {
      this.sendMessageForm.controls.inCopy.patchValue([]);
    }
    console.log(this.sendMessageForm.value);
  }*/
  
  toggleAllSelection() {
    //console.log(this.allSelected)
    //console.log("start ");
    this.sendMessageForm.controls.inCopy.patchValue([]);
    const element: HTMLElement = document.getElementById('divtous') as HTMLElement
    element.innerHTML = 'Tous';
    /*
    const elementcopy: HTMLElement = document.getElementById('copiesusers') as HTMLElement
    elementcopy.innerHTML = '';
    */
    this.copiesusers = [];
    //this.oneToOneOption.deselect();
    //this.allSelected.select();
    this.sendMessageForm.get('private').setValue(false);
  }

  resetPrivate() {
    this.sendMessageForm.controls.inCopy.patchValue([]);
    const element: HTMLElement = document.getElementById('divtous') as HTMLElement
    element.innerHTML = '';
    this.private = false;
    this.copiesusers = [];
    this.sendMessageForm.get('private').setValue(false);
  }

  fctOneToOne(){
    this.sendMessageForm.controls.inCopy.patchValue([]);
    this.allSelected.deselect();
    this.oneToOneOption.select();
    this.sendMessageForm.get('private').setValue(true);
  }

  compareObjects(o1: any, o2: any) {
    if(o1.givenName == o2.givenName )
    return true;
    else return false
  }


  truncate(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    let val =  value.length > limit ? value.substr(0, limit) + ellipsis : value;
    return val.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");
  }

  getCurrentProject() {
    //console.log('getCurrentProject paramètre id ==> '+this.ID);

    if (!this.ID) {
      return;
    }

    this.initIoConnection(this.ID);

    this.authService.getTheProject(this.ID)
      .subscribe(res => {
        this.projectFull = res;
        var tmpArrayUser = res['userProject'];
        //var tmpArrayUser = tmpArrayUser.filter((item) => item.user != null);
        this.projectUsers = [];
        this.project_isparent = res['type'];
        //alert("ok "+this.project_isparent);
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].user != null){
            this.projectUsers.push(tmpArrayUser[i]);
          }
        }
        
        this.projectUsers.sort((a, b) => {
          if ( a.user.fullName.toLowerCase() < b.user.fullName.toLowerCase() ){
            return -1;
          }
          if ( a.user.fullName.toLowerCase() > b.user.fullName.toLowerCase() ){
            return 1;
          }
          return 0;
        });
        //this.projectUsers.sort((a,b) => a.user.fullName > b.user.fullName);
        this.project = '/api/projects/' + res['id'];
        this.projectTags = res['tags'];


        this.projectTags.sort((a, b) => {
          if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
            return -1;
          }
          if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
            return 1;
          }
          return 0;
        })

        // console.log(this.project);
        // console.log(this.projectUsers);
        //console.log('fin chargement projet ');

        for (var i = 0; i < this.projectUsers.length; i++) {
          if((this.projectUsers[i].isAdmin == true) && (this.projectUsers[i].user.id == this.currentUser))
          {
            this.userAdmin = true;
          }
        }

      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });

    /*this.envoyerObs.subscribe(() =>{ 
      this.paginator.pageIndex = 0;
    });*/

    this.messageService.getMessageRefreshConv().subscribe(() =>{ 
      this.paginator.pageIndex = 0;
    });

    merge(this.paginator.page, this.messageService.getMessageRefreshConv(), this.refreshPageObs)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getProjectHisMessages(this.ID, this.paginator.pageIndex, this.perPage, (this.specMsg ? this.specMsg : null ), (this.specMsgType ? this.specMsgType : null ),(this.chosenFilterProject ? this.chosenFilterProject : null ))
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems']; 
          return data['hydra:member'];  
        }),
        catchError(() => {    
          this.isLoadingResults = false;
          // Catch if the  API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {    

        //console.log({commConvos:data})
          
          /*this.messageArray = data['hydra:member']; 
          this.messageArray.sort((a: any, b: any) => {// order by date
              return b.number - a.number;
          });
          this.dataSource = this.messageArray;*/

          for (var i = 0; i < data.length; i++) {
            data[i].truncating = true;
            if(data[i].isMessageAnswered == true) {
              data[i].answer.truncating = true;
            }
          }
          this.isReady = true;
          this.dataSource = data;
          // this.dataSource=this.dataSource.filter(item=>!item.action)
          // console.log({dataMsg:this.dataSource})
          if(this.dataSource.length > 0){ 

            this.hasMessage = true;
            //this.hiddenSearchEngin = false;

            for (var i = 0; i < this.dataSource.length; i++) {
              //this.dataSource[i].comment
              this.dataSource[i].comment.sort((a: any, b: any) => {// order by date
                  return b.smartNumber - a.smartNumber;
              });
            }

            if (this.specMsg) {
                  
              if (this.specMsgType && this.specMsgType == 'COMMENT') {
                for (var i = 0; i < this.dataSource.length; i++) {
                  if (this.dataSource[i].smartNumber ==  this.specMsg){
                    for (var index = 0; index < this.dataSource[index].comment.length; index++) {
                      if(this.dataSource[i].comment[index].dateCreated == this.dataSource[i].motionAt){
                        this.specMsg = this.dataSource[i].comment[index].number;
                        break;
                      }
                    }
                    break;
                  }
                }
              }

              setTimeout(() => {
                if (this.divComSpecMsg) {
                  this.divComSpecMsg.nativeElement.scrollIntoView({block: "center"});
                }
                else{
                  this.divHeaderTable.nativeElement.scrollIntoView({behavior: "smooth", block: "center"});
                }
              }, 500);
            }   


          }else { 
            this.hasMessage = false;
            this.messageService.openWriteMessageFace(null);
          }

        this.new_message = false;

      });
      
      
  } 

  // convenience getter for easy access to form fields
  get f() { return this.sendMessageForm.controls; }
  get fanswer() { return this.sendanswerForm.controls; }
  get fcomment() { return this.formComment.controls; }

  convertText(text){
    return text.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1'>$1</a>");
  }

 
  onSubmitForm(customerData) {
    console.log("sent");

     // Process checkout data here
    // console.log(JSON.stringify(this.files));
    //console.log('Your order has been submitted', customerData);

    this.submitted = true;
     // stop here if form is invalid
    if (this.sendMessageForm.invalid) {
      return;
    }

    /*
    if (this.sendMessageForm.controls.inCopy.value[0] == 'oneToOne') {
      this.sendMessageForm.controls.inCopy.patchValue([]);
    }

    if (this.sendMessageForm.controls.inCopy.value[0] == 'copyAll') {
      var tempMember = [];
      this.projectUsers.map(item=>tempMember.push(item.user.id))
      this.sendMessageForm.controls.inCopy.patchValue(tempMember);
    }*/
    if (this.private == true) {
      this.sendMessageForm.controls.inCopy.patchValue([]);
    }
    //if (this.sendMessageForm.controls.inCopy.value[0] == 'copyAll') {
    if (this.allcopies == true) {
      var tempMember = [];
      this.projectUsers.map(item=>tempMember.push(item.user.id))
      this.sendMessageForm.controls.inCopy.patchValue(tempMember);
    }

    //console.log(this.sendMessageForm.value);

    this.sendQuestion(this.sendMessageForm.value);

    if (this.showTool == false) {
      this.messageService.setShowProjectTutoIsFalse();
    }

    this.messageService.setMessageIsSend();
    this.sujectPlaceholder = ""
    this.submitted = false;
    this.theForm.resetForm();
    this.resetQuestionFrom();
    this.resetPrivate();
    this.openWriteFace = false;
    this.divopened = false;
    this.relanceOne = null;
    this.relanceTwo = null;
    this.echeance = null;
    this.showMessages = true;
    this.messageService.getCloseWriteMessage(true);
    this.selectRecipient.close();
    //scroll in view
     window.scroll(0,0);
    //this.divHeaderTable.nativeElement.scrollIntoView();
   
  }

  onSelectSubProject(sujetChosen){
    //console.log('sujetChosen ==> ', sujetChosen);
    this.sendMessageForm.get('newSubProject').setValue('');
    this.sendMessageForm.get('subProject').setValidators([Validators.required]);
    this.sendMessageForm.get('subProject').updateValueAndValidity();
  }

 
  onSelectNewSubProject(newSubProjetName){
    this.sendMessageForm.get('subProject').setValue('');
    this.sendMessageForm.get('subProject').clearValidators();
    this.sendMessageForm.get('subProject').updateValueAndValidity();
  }

  sendQuestion(messageTosendData) {

    this.ISendMessage = true;
    
    this.authService.sendQuestion(
      messageTosendData.messageToSend,
      messageTosendData.subProject,
      /*(messageTosendData.subProject != '' ? messageTosendData.subProject : messageTosendData.newSubProject),
      (messageTosendData.subProject != '' ? false : true),*/
      this.project,
      messageTosendData.recipient,
      messageTosendData.inCopy,
      messageTosendData.private,
      this.isCommentActivated,
      messageTosendData.deadLine,
      messageTosendData.relanceOne,
      messageTosendData.relanceTwo,
      messageTosendData.relanceTree,
      this.files,
      null,
      null,
      null
    )
    .subscribe(res => {
      //console.log('reponse envoi message: ',res);
      this.newTagsArray = res['tags'];
      for (var i = 0; i < this.newTagsArray.length; i++) {
        if (this.containsTheTag(this.newTagsArray[i]) == false) {
          //console.log('ajout du nouveau tag => '+ this.newTagsArray[i]);
          var tag = this.newTagsArray[i];
          this.projectTags.push(tag);
        }
      }

     this.projectTags.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })

      

      this.chosenFilterProject = null;
      this.specMsg = null;
      this.showRedBar = false;
      this.specMsgType = null;
      //**************** */
      this.modeDoc = false;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      tag: messageTosendData.subProject.id,
      statut: this.statut, 
      type: this.type,
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //**************** */
    //console.log(' launchSearch '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearch.emit(this.chosenFilter);
    /*
      this.messageService.messageRefreshConv();
      this.messageService.messageUpdated();
      this.messageService.needRefreshFiltre();
      */

      

      this.ISendMessage = false;

      this.socketService.send({
        id: this.currentUser,
        action: this.ID,
        type: "CONVERSATION"
      });
      //console.log("socket sended");
    
    },error => {
      alert("oups une erreur s'est produite!");
      this.ISendMessage = false;
    });
  }


  // getTags(){
  //   this.authService.getAllTags().then((res)=>{
  //     console.log({allTags:res})
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // }
  

  getProjectInfo(){
    this.arraySubject = []

    if (!this.ID) {
      return;
    }

    this.authService.getProjectInfo(this.ID).then((res)=>{
      console.log({res})
      this.arraySubject = res['tags']
      /*this.arraySubject.sort((a, b) => {
        return <any>new Date(a.dateCreated) - <any>new Date(b.dateCreated);
      })*/

      this.arraySubject.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })

      // this.arraySubject.reverse()
      this.isUpdate = Array(this.arraySubject.length).fill(false)
      //this.project = res
      // if(this.currentUser['id'] == this.project['creator']['id'] ){
      //   this.isAdmin = true
      // }
    }).catch(err=>console.log(err))
  }

  

  openAddSubjectModal(action){
    //console.log('ID du project subject =>'+ this.ID);
    const dialogRef = this.dialog.open(SubjectsListComponent, {
      width: '700px',
      data:{idProjet: this.ID, action: action, type: this.project_isparent}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
        //TO Do dialog ajout membre 
        //console.log(dialogRef.componentInstance.chosenSubject);
        if(dialogRef.componentInstance.chosenSubject){
          this.sujectPlaceholder = dialogRef.componentInstance.chosenSubject;
          this.sendMessageForm.get('subProject').setValue(dialogRef.componentInstance.chosenSubject)
          this.sendMessageForm.get('subProject').clearValidators();
          this.sendMessageForm.get('subProject').updateValueAndValidity();
          this.projectTags.push(dialogRef.componentInstance.chosenSubject);
          this.projectTags.sort((a, b) => {
            if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
              return -1;
            }
            if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
              return 1;
            }
            return 0;
          })
          this.selectSubject.open();
        }
        if(action == 2 && dialogRef.componentInstance.updatedSubject){
          //this.getProjectTags();
          this.getCurrentProject();
        }
        
    });
  }

  getUserListWithNoCurrentUser(){
    if(!this.projectUsers) return [];
    var arrayUser = [];
    for (var i = 0; i < this.projectUsers.length; i++) {
      if (this.sendMessageForm.controls.recipient.value) {
        if(this.projectUsers[i].user.id != this.currentUser && this.sendMessageForm.controls.recipient.value != this.projectUsers[i].user.id && this.projectUsers[i].status == 'ACCEPTED'){
          arrayUser.push(this.projectUsers[i]);
        }
      }
      else if(this.projectUsers[i].user.id != this.currentUser && this.projectUsers[i].status == 'ACCEPTED'){
        arrayUser.push(this.projectUsers[i]);
      }
    }
    return arrayUser;
  }

  openOptionsModal(){
    //console.log('ID du project subject =>'+ this.ID);
    var copies = this.getUserListWithNoCurrentUser();
    const dialogRef = this.dialog.open(OtherOptionsComponent, {
      width: '300px',
      data:{idProjet: this.ID, type: this.project_isparent, private: this.private, copies: copies, selected: this.tempMember},
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        if(result.value === "private"){
          //console.log("private "+JSON.stringify(result));
          this.sendMessageForm.controls.inCopy.patchValue([]);
          this.sendMessageForm.get('private').setValue(true);
          this.private = true;
          this.allcopies = false;
        } else if(result.value === "notprivate"){
          //console.log("private "+JSON.stringify(result));
          this.sendMessageForm.controls.inCopy.patchValue([]);
          this.sendMessageForm.get('private').setValue(false);
          this.private = false;
          this.tempMember = [];
          this.copiesusers = [];
          this.toggleAllSelection();
        } else if(result.value === "deadline"){
          //this.sendMessageForm.controls.inCopy.patchValue([]);
          //this.sendMessageForm.get('private').setValue(true);
          //console.log("deadline "+JSON.stringify(result));
          this.sendMessageForm.get('deadLine').setValue(result.deadline); 
          result.deadline = moment(result.deadline).format('DD-MM-YYYY');
          this.echeance = result.deadline.toLocaleString( );
          this.relanceOne = result.relanceOne;
          this.relanceOneDate = moment(result.relanceOne).format('DD-MM-YYYY').toLocaleString( );
          this.relanceTwo = result.relanceTwo;
          this.relanceTwoDate = moment(result.relanceTwo).format('DD-MM-YYYY').toLocaleString( );
          this.sendMessageForm.get('relanceOne').setValue(result.Relance1);
          this.sendMessageForm.get('relanceTwo').setValue(result.Relance2);
          this.sendMessageForm.get('relanceTree').setValue(result.Relance3);
        } else if(result.value === "copy"){
          this.sendMessageForm.get('private').setValue(false);
          this.private = false;
          this.allcopies = false;
          this.toggleAllSelection();
          var copiesselected = result.copiesselected;
          this.tempMember = [];
          this.copiesusers = [];
          copiesselected.forEach(item => {
            console.log("item "+item);
            this.projectUsers.forEach(element => {
              console.log("element "+element.user.id);
              if(item == element.user.id){
                this.tempMember.push(item);
                this.copiesusers.push(element.user);
              }
            });
          });
          const elementtous: HTMLElement = document.getElementById('divtous') as HTMLElement
          elementtous.innerHTML = '';
          this.sendMessageForm.controls.inCopy.patchValue(this.tempMember);
        }
      }
      //console.log('The dialog was closed result '+result);
        //TO Do dialog ajout membre 
        //console.log(dialogRef.componentInstance.chosenSubject);
        //this.optionselected = dialogRef.componentInstance.chosenSubject;
    });
  }


  containsTheTag(newTag){
    for(var i = 0; i < this.projectTags.length; i++) {
      if(this.projectTags[i].id == newTag.id){
        return true;
      }
    }
    return false;
  }

  quitReply(messageData){
    this.writeMode = -1;
  }

  openAnswerMode(messageData) {
    //console.log(' messageData => '+ JSON.stringify(messageData));
    this.resetAnswerFrom();
    this.resetQuestionFrom();
    this.openWriteFace = false;
    this.showMessages = true;
    this.messageInfo = messageData;
    this.writeMode = 1;     
  }

  quitComment(messageData){
    this.writeMode = -1;
  }

  openCommentMode(messageData){
    if(messageData && messageData.isCommentActivated == true){
      //if (this.writeMode !== 2) {
        this.resetCommentForm();
        this.resetQuestionFrom();
        this.openWriteFace = false;
        this.showMessages = true;
        this.messageInfo = messageData;
        this.writeMode = 2;
      /*}
      else
      {
        this.writeMode = -1;
      }*/
    }
    else
    {
      if(!messageData) 
      {
        alert('Aucun message à commenter');
      }
      else if (messageData.isCommentActivated == false)
      {
        alert('Commentaire déactivé');
      }
      else
      {
        alert('oups Error !!!');
      }
    }
  }


  onSubmitAnswerForm(answerFormData) {

     // Process checkout data here
    //console.log('Your order has been submitted onSubmitAnswerForm ', answerFormData);

    this.submitted = true;

     // stop here if form is invalid
    if (this.sendanswerForm.invalid) {
      return;
    }
    
    this.sendAnswer(answerFormData);
    
    this.submitted = false;
    this.files = [];
    this.resetAnswerFrom();

    //scroll in view
     window.scroll(0,0);
    //this.divHeaderTable.nativeElement.scrollIntoView();

  }

  sendAnswer(answerData) {
    this.ISendMessage = true;
    this.authService.sendAnswer(
      answerData.answerMessage,
      this.messageInfo.tags[0],
      this.messageInfo.isPrivate,
      this.project,
      this.messageInfo.id,
      this.files,
      null,
      null,
      null
    )
    .subscribe(res => {
      //console.log('réponse answer => '+res);
      //this.writeMode = 0;
      this.writeMode = -1;
      //this.envoyerObs.next();
      this.messageService.messageRefreshConv();
      this.messageService.messageUpdated();
      this.ISendMessage = false;

      this.socketService.send({
        id: this.currentUser,
        action: this.ID,
        type: "CONVERSATION"
      });

    },error => {
      alert("oups une erreur s'est produite!");
      this.ISendMessage = false;
    });
  }

  onSubmitFormComment(commentFormData){

    // Process checkout data here
    //console.log('Your order has been submitted onSubmitFormComment ', commentFormData);

    this.submitted = true;

     // stop here if form is invalid
    if (this.formComment.invalid) {
      return;
    }
    
    this.sendComment(commentFormData);

    this.submitted = false;
    this.files = [];
    this.resetCommentForm();  

     //scroll in view
      window.scroll(0,0);
    //this.divHeaderTable.nativeElement.scrollIntoView();

  }

  sendComment(commentFormData) {
    this.ISendMessage = true;
    this.authService.sendComment(
      commentFormData.commentMessage,
      this.messageInfo.tags[0],
      this.messageInfo.isPrivate,
      this.project,
      this.messageInfo.id,
      this.files,
      null,
      null,
      null
    )
    .subscribe(res => {
      //console.log('réponse comment => '+res);
      //this.writeMode = 0;
      this.writeMode = -1;
      //this.envoyerObs.next();
      this.messageService.messageRefreshConv();
      this.messageService.messageUpdated();
      this.ISendMessage = false;

      this.socketService.send({
        id: this.currentUser,
        action: this.ID,
        type: "CONVERSATION"
      });

    },error => {
      alert("oups une erreur s'est produite!");
      this.ISendMessage = false;
    });
  }

  resetQuestionFrom(){
     // reset form
    // this.sendMessageForm.reset();
    // this.sendMessageForm.get('subProject').clearValidators();
    // this.sendMessageForm.get('subProject').updateValueAndValidity();
    // this.sendMessageForm.get('recipient').clearValidators();
    // this.sendMessageForm.get('recipient').updateValueAndValidity();
    // this.sendMessageForm.get('messageToSend').clearValidators();
    // this.sendMessageForm.get('messageToSend').updateValueAndValidity();
    // this.sendMessageForm.get('files').clearValidators();
    // this.sendMessageForm.get('files').updateValueAndValidity();
    // this.sendMessageForm.reset();
    
    

    //set defaut form values
    this.files = [];
    this.sendMessageForm.get('subProject').setValue('');
    this.sendMessageForm.get('subProject').setValidators([Validators.required]);
    this.sendMessageForm.get('subProject').updateValueAndValidity();
    this.sendMessageForm.get('newSubProject').setValue('');
    this.sendMessageForm.get('recipient').setValue('');
    this.sendMessageForm.get('recipient').setValidators([Validators.required]);
    this.sendMessageForm.get('recipient').updateValueAndValidity();
    this.sendMessageForm.get('inCopy').setValue([]);
    this.sendMessageForm.get('private').setValue(false);
    this.sendMessageForm.get('deadLine').setValue(null);
    this.echeance = null;
    this.sendMessageForm.get('relanceOne').setValue(null);
    this.sendMessageForm.get('relanceTwo').setValue(null);
    this.sendMessageForm.get('relanceTree').setValue(null);
    this.sendMessageForm.get('messageToSend').setValue('');
    this.sendMessageForm.get('messageToSend').setValidators([Validators.required, Validators.minLength(1)]);
    this.sendMessageForm.get('messageToSend').updateValueAndValidity();
    this.sendMessageForm.get('files').setValue(null);
    this.sendMessageForm.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]); 
    this.sendMessageForm.get('files').updateValueAndValidity();
    
    // Object.keys(this.sendMessageForm.controls).forEach(key => {
    //   this.sendMessageForm.controls[key].setErrors(null)
    // });
  }


  redirectTache(){
   //this.router.navigateByUrl('/reunion/'+this.ID)
   //alert("En cours de développement");
   this.router.navigateByUrl('/faceTofacesProposal/'+this.ID+'/openprop');
  }

  redirectReunions(){
   //this.router.navigateByUrl('/reunion/'+this.ID)
   this.router.navigateByUrl('/faceTofacesReunion/'+this.ID+'/openmeet');
  }

  redirectPlan(){
    this.router.navigateByUrl('/plan/'+this.ID)
  }

  redirectCompteRenduReunions(){
    this.router.navigateByUrl('/compteRenduRenunion/'+this.ID)
  }

  changePrivacy(val){
    this.sendMessageForm.get('private').setValue(!val);
  }

  resetAnswerFrom(){
    
    // reset form
    this.sendanswerForm.get('answerMessage').clearValidators();
    this.sendanswerForm.get('answerMessage').updateValueAndValidity();
    this.sendanswerForm.get('files').clearValidators();
    this.sendanswerForm.get('files').updateValueAndValidity();
    this.sendanswerForm.reset();

    //set defaut form values
    this.sendanswerForm.get('answerMessage').setValue('');
    this.sendanswerForm.get('answerMessage').setValidators([Validators.required, Validators.minLength(1)]);
    this.sendanswerForm.get('answerMessage').updateValueAndValidity();
    this.sendanswerForm.get('files').setValue(null);
    this.sendanswerForm.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]);
    this.sendanswerForm.get('files').updateValueAndValidity();

  }

  resetCommentForm(){

    // reset form
    this.formComment.get('commentMessage').clearValidators();
    this.formComment.get('commentMessage').updateValueAndValidity();
    this.formComment.get('files').clearValidators();
    this.formComment.get('files').updateValueAndValidity();
    this.formComment.reset();

    //set defaut form values
    this.formComment.get('commentMessage').setValue('');
    this.formComment.get('commentMessage').setValidators([Validators.required, Validators.minLength(1)]);
    this.formComment.get('commentMessage').updateValueAndValidity();
    this.formComment.get('files').setValue(null);
    this.formComment.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]);
    this.formComment.get('files').updateValueAndValidity();

  }

  getMediaLink(mediaUrl){
    return this.authService.getMediaData(mediaUrl);
  }

  getMediaThumbail(mediaUrl){
    return this.authService.getMediaThumbail(mediaUrl);
  }

  fctListeMembre(){
    const dialogRef = this.dialog.open(DialogMemberListe, {
      width: '500px',
      data:{idProjet: this.ID}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      if(result){
        //TO Do dialog ajout membre 
        this.fctAddMember();
      }
    });
  }

  fctAddMember() {
    const dialogRef = this.dialog.open(DialogAddMember, {
      width: '700px',
      data: {project: this.project, projectUsers: this.projectUsers}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result add membre '+result);    
    });
  }

  showHideAllMessage(){
    if (this.showMessages == true) {
        this.showMessages = false;
    }
    else{
       this.showMessages = true;
    }
  }


  loadAllMessage(){
    this.ged = false;
    this.chosenFilterProject = null;
    this.specMsg = null;
    this.showRedBar = false;
    this.specMsgType = null;
    this.chosenFilter = null;
    //this.envoyerObs.next();
    this.messageService.messageRefreshConv();
  }

  public filterMessage(wantedFilter){
    //console.log(' wantedFilter --> '+ JSON.stringify(wantedFilter));
    if (this.isEmpty(wantedFilter) == true) 
    {
      this.chosenFilterProject = null;
    }
    else 
    {
      this.chosenFilterProject = wantedFilter;    
    }
    this.ged = false;
    this.expanded = true;
    this.specMsg = null;
    this.showRedBar = false;
    this.specMsgType = null;
    //this.envoyerObs.next();
    this.messageService.messageRefreshConv();
  }

  isEmpty(obj) {
    if (obj) {
      for(var key in obj) {
        if ((key != 'projectId') && obj[key]) {
          return false;
        }
      }
    }
    return true;
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }



  userInCopyHasNoRead(element){
    if(!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
        return true;
      }
    }
    return false;
  }

  userInCopyHasRead(element){
    if(!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == true)){
        return true;
      }
    }
    return false;
  }

  isNoRead(element){
    if((element.recipient && element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.isRecipientRead == false))
    {
      return true;
    }
    return false;
  }

  isRead(element){
    if((element.recipient && element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.isRecipientRead == true))
    {
      return true;
    }
    return false;
  }


  userIsNotConcerned(element){
    if(!element) return true;
    if(element.recipient && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']))
    {
      return false;
    }
    for (var i = 0; i < element.copy.length; i++) {
      if(element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
        return false;
      }
    } 
    return true;
  }


  userHasRead(element){
    if(!element) return false;
    if((element.recipient && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])) && (element.isRecipientRead == true))
    {
      return true;
    }
    else if ((element.recipient && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])) && (element.isRecipientRead == false)) {
      return false;
    }
    return false;
  }

  inCopyHasRead(element){
    if(!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == true)){
        return true;
      }
      else if ((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)) {
        return false;
      }
    }
    return false;
  }

  userIsInCopy(element){
    if(!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if(element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
        return true;
      }
    } 
    return false;
  }

  userIsConcerned(element){
    if(!element) return false;
    if(element.recipient && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']))
    {
      return true;
    }
    if(element.author && (element.author.id == JSON.parse(localStorage.getItem('currentUser'))['id']))
    {
      return true;
    }
    for (var i = 0; i < element.copy.length; i++) {
      if(element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
        return true;
      }
    }
    if (element.answer) {
      for (var i = 0; i < element.answer.copy.length; i++) {
        if(element.answer.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
          return true;
        }
      }
    }
    return false;
  }


  youNeedToMarkAsRead(element){
    
    if(!element) return false;

    if((element.isRecipientRead == false) && (element.type != "COMMENT") && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])){
      return true
    }

    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
         return true
      }
    }

    if (element.answer) {
      
      if((element.answer.isRecipientRead == false) && (element.answer.type != "COMMENT") && (element.answer.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])){
        return true
      }

      for (var i = 0; i < element.answer.copy.length; i++) {
        if((element.answer.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.answer.copy[i].isRead == false)){
           return true
        }
      }

    }

    return false;

  }

  markAsReadAll(element){
     this.authService.markAsRead(element).subscribe(res => {
        let idCopy = this.readMarkCopyFct(element);
        if (idCopy) {
          this.authService.markAsReadCopy(idCopy).subscribe(res => {
            if (element.answer) {
              this.markAsReadAll(element.answer);
            }
            else
            {
              this.refreshPageObs.next();
              this.messageService.messageUpdated();
              return;
            }
          },
          error => {
            //alert("oups une erreur s'est produite!");
            if (element.answer) {
              this.markAsReadAll(element.answer);
            }
            else
            {
              this.refreshPageObs.next();
              this.messageService.messageUpdated();
              return;
            }
          });
        }
        else
        {
          if (element.answer) {
            this.markAsReadAll(element.answer);
          }
          else
          {
            this.refreshPageObs.next();
            this.messageService.messageUpdated();
            return;
          }
        }
      },
      error => {
        //alert("oups une erreur s'est produite!");
        let idCopy = this.readMarkCopyFct(element);
        if (idCopy) {
          this.authService.markAsReadCopy(idCopy).subscribe(res => {
            if (element.answer) {
              this.markAsReadAll(element.answer);
            }
            else
            {
              this.refreshPageObs.next();
              this.messageService.messageUpdated();
              return;
            }
          },
          error => {
            //alert("oups une erreur s'est produite!");
            if (element.answer) {
              this.markAsReadAll(element.answer);
            }
            else
            {
              this.refreshPageObs.next();
              this.messageService.messageUpdated();
              return;
            }
          });
        }
        else
        {
          if (element.answer) {
            this.markAsReadAll(element.answer);
          }
          else
          {
            this.refreshPageObs.next();
            this.messageService.messageUpdated();
            return;
          }
        }
      });
  }


  readMarkCopyFct(element){
      if (!element) {
        return null;
      }
      for (var i = 0; i < element.copy.length; i++) {
        if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
          return element.copy[i].id;
        }
      }
      return null;
  }

  markAsRead(element){
    if((element.isRecipientRead == false) && (element.type != "COMMENT") && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])){
      //console.log('lancement de en recipient');
      this.authService.markAsRead(element).subscribe(res => {
        this.refreshPageObs.next();
        this.messageService.messageUpdated();
      },
      error => {
        alert("oups une erreur s'est produite!");
      });
    }
    else
    {
      //console.log('lancement de en copie');
       for (var i = 0; i < element.copy.length; i++) {
        if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
          this.authService.markAsReadCopy(element.copy[i].id).subscribe(res => {
            this.refreshPageObs.next();
            this.messageService.messageUpdated();
          },
          error => {
            alert("oups une erreur s'est produite!");
          });
          return;
        }
      }
    }
  }

  markAsReadbis(element){

     this.authService.markAsRead(element).subscribe(res => {
        if (element.answer) {
          this.authService.markAsRead(element.answer).subscribe(res => {
            this.refreshPageObs.next();
            this.messageService.messageUpdated();
          },
          error => {
            alert("oups une erreur s'est produite!");
          });
        }
        else
        {
          this.refreshPageObs.next();
          this.messageService.messageUpdated();
        }
      },
      error => {
        alert("oups une erreur s'est produite!");
      });

  }

  markAsReadCopy(element){
      for (var i = 0; i < element.copy.length; i++) {
        if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
          this.authService.markAsReadCopy(element.copy[i].id).subscribe(res => {
            this.refreshPageObs.next();
            this.messageService.messageUpdated()
          },
          error => {
            alert("oups une erreur s'est produite!");
          });
        }
      }

      for (var index = 0; index < element.answer.copy.length; index++) {
        if((element.answer.copy[index].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.answer.copy[index].isRead == false)){
          this.authService.markAsReadCopy(element.answer.copy[index].id).subscribe(res => {
            this.refreshPageObs.next();
            this.messageService.messageUpdated();
          },
          error => {
            alert("oups une erreur s'est produite!");
          });
        }
      }
  }


  fctinfoProjet(): void{
    const dialogRef = this.dialog.open(projectInfoDialog, {
      width: '500px',
      data: this.projectFull
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed fctinfoProjet '+result); 
        if (result && result.deleteReturn) {
          this.authService.obRefreshProjet.next();
          this.router.navigate(['/']);
        }else if (result) {
          this.projectFull = result;
          this.authService.obRefreshProjet.next();
       }  

    });
  }

  changeSubject(){
    
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowProjectTutobis(false);
    }

    const dialogRef = this.dialog.open(subjectInfodialog, {
      width: '500px',
      data: {
        projectData : this.projectFull,
        sendMessageForm : this.sendMessageForm
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed fctinfoProjet '+result); 
      if (result) {
       this.sendMessageForm.patchValue({
          subProject: result
        });
      } 
    });

  }

  disabledMessage(messageData): void{
    this.authService.disableMessage(messageData)
    .subscribe(res => {
      this.refreshPageObs.next();
      this.messageService.messageUpdated();
    },
    error => {
      alert("oups une erreur s'est produite!");
    }); 
  }

  recalls(messageData): void{
    this.authService.recalls(messageData).subscribe(res => {
      this.refreshPageObs.next();
      this.messageService.messageUpdated();
      alert("Rappel envoyé");
    },
    error => {
      //console.log('error '+JSON.stringify(error));   
      alert(error['error']['hydra:description']);
    }); 
  }
 
  compare( a, b ) {
    if ( a.fullName.toLowerCase() < b.fullName.toLowerCase() ){
      return -1;
    }
    if ( a.fullName.toLowerCase() > b.fullName.toLowerCase() ){
      return 1;
    }
    return 0;
  }

  /*getContact(){

    if (!this.iDProjet) {
      return;
    }

    this.authService.getTheProject(this.iDProjet)
    .subscribe(res => {      
      var tmpArrayUser = res['userProject'];
      this.contacts = [];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].user){
          this.contacts.push(tmpArrayUser[i].user);
        }
      }
      this.contacts.sort(this.compare);
    }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });
    
  }*/

  /*getSujet(idProject){
    if (!idProject) {
      return;
    }
    this.projectId = idProject;
    this.authService.getTheProject(idProject).subscribe(res => {
      this.projectTags = res['tags'];
      console.log({projectTags:this.projectTags})
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }
*/

  getProjectTags(){
    if (!this.iDProjet) {
      return;
    }

    this.projectId = this.iDProjet;

    this.authService.getTheProject(this.iDProjet).subscribe(res => {
      this.projectTags = res['tags'];
      this.projectTags.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      });
      this.selectSubject.open();
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }

  getInfoProject(){

   // console.log('getInfoProject id project => '+ this.iDProjet);

    if (!this.iDProjet) {
      return;
    }

    this.projectId = this.iDProjet;

    this.authService.getTheProject(this.iDProjet).subscribe(res => {
      this.projectTags = res['tags'];
      this.projectTags.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })
      this.getProjectExp();
      this.getProjectDest();
      if (document.getElementById('selectRecipient') && (this.openWriteFace == true)) {
        //this.selectRecipient.open();
      }
      
      /*var tmpArrayUser = res['userProject'];
      this.contacts = [];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].user){
          this.contacts.push(tmpArrayUser[i].user);
        }
      }
      this.contacts.sort(this.compare);*/
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
    
  }

  getProjectExp(){
    this.authService.getContactsExpID(this.iDProjet).subscribe(res => {
      //var tmpArrayUser = res['hydra:member'];
      this.contactsExp = [];
      this.contactsExp = res['hydra:member'];
      this.contactsExp.sort(this.compare);
      /*
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].user){
          this.contactsExp.push(tmpArrayUser[i].user);
        }
      }
      this.contactsExp.sort(this.compare);
      */
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }

  getProjectDest(){
    this.authService.getContactsRecipID(this.iDProjet).subscribe(res => {
      var tmpArrayUser = res['hydra:member'];
      this.contactsDest = [];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].id){
          this.contactsDest.push(tmpArrayUser[i]);
        }
      }
      this.contactsDest.sort(this.compare);
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }


  getReceiver(recipientid){
    this.authService.getRecipientsBySender(this.iDProjet, recipientid).subscribe(res => {
      var tmpArrayUser = res['hydra:member'];
      this.contactsDest = [];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].id){
          this.contactsDest.push(tmpArrayUser[i]);
        }
      }
      this.contactsDest.sort(this.compare);
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }





  launchSearch(){

    this.modeDoc = false;
    this.hiddenSearchEngin = true;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut, 
      type: this.type,
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearch '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearch.emit(this.chosenFilter);

  }

  launchSearchDoc(){

    this.modeDoc = true;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut,
      type: this.type, 
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearchDoc '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearchDoc.emit(this.chosenFilter);

  }

  public resetSearch(){

    this.transmitter = null;
    this.recipient = null;
    this.projectId = null;
    this.dateBefore  = null;
    this.dateAfter  = null;
    this.tag = null;
    this.statut = null;
    this.type = null;
    this.keyWord = null;
    this.smartNumber = null;
 
    /*this.getContact();
    this.getSujet(this.iDProjet); */
    this.getInfoProject();

    if(this.modeDoc == false) 
    {
      this.launchSearch();
    }
    else
    {
      this.launchSearchDoc();
    }

  }

  public projecthasChange(newIdProject){

    //console.log(' projecthasChange => '+newIdProject);
    
    this.iDProjet = newIdProject;
    this.ID = newIdProject;
    this.transmitter = null;
    this.recipient = null;
    this.projectId = null;
    this.dateBefore  = null;
    this.dateAfter  = null;
    this.tag = null;
    this.statut = null;
    this.type = null;
    this.keyWord = null;
    this.smartNumber = null;

    /*this.getContact();
    this.getSujet(this.iDProjet);*/
    this.getInfoProject();
    

  }

  public refreshInfoFilter(){  
    /*this.getContact();
    this.getSujet(this.iDProjet);*/
    this.getInfoProject();
  }

  openDialogInfoWrite(){
    
    const dialogRef = this.dialog.open(infoWriteMessageDialog, {
      width: '900px',
      data:{}
    });

    dialogRef.afterClosed().subscribe(result => {
       
      if (result){ 
       
      }

    })

  }

  openDialogEcheance(){
    
    const dialogRef = this.dialog.open(DeadLineConvDialog, {
      width: '500px',
      data:{}
    });
 
    dialogRef.afterClosed().subscribe(result => {
       
      if (result){ 
        //console.log({resultatdate:result});
        this.sendMessageForm.get('deadLine').setValue(result.deadline); 
        result.deadline = moment(result.deadline).format('DD-MM-YYYY');
        this.echeance = result.deadline.toLocaleString( );
        this.sendMessageForm.get('relanceOne').setValue(result.Relance1);
        this.sendMessageForm.get('relanceTwo').setValue(result.Relance2);
        this.sendMessageForm.get('relanceTree').setValue(result.Relance3);
      }

    })

  }



}

@Component({
  selector: 'memberListe-dialog',
  templateUrl: './memberListe-dialog.html',
  styleUrls: ['./memberListe-dialog.scss']
})
export class DialogMemberListe {
  
  userID = JSON.parse(localStorage.getItem('currentUser'))['id'];
  userAdmin = false;
  projectUsers = [];
  secteur ="";

  constructor(
    public dialogRef: MatDialogRef<DialogMemberListe>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog) {
      
      this.secteur = localStorage.getItem('secteur');
      this.authService.getTheProject(data.idProjet)
      .subscribe(res => {
        var tmpArrayUser = res['userProject'];
        this.projectUsers = [];
        var users = tmpArrayUser.filter(a => a.user != null);
        users.sort((a, b) => {
          if (a.user.fullName.toLowerCase() < b.user.fullName.toLowerCase()){
            return -1;
          }
          if (a.user.fullName.toLowerCase() > b.user.fullName.toLowerCase() ){
            return 1;
          }
          return 0;
        });
        for (var i = 0; i < users.length; i++) {
          // if(tmpArrayUser[i].user){
            if(users[i].isCreator){
              this.projectUsers.unshift(users[i]);
            }else{
              this.projectUsers.push(users[i]);
            }
            
          // }
        }
        var invited = tmpArrayUser.filter(a => a.user == null);
        invited.sort((a, b) => {
          if (a.email < b.email){
            return -1;
          }
          if (a.email > b.email ){
            return 1;
          }
          return 0;
        });
        for (var i = 0; i < invited.length; i++) {
              this.projectUsers.push(invited[i]);
        }
        /*
        tmpArrayUser.sort((a, b) => {
          if ( a.user && a.user.fullName < b.user.fullName){
            return -1;
          }
          if ( a.user && a.user.fullName > b.user.fullName ){
            return 1;
          }
          return 0;
        });
        
        for (var i = 0; i < tmpArrayUser.length; i++) {
          // if(tmpArrayUser[i].user){
            if(tmpArrayUser[i].isCreator){
              this.projectUsers.unshift(tmpArrayUser[i]);
            }else{
              this.projectUsers.push(tmpArrayUser[i]);
            }
            
          // }
        }*/
        

        for (var i = 0; i < this.projectUsers.length; i++) {
          if((this.projectUsers[i].isAdmin == true) && (this.projectUsers[i].user.id == this.userID))
          {
            this.userAdmin = true;
          }
        }
          //console.log("fin requete =>"+this.projectUsers);
         
      });


  }

  getbackGroundColor(){
    if (this.data.projectAction) {
      return "#645994"
    }
    else if (this.data.projectMeetting){
      return "#5d97eb"
    }
    else
    {
      return "#05ab69"
    }
  }

  updateListUser(contact,values){
    this.authService.updateUserProject(contact, values)
    .subscribe(res => {
      for (var i = 0; i < this.projectUsers.length; i++) {
        if(res['id'] ===  this.projectUsers[i]['id'])
          this.projectUsers[i] = res;
      }
    });
  }


  updateUserProject(contact, key){
    let values = null;
    switch (key) {
      case 1:
        values = {isAdmin: false};
        this.updateListUser(contact, values);
        break;
      case 2:
        values = {isMember: false};
        this.updateListUser(contact, values);
        break;
      case 3:
        values = {isAdmin: true};
        this.updateListUser(contact, values);
        break;
      case 5:
        values = {isMember: true};
        this.updateListUser(contact, values);
        break;
      case 4:
        const fullname = contact.user ? contact.user.fullName : contact.email;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '350px',
          data: "Vous êtes sur le point de retirer "+fullname+" du projet ?"
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.authService.deleteUserProject(contact)
            .subscribe(res => {
              for (var i = 0; i < this.projectUsers.length; i++) {
                if(contact['id'] ===  this.projectUsers[i]['id'])
                  this.projectUsers.splice(i, 1); 
              }
            });
          }
        });
        break;
      default:
        break;
    }
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showProfil(idUser): void{
    this.router.navigate(['/profile/'+idUser]);
    this.dialogRef.close();
  }


}
 

/////////////////// add contact view dialog ///////////////////

/* Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'addMember-dialog',
  templateUrl: './addMember-dialog.html',
  styleUrls: ['./addMember-dialog.scss']
})
export class DialogAddMember {
 
  contacts = [];
  secteur = "";
  // userStatus : { name: string }[];
  projectUsers = [];
  userStatus = null;
  showNewContactInput = false;
  searchContact: any;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  matcher = new MyErrorStateMatcher();
  addingMember = false;
  @ViewChild('emailInput', {static: true}) emailInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DialogAddMember>,
    @Inject(MAT_DIALOG_DATA) public data: any,
     private authService: AuthService,
     private messageService: MessageService) {

      /*this.authService.getContacts().subscribe( dataContact => {
        this.contacts = dataContact['hydra:member'];
      });*/
      
      this.contacts = [];
      this.secteur = localStorage.getItem('secteur');

      this.loadContact();

      this.authService.getTheProject(this.data.project.replace("/api/projects/", ""))
      .subscribe(res => {
        //console.log(res)
        var tmpArrayUser = res['userProject'];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].user){
            this.projectUsers.push(tmpArrayUser[i]);
          }
        }

        let userStatus = []; 
        for (let index = 0; index < this.projectUsers.length; index++) {
          if(this.projectUsers[index]['user'])
          userStatus[this.projectUsers[index]['user']['id']] = this.projectUsers[index]['status'];
        }
        this.userStatus = userStatus;
        //console.log(this.userStatus)
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });


      //console.log(' this.data.projectUsers ==> '+JSON.stringify(this.data.projectUsers));
      
  }


  getbackGroundColor(){
    if (this.data.projectAction) {
      return "#645994"
    }
    else
    {
      return "#05ab69"
    }
  }

  loadContact(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);

      //this.contacts = res['hydra:member'];
      if(res['hydra:view']['hydra:next']){
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    }); 
  }


  newContact(){
    if (this.showNewContactInput == true)  
      this.showNewContactInput = false;
    else
      this.showNewContactInput = true;
  }


   fctAddcontactByMail(){

      // Process checkout data here
      //console.log('Your order has been submitted', customerData);

       // stop here if form is invalid
      if (this.emailFormControl.status == 'INVALID') {
        return;
      }

      var result = this.emailFormControl.value;

      this.authService.addUserProject(this.data.project, result, true).subscribe(res => {
         //console.log('retour ajout membre mail => '+JSON.stringify(res));
        this.searchContact = "";
        alert('Invitation envoyé'); 
        
        if(res['user'] && res['user']['id']){
          var newUser = res;
          this.userStatus[res['user']['id']] = res['status'];
          this.data.projectUsers.push(newUser);
          this.messageService.needRefreshFiltre();
         }

         //this.emailFormControl.value = null;
         this.emailInput.nativeElement.value = "";
      },
      (error) => {
       
        if(error.error && error.error.violations){
          alert(error.error.violations[0].message);
        }
        else
        {
          alert(error.statusText);
        }

      });

   }

  onSubmitForm(customerData) {
     // Process checkout data here
    //console.log('Your order has been submitted', customerData);

     // stop here if form is invalid
    if (this.emailFormControl.status == 'INVALID') {
      return;
    }

    this.authService.addUserProject(this.data.project, customerData, true).subscribe(res => {
       //console.log('retour ajout membre mail => '+JSON.stringify(res));
      this.searchContact = "";
      alert('Invitation envoyé'); 
      
      if(res['user'] && res['user']['id']){
        var newUser = res;
        this.userStatus[res['user']['id']] = res['status'];
        this.data.projectUsers.push(newUser);
        this.messageService.needRefreshFiltre();
       }
    },
    (error) => {
     
      if(error.error && error.error.violations){
        alert(error.error.violations[0].message);
      }
      else
      {
        alert(error.statusText);
      }

    });

  }

  fctAdMember(userInfo){
    if(this.addingMember) return false;
    this.addingMember = true;
    this.authService.addUserProject(this.data.project, userInfo.id, null).subscribe(res => {
       //console.log('retour ajout membre '+JSON.stringify(res['user']));
       if(res){
        var newUser = res;
        this.userStatus[res['user']['id']] = res['status'];
        this.data.projectUsers.push(newUser);
        this.searchContact = "";
       }
       this.addingMember = false;
    },
    (error) => {
      this.addingMember = false;
      if(error.error && error.error.violations){
        alert(error.error.violations[0].message);
      }
      else
      {
        alert(error.statusText);
      }

    });

  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


////////////////// dialog project info ////////////////////


@Component({
  selector: 'projectInfo-dialog',
  templateUrl: './projectInfo-dialog.html',
  styleUrls: ['./projectInfo-dialog.scss']
})
export class projectInfoDialog {
  
  imageFil = null;
  img = null;
  currentUser: any;
  secteur = "";
  isAdmin = false; 
  noChangeImage = true;
  modeChangeName = false;
  firstFormGroup: FormGroup;
  modeSupp = false;
  userData : any;
  nameproject = "";
  constructor(
    public dialogRef: MatDialogRef<projectInfoDialog>,
    @Inject(MAT_DIALOG_DATA) public projectData: any,
    private authService: AuthService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private _formBuilder: FormBuilder) {
      this.isAdmin = true;
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id']; 
      this.secteur = localStorage.getItem('secteur');
      this.nameproject = this.projectData.name;
      /*
      if(this.projectData.creator.id == this.currentUser) {
        this.isAdmin = true;
      }*/

      this.firstFormGroup = this._formBuilder.group({
        projetName: ['', Validators.required]
      });

  }

  ChoseImage(event): void{  

    const reader = new FileReader();
    var files = event.files;

    // loop through files
    for (var i = 0; i < files.length; i++) {
      this.imageFil = files[i];
    }

    reader.readAsDataURL(this.imageFil);
      
    reader.onload = () => {
      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
      this.img = reader.result;
    }; 

  }
  
  onClickChangeName(): void{
    this.modeChangeName = true;
  }


  onClickAnnuler(): void{
    this.modeChangeName = false;
  }


  onClickValidate(): void{
    if(this.imageFil != null){
     this.authService.projectImage(this.projectData.id, this.imageFil)
     .subscribe(  
      (success) => {
        //console.log('retour image success => '+JSON.stringify(success));
        alert('Modification enregistré');
      },
      (error) => {
        alert('Erreur ! : ' + error)
      });
    }
    else
    {
      alert('Veuillez choisir une image pour le projet')
    }
  }


  onSubmitProjectName(customerData){
  
    //console.log('firstFormGroup ==>' , customerData);

    if (this.firstFormGroup.get('projetName').value.trim().length == 0 ){
      this.firstFormGroup.patchValue({
        projetName: '' 
      });
    }

     // stop here if form is invalid
    if (this.firstFormGroup.invalid) {
      return;
    }

     // Creation du projet
    this.authService.changeProjetName(customerData.projetName, this.projectData.id).subscribe(
      res => {
        //console.log('retour firstFormGroup ==>'+ res);
        //this.stepper.selectedIndex = 1;
        this.projectData = res;
        this.dialogRef.close(res);
      },
      (error) => {
        alert('Erreur ! : ' + error)
      }
    );

     this.modeChangeName = true;

  }

  onClickdelete(): void {
    this.modeSupp = true;
  }

  onClickCancelSupp(): void{
    this.modeSupp = false;
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickConfirmeDelete(): void{
    this.authService.deleteProject(this.projectData.id).subscribe(
      res => {   
        this.messageService.messageUpdated();
        this.authService.obRefreshProjet.next();
        this.dialogRef.close({deleteReturn: true});
        //this.stepper.selectedIndex = 1;
      },
      (error) => {
        alert('Erreur ! : ' + error)
      }
    );
  }

}

////////////////// dialog transfert ///////////////////

@Component({
  selector: 'dialogTransfert',
  templateUrl: 'transfertdialog.html',
  styleUrls: ['./transfertdialog.scss']
})
export class transfertdialog {

  submitted = false;
  sendMessageForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<transfertdialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
     private authService: AuthService) {
    
      this.sendMessageForm = this.formBuilder.group({
        Destinataire: ['', Validators.required],
        EnCopie: '',
        message:  ''
     });
  }

  onSubmitForm(customerData) {

     // Process checkout data here
    // console.log(JSON.stringify(this.files));
    
    //console.log('Your order has been submitted', customerData);

    this.submitted = true;
     // stop here if form is invalid
    if (this.sendMessageForm.invalid) {
      return;
    }
    
    this.sendTransfert(customerData);

    this.submitted = false;
    //this.sendMessageForm.reset();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getbackGroundColor(){
    if (this.data.projectAction) {
      return "#6e73b2"
    }
    else if (this.data.projectMeetting){
      return "#5d97eb"
    }
    else
    {
      return "#05ab69"
    }
  }

  getErrorMessage() {
    return this.sendMessageForm.controls.Destinataire.hasError('required') ? 'Veuillez entrer une adresse email' :
        this.sendMessageForm.controls.Destinataire.hasError('email') ? 'Email non valide' : '';
  }

  sendTransfert(customerData){
    //console.log({affichage:this.data});
    this.authService.transfertByMail(customerData, this.data.message).subscribe(  
    (success) => {
      //console.log('retour image success => '+JSON.stringify(success));
      alert('Message transferé');
      this.dialogRef.close();
    },
    (error) => {
      alert('Erreur ! : ' + error)
    });
  }


}

////////////////// dialog change subject ///////////////////



@Component({
  selector: 'subjectInfo-dialog',
  templateUrl: './subjectInfo-dialog.html',
  styleUrls: ['./subjectInfo-dialog.scss']
})
export class subjectInfodialog {
  
 
  currentUser: any;
  projectTag = [];
  isAdmin = false; 
  renameSubject = '';
  newSubject = '';
  editeSubjectMode = false;
  addSubjectMode = false;
  tagInfo: any;

  constructor(
    public dialogRef: MatDialogRef<subjectInfodialog>,
    @Inject(MAT_DIALOG_DATA) public dataGet: any,
    private authService: AuthService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private _formBuilder: FormBuilder) {
     
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id']; 
      if(this.dataGet.projectData.creator.id == this.currentUser) {
        this.isAdmin = true;
      }

      this.projectTag = this.dataGet.projectData['tags'];

      /*console.log(' les tag ==> '+ JSON.stringify(this.projectTag[0]));
      console.log(' les tag length ==> '+ this.projectTag.length);*/

  }

  fctChangeSubject(element){
    this.renameSubject = '';
    this.tagInfo = element;
    this.editeSubjectMode = true;
  }

  doTextareaValueChange(ev) {
    this.renameSubject = ev.target.value;
  }

  validerModif(tag){

    if (this.renameSubject.trim() != '') {
      this.authService.changeSubjectName(this.renameSubject, tag.id)
        .subscribe(res => {
          /*console.log(' retour validerModif => '+res);*/
          tag.libelle = this.renameSubject;
          if (tag.id == this.dataGet.sendMessageForm.controls.subProject.value.libelle ) {
            this.dataGet.sendMessageForm.patchValue({
              subProject: tag
            });
          }
          this.tagInfo = null;
          this.editeSubjectMode = false;
          this.renameSubject = '';
          //this.envoyerObs.next();
          this.messageService.messageRefreshConv();
          this.messageService.messageUpdated();
          this.messageService.needRefreshFiltre();

        }, (error) => {
           //console.log(' getTheProject subscribe error '+error.statusText);
      }); 
    }
    else
    {
      this.tagInfo = null;
      this.editeSubjectMode = false;
      this.renameSubject = '';
    }

  }
  
  fctChoseTag(tag): void {
    this.dialogRef.close(tag);
  }

  fctaddSubjectMode(): void {
    this.addSubjectMode = true;  
  }

  addSubjectCancel(): void {
    this.addSubjectMode = false; 
  }

  addSubject(): void {

    this.authService.addNewSubject(this.newSubject, this.dataGet.projectData.id)
      .subscribe(res => {
        //console.log('res tagg => '+JSON.stringify(res));
        this.projectTag.push(res);
        this.messageService.messageRefreshConv();
        this.messageService.messageUpdated();
        this.messageService.needRefreshFiltre();
        this.newSubject = '';
        this.addSubjectMode = false; 
      }, (error) => {
       alert('Erreur');
    }); 
  }

  onSelectNewSubProject(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubject = newSubProjetName;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

////////////////// dialog info write message ///////////////////


@Component({
  selector: 'infoWriteMessageDialog',
  templateUrl: 'infoWriteMessageDialog.html',
   styleUrls: ['infoWriteMessageDialog.scss']
})
export class infoWriteMessageDialog implements OnInit{
  lang='fr';

  constructor(
    public dialogRef: MatDialogRef<infoWriteMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder) {
     
  }

  ngOnInit() {
    this.messageService.getTranslate().subscribe((data) => {
      this.lang= data;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

////////////////// dialog info conv ///////////////////

@Component({
  templateUrl: './deadLineConvDialog.html',
  styleUrls: ['./deadLineConvDialog.scss'],
  providers: [DatePipe]
})
export class DeadLineConvDialog {
  
  deadline: Date | null;
  deadlineRelanceOne: Date | null;
  deadlineReLanceTwo: Date | null;
  deadlineReLanceTree: Date | null;
  public minDate = new Date();

  constructor(
    public dialogRef: MatDialogRef<DeadLineConvDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private datePipe: DatePipe) {


  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  validate(): void {

    if (!this.deadline) {
      alert('veuillez choisir une échéance');
      return;
    }

    var deadLineJson : any = {
      'deadline': this.deadline,
      'Relance1': this.deadlineRelanceOne,
      'Relance2': this.deadlineReLanceTwo,
      'Relance3': this.deadlineReLanceTree,
    };

    /*if (this.relaunchSevenDaysBefore == true) {
      var temp = new Date(this.deadline);
      temp.setDate(temp.getDate()-7);
      deadLineJson['relaunchArray'].push(this.datePipe.transform(temp, 'yyyy-MM-dd'));
    }

    if (this.relaunchThreeDaysBefore == true) {
      var temp = new Date(this.deadline);
      temp.setDate(temp.getDate()-3);
      deadLineJson['relaunchArray'].push(this.datePipe.transform(temp, 'yyyy-MM-dd'));
    }

    if (this.relaunchOnenDayBefore == true) {
      var temp = new Date(this.deadline);
      temp.setDate(temp.getDate()-1);
      deadLineJson['relaunchArray'].push(this.datePipe.transform(temp, 'yyyy-MM-dd'));
    }*/

    this.dialogRef.close(deadLineJson);
  
  }
 

}



