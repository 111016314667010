import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/services/message/message.service';
// import { LoadingScreenComponent } from "../../components/loading-screen/loading-screen.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email = '';
  password = '';
  lang  = 'fr';
  confirmError = false;
  submitted = false;
  hide = true;
  error = '';
  
  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService
    ) { }

  Login() {
    // this.loading = true;
    //console.log('you are logging in ' + this.email + ' ' + this.password);
    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
      if (this.lang == 'fr' && this.error == 'incorrect email / password') {
        this.error  = "email / mot de passe incorrect";
      }
      else if (this.lang == 'en' && this.error == 'email / mot de passe incorrect') {
        this.error = "incorrect email / password";
      }
    });

    this.authService.login( this.email, this.password)
    .pipe( first() )
    .subscribe(
      data => {
        //console.log('you are logging in ' + this.email + ' ' + this.password);
        this.router.navigate(['/']);
        // console.log(data);
      },
      err => {
        //console.log('login erreur ===> '+JSON.stringify(err));
        const error = err.error;
        const errorS = error.message;
        const errorD = error.detail;
        if (!errorD ) {
          this.error = (this.lang == 'fr' ? 'email / mot de passe incorrect' : 'incorrect email / password');
        } else if (!errorS) {
          this.error = errorD;
          this.confirmError = true;
        }
        //console.log(error);
        // console.log(errorS);
        // console.log(errorD);
        // this.loading = false;
      }
    );
  }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
  }

}
