import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DeadLineConvDialog } from '../single-project/single-project.component';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MatSelect, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-other-options',
  templateUrl: './other-options.component.html',
  styleUrls: ['./other-options.component.scss']
})
export class OtherOptionsComponent implements OnInit {

  chosenSubject : any;
  style = 1;
  echeance = null;
  relanceOne = null;
  relanceTwo = null;
  relanceTree = null;
  private = false;
  copies = [];
  selected = [];
  hideSelectCopy = true;
  copiesselected = [];
  searchContact: any;
  type = "";
  @ViewChild('selectCopy', {static: true}) private selectCopy: MatSelect;

  constructor(public  dialog: MatDialog, public dialogRef: MatDialogRef<OtherOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.private = data.private;
      this.copies = data.copies;
      this.type = data.type;
      this.copies.sort((a, b) => {
        if ( a.user.fullName < b.user.fullName ){
          return -1;
        }
        if ( a.user.fullName > b.user.fullName ){
          return 1;
        }
        return 0;
      });
      this.selected = data.selected;
      console.log("lenght  *  "+this.copies.length);
    }

  ngOnInit() {
  }

  fctCheckMember(userInfo){ 
    if(this.selected && !this.selected.includes(userInfo)){
      this.fctAdMember(userInfo);
    } else this.fctRemoveUser(userInfo);
  }

  fctAdMember(userInfo){ 
    this.selected.push(userInfo);
    this.searchContact = "";
  }

  fctRemoveUser(userInfo){
    const index = this.selected.indexOf(userInfo);
    if (index > -1) {
      this.selected.splice(index, 1);
      this.searchContact = "";
    }
  }

  closeAll(value){
    this.dialogRef.close();
  }

  
  chooseOption(value){
    //this.dialogRef.close();
    var resultobject = {"value": ""};
    resultobject.value = value;
    this.dialogRef.close(resultobject);
    /*
    this.dialogRef.afterClosed().subscribe(result => {
      if (result){ 
        result.value = value;
      }
    })*/
  }

  choosePrivate(value){
    //this.dialogRef.close();
    var resultobject = {"value": ""};
    resultobject.value = value;
    this.dialogRef.close(resultobject);
    /*
    this.dialogRef.afterClosed().subscribe(result => {
      if (result){ 
        result.value = value;
      }
    })*/
  }

  verif(){
    console.log("sel "+this.selected);
    var resultobject = {"value": "", "copiesselected": []};
    resultobject.value = "copy";
    resultobject.copiesselected = this.selected;
    this.dialogRef.close(resultobject);
  }

  chooseCopy(value){
    //this.dialogRef.close();
    this.hideSelectCopy = false;
    /*
    this.dialogRef.afterClosed().subscribe(result => {
      if (result){ 
        result.value = value;
      }
    })*/
  }

  openDialogEcheance(){
    
    const dialogRef = this.dialog.open(DeadLineConvDialog, {
      width: '500px',
      data:{}
    });   
    var resultobject = {"value": "", "echeance" : "", "relanceOne": "", "relanceTwo": "", "relanceTree" : ""};
    
    
 
    dialogRef.afterClosed().subscribe(result => {
       
      //console.log("priv first "+JSON.stringify(result));
      if (result){ 
        resultobject.value = "deadline";
        result.deadline = moment(result.deadline).format('DD-MM-YYYY');
        resultobject.echeance = result.deadline.toLocaleString( );
        resultobject.relanceOne = result.Relance1;
        resultobject.relanceTwo = result.Relance2;
        resultobject.relanceTree = result.Relance3;
        this.dialogRef.close(resultobject);
        /*
        this.sendMessageForm.get('relanceOne').setValue(result.Relance1);
        this.sendMessageForm.get('relanceTwo').setValue(result.Relance2);
        this.sendMessageForm.get('relanceTree').setValue(result.Relance3);
        */
      }

    })

  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

}
