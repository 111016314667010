import { Component, OnInit, Inject, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatButton, MatDialogConfig, MatRadioChange } from '@angular/material';
import { DashboardDialogComponent } from 'src/app/components/dashboard-menu/dashboard-menu.component';
import { MessageService } from 'src/app/services/message/message.service';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Validators, FormControl, FormGroup, FormBuilder} from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import {MatStepper} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Location } from "@angular/common";
import * as introJs from 'intro.js/intro.js';
import { projectInfoDialog } from '../single-project/single-project.component';
import { takeUntil } from 'rxjs/operators';
import { addMemberCompany } from 'src/app/company-member/company-member.component';
import { ConditionalExpr } from '@angular/compiler';


export interface Projects {
  name: string;
  date: string;
  groupName: string;
  url: string;
}

export interface DialogData {
  objectif: string;
  projetName: string;
  projects: Array<any>;
}


@Component({
  selector: 'app-dashboardnav',
  templateUrl: './dashboardnav.component.html',
  styleUrls: ['./dashboardnav.component.scss']
})
export class DashboardnavComponent implements OnInit, OnDestroy {

  introJS = introJs();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscription: Subscription;
  projectLists: Array<any> = [];
  projectListsParent: Array<any> = [];
  total_project = 0;
  name: any;
  profile: any;
  commonRef: any;
  notificationload: any;
  //dialog: any;
  token: any;
  objectif: string;
  projetName: string;
  currentProjectId: string;
  searchProject : any;
  currentUser: any;
  adminrole = false;
  lang = 'fr';
  displayedColumns: string[] = ['Columnsproject'];
  intro = null;
  stepProject = null;
  stepContact = null;
  stepNotif = null;
  stepInvit = null;
  stepBoard = null;
  skipLabel= null;
  doneLabel= null;
  prevLabel= null;
  nextLabel= null;
  showTool = false;
  route: string;
  userData : any
  Url = environment.server_url
  profileImg : any
  @ViewChild('faceAndyouGroups', {static: true}) projectListDiv: ElementRef;
  updclicked = false;
  projectFull: any;
  secteur = "";

  @Output() projectChosen = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private authServices: AuthService, 
    private router: Router, 
    private routeAc: ActivatedRoute,
    private translate: TranslateService,
    location: Location, 
    private messageService: MessageService) {

    if (this.messageService.getListProject().length > 0) {
      //console.log('tableau rempli');
      this.projectLists = this.messageService.getListProject();
      this.total_project = this.projectLists.length;
      //this.projectListsParent = this.projectLists;
      this.projectLists.forEach(element => {
        if(element.type !== "CHILD") this.projectListsParent.push(element);
      });
    }
    else
    {
     // console.log('tableau non rempli');
      this.loadProject();
    }
    this.secteur = localStorage.getItem('secteur');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id'];
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    if(this.userData.imageUrl){
      this.profileImg = this.Url + this.userData.imageUrl
    }
      //console.log({userData:this.userData})
    this.authServices.obRefreshProjet.subscribe(() =>{
      this.messageService.setListProject([]);
      this.projectLists = [];
      this.loadProject();
    });

    this.subscription = this.messageService.getMessage().subscribe(() => { 
      this.loadNotifProjet();
    });

    // console.log("translate", this.translate.get('guide.intro').subscribe);
    
    
    this.setVar();
    this.setTheGuide();
    this.messageService.getTranslate().subscribe((data) => {
      this.setVar();
      this.setTheGuide();
    });
    

    this.messageService.getGuide().subscribe(() => { 
      this.introJS.start().oncomplete(function() {
        window.localStorage['guide'] = 1;
      });
    });
    
    if (this.messageService.getShowCreateProjectTuto() == true) {
      this.showTool = true; 
    }
    
    this.messageService.getMessageIsSend().subscribe(() => {
       this.showTool = false; 
       this.messageService.setShowCreateProjectTuto(false);
    });

  }
  
  setVar(){
    this.translate.get('guide.intro').subscribe(value => { 
      this.intro = value;
   });
   this.translate.get('guide.stepProject').subscribe(value => { 
     this.stepProject = value;
   });
   this.translate.get('guide.stepContact').subscribe(value => { 
     this.stepContact = value;
   });
   this.translate.get('guide.stepNotif').subscribe(value => { 
     this.stepNotif = value;
   });
   this.translate.get('guide.stepInvit').subscribe(value => { 
     this.stepInvit = value;
   });
   this.translate.get('guide.stepBoard').subscribe(value => { 
     this.stepBoard = value;
   });
   this.translate.get('guide.skipLabel').subscribe(value => { 
    this.skipLabel = value;
    });
    this.translate.get('guide.doneLabel').subscribe(value => { 
      this.doneLabel = value;
    });
    this.translate.get('guide.prevLabel').subscribe(value => { 
      this.prevLabel = value;
    });
    this.translate.get('guide.nextLabel').subscribe(value => { 
      this.nextLabel = value;
    });
  }


  setTheGuide(){
    setTimeout(() => {
      this.introJS.setOptions({
        skipLabel: this.skipLabel,
        doneLabel: this.doneLabel,
        prevLabel: this.prevLabel,
        nextLabel: this.nextLabel,
        steps: [
          { 
            intro: this.intro,
          },
          {
            element: '#stepProject',
            intro: this.stepProject,
            position: 'left'
          },
          {
            element: '#stepContact',
            intro: this.stepContact,
            position: 'bottom'
          },
          {
            element: '#stepNotif',
            intro: this.stepNotif,
            position: 'bottom'
          },
          {
            element: '#stepInvit',
            intro: this.stepInvit,
            position: 'bottom'
          },
          {
            element: '#stepBoard',
            intro: this.stepBoard,
            position: 'bottom'
          }
        ]
      });

      // if(!window.localStorage['guide']){
      //   this.introJS.start().oncomplete(function() {
      //       window.localStorage['guide'] = 1;
      //   });
      // }

    }, 2000);
  }

  ngOnInit() {
    //this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.adminrole = this.userData.roles.includes('ROLE_MANAGER');
    //this.calculateDate();
    //this.total_project = 0;

    this.routeAc.params.subscribe(paramsId => {
      this.currentProjectId = paramsId.ID;
    });

  }
  calculateDate(date) {
    var result = 0;
    var today = new Date().setHours(0, 0, 0, 0);
    var yesterday = new Date(new Date().setDate(new Date().getDate()-1)).setHours(0, 0, 0, 0);
    date = new Date(date);
    if (date >= today) {
      result = 1;
    } else if (date >= yesterday) {
      result = 2;
    } else result = 3;
    return result;
  }


  changeLang(){
    this.translate.use(this.lang);
    this.messageService.setTranslate(this.lang);
  }

  userIsAdmin(project){
    if (project.creator.id == this.currentUser) {
        return 'admin';
    }
    var member = project['userProject'];
    for (var i = 0; i < member.length; i++) {
      if(member[i].user && member[i].user.id == this.currentUser && member[i].isAdmin == true){
        return 'co-admin';
      }
    }
    return 'membre';
  }


  loadProject(url?: string){
    url = url ? url : undefined;
    this.authServices.getProjects(url).subscribe(res => {
      //console.log('retour liste project!!! ');
      this.projectLists = this.projectLists.concat(res['hydra:member']);
      this.projectLists.forEach((value,index)=>{
          if(value.type !=="CHILD") this.projectListsParent.push(value);
      });
      //this.projectListsParent = this.projectLists.concat(res['hydra:member']);
      this.total_project = res['hydra:totalItems'];
      if(res['hydra:view']['hydra:next']){
        this.loadProject(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
      else if(res['hydra:totalItems'] == this.projectLists.length){
        this.messageService.setListProject(this.projectLists);
        this.loadNotifProjet();
      }
    }); 
  }

  loadNotifProjet(){
    this.authServices.getNotification().subscribe(res => {
      this.notificationload = <any>res;
      for (var i = 0; i < this.projectLists.length; i++) {
        for (var index = 0; index < this.notificationload.length; index++) {
          if(this.notificationload[index].id == this.projectLists[i].id) {
            this.projectLists[i].notif = this.notificationload[index].total;
          }
        }
      }
    });
  }

  updDialog(): void{
    this.updclicked = !this.updclicked;
  }
  
  openDialog(): void {
      
      this.showTool = false; 
      this.messageService.setShowCreateProjectTuto(false);
      this.messageService.setShowCreateProjectTutoIsFalse();
      if (this.messageService.getListProject().length > 0) {
        //console.log('tableau rempli');
        this.projectListsParent = [];
        this.projectLists = this.messageService.getListProject();
        this.projectLists.forEach(element => {
          if(element.type !== "CHILD") this.projectListsParent.push(element);
        });
      }
      const dialogRef = this.dialog.open(dashboarddialogCompo, {
        panelClass: 'dashboarddialog-container',
        width: '700px',
        height: '680px',
        data: {projetName: this.projetName, objectif: this.objectif, projects: this.projectListsParent}
      });

      dialogRef.afterClosed().subscribe(result => {
         /*console.log('this.objectif '+result.objectif);
         console.log('this.projetName '+result.projetName);*/
         if (result){ 
          this.projectLists.unshift(result);
          console.log("resuuuult    "+result.type);
          if(result.type !== "CHILD") this.projectListsParent.unshift(result)
          this.currentProjectId = result.id;
          this.projectChosen.emit(result.id);
          this.router.navigate(['/project/'+result.id+'/openmsg']);
          //  this.authServices.createProject(result.projetName, result.objectif, []).subscribe(
          //   res => {
          //      //console.log('retour creation project => '+JSON.stringify(res));
          //      this.projectLists.push(res)
          //      var lestElement = this.projectLists[this.projectLists.length-1];
          //      this.router.navigate(['/single/'+lestElement.id]);
          //   },
          //   (error) => {
          //     alert('Erreur ! : ' + error)
          //   }
          // );
        }
      })
  }

  fctinfoProjet(idproject: any, libelle: string): void{
    
    const dialogRef = this.dialog.open(projectInfoDialog, {
      width: '500px',
      data: {"id" : idproject, "name": libelle}
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed fctinfoProjet '+result); 
        if (result && result.deleteReturn) {
          //this.projectLists.filter(item => item.id == idproject)[0].libelle = "okkk";
          
          //this.authService.obRefreshProjet.next();
          //this.router.navigate(['/']);
        }else if (result) {
          (<HTMLInputElement>document.getElementById('project_'+idproject)).innerHTML = result.libelle;
          if(this.currentProjectId && this.currentProjectId == idproject){
            console.log("msg detect");
            this.messageService.setLibelleProject(result.libelle);
            //(<HTMLInputElement>document.getElementById('libproject_'+idproject)).innerHTML = result.libelle;
          }
          this.projectLists.forEach((value,index)=>{
            if(value.id==idproject) this.projectLists[index].libelle = result.libelle;
          });
          this.projectFull = result;
          //this.authService.obRefreshProjet.next();
       }  

    });
    
  }

  clickSuppMethod(id, libelle) {
    if(confirm("Voulez vraiment suppprimer le projet "+libelle)) {
      this.onClickConfirmeDelete(id);
    }
  }

  onClickConfirmeDelete(id): void{
    this.authServices.deleteProject(id).subscribe(
      res => {  
        document.getElementById('project_'+id).parentElement.parentElement.parentElement.remove();
        this.projectLists.forEach((value,index)=>{
          if(value.id==id) this.projectLists.splice(index,1);
      });
      },
      (error) => {
        alert('Erreur ! : ' + error)
      }
    );
  }
 
  fctprojectChosen(projectId){
    this.currentProjectId = projectId;
    this.projectChosen.emit(projectId);
    //this.router.navigate(['/project/'+projectId]);
    this.router.navigate(['/project/'+projectId+'/openmsg']);

    setTimeout(() => {
      const currentProj = this.projectLists.filter(item => item.id == projectId)[0];
      this.projectLists = this.projectLists.filter(item => item.id != projectId);
      this.projectLists.unshift(currentProj);
      this.projectListDiv.nativeElement.scrollTop = 0;
    }, 1000);
    
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  openDialogInfo(){
    const dialogRef = this.dialog.open(tutoCreateProjectdialog, {
      width: '850px',
      data:{}
    });
    dialogRef.afterClosed().subscribe(result => {
    })
  }


}


@Component({
  selector: 'dashboarddialog.component',
  templateUrl: 'dashboarddialog.component.html',
   styleUrls: ['dashboarddialog.component.scss']
})
export class dashboarddialogCompo implements OnInit{
  

  //@ViewChild('stepper') stepper: MatStepper;
  @ViewChild('addProjectForm', {static: true}) addProjectForm;
  firstFormGroup: FormGroup;
  firstFormGroupOne: FormGroup;
  firstFormGroupTwo: FormGroup;
  projetNameOnemodel = "";
  addingMember = [];
  hasMemberAdding = false;
  projectUsers = [];
  userStatus = [];
  subjectGroup = [];
  projectId = null;
  projects: Array<any> = [];
  members: Array<any> = [];
  tags: Array<any> = [];
  projectDetails = null;
  contacts = [];
  searchContact: any;
  arrayEmailInvitation = [];
  arrayEmailInvitationWithId = [];
  arraySubject = [];
  groupselected = 0;
  public projectCtrl: FormControl = new FormControl();
  saveProject = null;
  firstLoad = false;
  newProjectSubject = '';
  secteur = 'clients'
  currenteDate: any;
  showComfirmMessage = false;
  protected _onDestroy = new Subject<void>();
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  tagFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  createProjectMode = 1;
  memberdialog = 0;
  dupplicatestyle = 0;
  stepstyle = 0;
  projectFilter = "0";
  typeproject = '0';
  mailadded = "";
  duppselected = 1;
  projecttype = "PARENT";
  currentUser = null;
  @ViewChild('firstnextstep', {static: false}) firstnextstep: MatButton;

  constructor(
    public dialogRef: MatDialogRef<dashboarddialogCompo>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder) {

      /*this.authService.getContacts().subscribe( dataContact => {
        this.contacts = dataContact['hydra:member'];
      });*/
      this.contacts = [];
      this.projects = data.projects;
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      //this.projects = data.projects;
      this.loadContact();
      this.projectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProject();
      });
      

      /*
      let userStatus = []; 
        for (let index = 0; index < this.projectUsers.length; index++) {
          if(this.projectUsers[index]['user'])
          userStatus[this.projectUsers[index]['user']['id']] = this.projectUsers[index]['status'];
        }
      this.userStatus = userStatus;*/
      
    }
/*
    ngAfterViewInit() {
      var firststep = document.getElementById("firststep");
      var parent =  this.chooseTypeProject();
      firststep.addEventListener("keydown", function (e) {
        if (e.code === "Enter") { 
          console.log("yeah");
          parent;
          e.stopPropagation()
          parent;
          
        } 
    });
    }*/

    protected filterProject() {
      if(this.saveProject == null){
        this.saveProject = this.projects;
      }
  
      let search = this.projectCtrl.value;
      if (!search) {
        this.projects = this.saveProject;
        return;
      } else {
        search = search.toLowerCase();
        this.projects = this.saveProject.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
      }
    }

    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }

    radioButtonChange(data: MatRadioChange) {
      this.typeproject = data.value;
      this.createProjectMode = 3;
      if(data.value === '2'){
        this.projecttype = "CHILD";
      }
    }

    chooseTypeProject(){
      if (this.firstFormGroupOne.get('projetNameOne').value.trim().length > 0 ){
        this.authService.existProject(this.firstFormGroupOne.get('projetNameOne').value.trim()).subscribe(res => {
          //console.log('retour liste project!!! ');
          var resultat = ''+res;
          if(resultat === 'true'){
            alert("Ce magasin existe déjà");
          } else {
            this.createProjectMode = 3; 
            this.typeproject = '2';
            this.dupplicatestyle = 1;
            this.projecttype = "CHILD";
          }
        });  
       
      }
    }

    nextStepGroupMember(){
      this.createProjectMode = 3; 
      this.typeproject = "2";
    }

    ngOnInit() {
      this.secteur = localStorage.getItem('secteur');
      console.log("secteuuur   "+this.secteur);
      this.arrayEmailInvitation = [];
      this.arrayEmailInvitationWithId = [];
      this.addingMember = [];
      this.createProjectMode = 1;
      this.currenteDate = new Date();
      /*
      this.firstFormGroup = new FormGroup({
        projetNameOne: new FormControl(''),
        projetName: new FormControl(''),
        clickCreate: new FormControl(''),
        objectif: new FormControl(''),
        deadLine: new FormControl(''),
        sujectList: new FormControl(''),
     });*/
      this.firstFormGroup = this._formBuilder.group({
        projetName: ['', ],
        clickCreate: ['', [Validators.required, Validators.requiredTrue]],
        objectif: [''],
        deadLine: [''],
        sujectList: [[]]
      });
      
      this.firstFormGroupOne = new FormGroup({
        projetNameOne: new FormControl('')
     });
     this.firstFormGroupOne = new FormGroup({
        projetNameOne: new FormControl('')
     });
      this.firstFormGroupOne = this._formBuilder.group({
        projetNameOne: ['', Validators.required]
      });
      this.firstFormGroupTwo = this._formBuilder.group({
        mailadded: ['', ]
      });
    }

    getGroupData(idProject){
      this.projectId = idProject;
      //this.search()
      if (idProject) {
        this.loadMembers(idProject);
      }
      this.groupselected = 1;
    }

    

    loadFilterElement(){
      //console.log("on loadFilterElement");
      if (this.firstLoad == true) {
        this.loadProject();
      }
      this.firstLoad = false;
    }

    getTags(){
         this.authService.getAllTags().then((res)=>{
           console.log({allTags:res})
         }).catch((err)=>{
           console.log(err)
         })
    }

    loadProject(url?: string){
      url = url ? url : undefined;
      this.authService.getProjects(url).subscribe(res => {
        this.projects = this.projects.concat(res['hydra:member']);
        this.projects = this.projects.sort(function (a, b) {
            if (a.libelle.toLowerCase() < b.libelle.toLowerCase()) { return -1; }
            if (a.libelle.toLowerCase() > b.libelle.toLowerCase()) { return 1; }
            return 0;
        })
        if(res['hydra:view']['hydra:next']){
          this.loadProject(res['hydra:view']['hydra:next'].replace("/api", ""));
        }
      }); 
    }

    loadContact(url?: string) {
      url = url ? url : undefined;
      //this.authService.getContacts(url).subscribe(res => {
      this.authService.getCompagnyMember(this.currentUser['compId']).subscribe(res => {
        this.contacts = this.contacts.concat(res['hydra:member']);
        this.contacts = this.contacts.sort(function (a, b) {
          if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) { return -1; }
          if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) { return 1; }
          return 0;
      })
      this.contacts.forEach((value,index)=>{
        value.interne = true;
        //console.log("value "+JSON.stringify(value));
        if(value.id== this.currentUser.id) this.contacts.splice(index,1);
      });
      });
    }

    onNoClick(): void {
      this.dialogRef.close();
    }


    onSubmitProject(){
    
      this.firstFormGroup.patchValue({
        clickCreate: true 
      });

      //console.log('firstFormGroup value => ' , this.firstFormGroup.value);

      if (this.firstFormGroupOne.get('projetNameOne').value.trim().length == 0 ){
        this.firstFormGroup.patchValue({
          projetName: '' 
        });
      } else {
        this.firstFormGroup.patchValue({
          projetName: this.firstFormGroupOne.get('projetNameOne').value.trim() 
        });
      }

       // stop here if form is invalid
      if (this.firstFormGroup.invalid) {
        this.firstFormGroup.patchValue({
          clickCreate: '' 
        });
        return;
      }

     
      let result = this.firstFormGroup.value;
      
      let arrayUserProject = [];
      let arrayTagProject = [];

      if(this.dupplicatestyle === 1 || this.dupplicatestyle === 2 || this.createProjectMode === 2){
        for (var i = 0; i < this.addingMember.length; i++) {
          if(this.addingMember[i].interne === true){
            arrayUserProject.push({"user":"/api/users/"+this.addingMember[i].id});
          } else if(this.addingMember[i].interne === false){
            //arrayUserProject.push({"email":this.arrayEmailInvitation[i]});
            arrayUserProject.push({"email":this.addingMember[i].givenName});
          }
        }
      }

      /*
      if(this.dupplicatestyle === 1 || this.dupplicatestyle === 2 || this.createProjectMode === 2){
        for (var i = 0; i < this.arrayEmailInvitation.length; i++) {
          arrayUserProject.push({"email":this.arrayEmailInvitation[i]});
        }
      }*/

      if(this.dupplicatestyle === 1 || this.dupplicatestyle === 3){
        for (var i = 0; i < this.subjectGroup.length; i++) {
          arrayTagProject.push({"libelle":this.subjectGroup[i]});
        }
      }
      
      this.authService.createProject(result.projetName, result.objectif, result.deadLine, arrayTagProject, arrayUserProject, this.projecttype, this.projectId).subscribe(
        res => {
          this.projectDetails = res;
          this.projectId = res['id'];
          this.dialogRef.close(this.projectDetails);
          //this.stepper.selectedIndex = 1;
        },
        (error) => {
          alert('Erreur ! : ' + error)
        }
      );

    }

    validateClick(): void{
      this.addProjectForm.nativeElement.submit();
    // this.dialogRef.close(result);
    }


  fctAddcontactByMail() {

    // stop here if form is invalid
    if (this.emailFormControl.status == 'INVALID') {
      return;
    }

    var result = this.emailFormControl.value;

 
     this.authService.getUserInfo(result).subscribe(
        res => {
          
          var arrayUser = res["hydra:member"];
          
          if (arrayUser.length > 0) {
            var newUser = arrayUser[0];
            this.addingMember.push(newUser);
            this.userStatus[newUser['id']] = 'PENDING';
            this.hasMemberAdding = true;
            this.emailFormControl.reset();
            this.arrayEmailInvitationWithId.push(result);  
          }
          else
          {
            this.arrayEmailInvitation.push(result);   
            this.hasMemberAdding = true;
            this.emailFormControl.reset();
          }
          
          this.searchContact = "";
         
        },
        (error) => {
          alert('Erreur ! : ' + error)
        }
      );

  }


  fctAddByMailValue(mail) {

    // stop here if form is invalid
    if (mail == '') {
      return;
    }

    var result = mail;

 
     this.authService.getUserInfo(result).subscribe(
        res => {
          
          var arrayUser = res["hydra:member"];
          
          if (arrayUser.length > 0 ) {
            var newUser = arrayUser[0];
            newUser.interne = true;
            if (!(this.addingMember.includes(this.contacts.find(i => i.id === newUser.id)))){
            //this.addingMember.push(newUser);
            //this.addingMember.push(this.contacts.find(i => i.id === newUser.id));
            
              this.addingMember.push(this.contacts.find(i => i.id === newUser.id));
              console.log("user  =  "+JSON.stringify(this.addingMember));
              console.log("cont  =  "+JSON.stringify(this.contacts));
              this.userStatus[newUser['id']] = 'PENDING';
              this.hasMemberAdding = true;
              this.emailFormControl.reset();
              this.arrayEmailInvitationWithId.push(result); 
            }  
          }
          else
          {
            //this.arrayEmailInvitation.push();  
            console.log("res "+result);
            this.addingMember.push({"givenName": result, "familyName": "", "interne": false}); 
            this.hasMemberAdding = true;
            this.emailFormControl.reset();
          }
          
          this.searchContact = "";
         
        },
        (error) => {
          alert('Erreur ! : ' + error)
        }
      );

  }


  clickConfirm(){
    //this.dialogRef.close(this.projectDetails);
    this.createProjectMode = 4;
    this.showComfirmMessage = false; 
  }

  clickRefus(){
    this.showComfirmMessage = false;
  }

  goToSubject(){
    //console.log("Project ID go : "+this.projectDetails);
    if (this.firstFormGroupOne.get('projetNameOne').value.trim().length > 0 ){
      this.authService.existProject(this.firstFormGroupOne.get('projetNameOne').value.trim()).subscribe(res => {
        //console.log('retour liste project!!! ');
        var resultat = ''+res;
        if(resultat === 'true'){
          alert("Ce magasin existe déjà");
        } else {
          this.createProjectMode = 2; 
        }
      });  
     
    }
    else
    {
      alert("Veuillez entrer un nom pour l'activité");
    }
   
  } 

  goToDupplicate(id: any){
    if ((this.firstFormGroupOne.get('projetNameOne').value.trim().length > 0 ) && id !== "0"){
      this.authService.existProject(this.firstFormGroupOne.get('projetNameOne').value.trim()).subscribe(res => {
        //console.log('retour liste project!!! ');
        var resultat = ''+res;
        if(resultat === 'true'){
          alert("Ce magasin existe déjà");
        } else {
          this.createProjectMode = 3; 
          this.dupplicatestyle = id;
          if(id === 1){
            this.stepstyle = 1;
          }
        }
      }); 
    } else if (this.firstFormGroupOne.get('projetNameOne').value.trim().length === 0)
    {
      this.projectFilter = "0";
      alert("Veuillez entrer un nom pour l'activité" +this.projectFilter);
    } 
    /*
    //console.log("Project ID go : "+this.projectDetails);
    if (this.firstFormGroup.get('projetName').value.trim().length > 0 ){
       this.createProjectMode = 3; 
       this.dupplicatestyle = id;
    }
    else
    {
      alert("Veuillez entrer un nom pour l'activité");
    }*/
   
  } 

  nextStep(){
    this.stepstyle = 2;
  } 

  prevStep(){
    this.stepstyle = 1;
  } 

  goToGroup(projectId){
    //this.projectId = projectId;
    this.createProjectMode = 4 ;
    this.loadMembers(projectId);
  }

  loadMembers(id: string){
    this.authService.getDataDuplicated(id).subscribe(res => {
      //console.log('retour liste project!!! ');
      this.members = [];
      this.addingMember = [];
      this.members = this.members.concat(res['userProject']);
      this.tags = this.tags.concat(res['tag']);
      let userStatus = []; 
      let subjectGroup = []; 
        for (let index = 0; index < this.members.length; index++) {
          if(this.members[index]['user']){
            var user = this.members[index]['user'];
            var id = user.substr(user.lastIndexOf('/') + 1);
            userStatus.push(id);
            if(this.contacts.find(i => i.id === id)){
              this.addingMember.push(this.contacts.find(i => i.id === id));
            }
          }
        }
        this.addingMember = this.addingMember.sort(function (a, b) {
          if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) { return -1; }
          if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) { return 1; }
          return 0;
        })
        this.userStatus = userStatus;
        for (let index = 0; index < this.tags.length; index++) {
          if(this.tags[index]['libelle']){
            var tag = this.tags[index]['libelle'];
            //var id = tag.substr(tag.lastIndexOf('/') + 1);
            subjectGroup.push(tag);
          }
        }
        this.subjectGroup = subjectGroup;
      //this.total_project = res['hydra:totalItems'];
    }); 
  }

  goToAddMember(){
    //console.log("Project ID go : "+this.projectDetails);
    this.addSujet();
    this.createProjectMode = 3; 
  }

  goToGoal(){
    //console.log("Project ID go : "+this.projectDetails);
     if (this.hasMemberAdding == false) {
        this.showComfirmMessage = true;
     }
     else
     {
        this.createProjectMode = 4;
        this.showComfirmMessage = false; 
     }

  }

  goToDeadline(){
    this.createProjectMode = 5;
    //console.log(" goToDeadline ==> : "+this.createProjectMode);
  }

  addSujet(){
    if (this.newProjectSubject.trim().length > 0) {
      var newTheme = this.newProjectSubject; 
      this.arraySubject.push({"libelle":newTheme});
      this.firstFormGroup.patchValue({
        sujectList: this.arraySubject   
      });
      this.newProjectSubject = '';
    }
    //console.log('firstFormGroup value => ' , this.firstFormGroup.value);
  }

  deleteSuject(sujectItem){
    
    const index = this.arraySubject.indexOf(sujectItem, 0);
    if (index > -1) {
        this.arraySubject.splice(index, 1);
    }

    this.firstFormGroup.patchValue({
      sujectList: this.arraySubject   
    });
    
    //console.log('deleteSuject firstFormGroup value => ' , this.firstFormGroup.value);
  
  }

  goToProject(){
    //console.log("Project ID go : "+this.projectDetails);
     if (this.hasMemberAdding == false) {
        this.showComfirmMessage = true;
     }
     else
     {
        this.dialogRef.close(this.projectDetails);
     }
    
  }

  fctAddMember(){
      
    //this.showTool = false; 
    //this.messageService.setShowCreateProjectTuto(false);
    //this.messageService.setShowCreateProjectTutoIsFalse();
    this.memberdialog = 1;
    /*
    const dialogRef = this.dialog.open(dashboardmemberdialog, {
      width: '700px',
      data: {idcompany: 1, companyUser: this.contacts}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.memberdialog = 0;
      this.mailadded = result;
      this.fctAddByMailValue(this.mailadded);  
    });*/
    if (this.mailadded != ""){
      this.fctAddByMailValue(this.mailadded);
      this.mailadded = "";
    }
  }

  fctCheckMember(userInfo){ 
    if(this.addingMember && !this.addingMember.includes(userInfo)){
      this.fctAdMember(userInfo);
    } else this.fctRemoveUser(userInfo);
  }

  fctAdMember(userInfo){ 
    this.addingMember.push(userInfo);
    this.userStatus[userInfo['id']] = 'PENDING';
    this.hasMemberAdding = true;
    this.searchContact = "";
  }

  fctAdTag(userInfo){ 
    this.subjectGroup.push(userInfo);
  }

  fctAdTagRow(){ 
    if (this.tagFormControl.value == '') {
      return;
    }
    var result = this.tagFormControl.value;
    this.subjectGroup.push(result);
    this.tags.push({"libelle": result});
    this.tagFormControl.reset();
  }

  fctRemoveTag(userInfo){
    const index = this.subjectGroup.indexOf(userInfo);
    if (index > -1) {
      this.subjectGroup.splice(index, 1);
    }
  }

  fctRemoveUser(userInfo){
    const index = this.addingMember.indexOf(userInfo);
    if (index > -1) {
      this.addingMember.splice(index, 1);
      delete this.userStatus[userInfo['id']];
      if (this.addingMember.length > 0) {
       this.hasMemberAdding = true;
      }
      else
      {
        this.hasMemberAdding = false;
      }
      this.searchContact = "";
    }
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  openDialogInfo(){
    //console.log(' dans la fonction openDialogInfo');
    const dialogRef = this.dialog.open(tutoCreateProjectdialog, {
      width: '850px',
      data:{}
    });
    dialogRef.afterClosed().subscribe(result => {
    })
  }

}


@Component({
  selector: 'tutoCreateProjectdialog',
  templateUrl: 'tutoCreateProjectdialog.html',
   styleUrls: ['tutoCreateProjectdialog.scss']
})
export class tutoCreateProjectdialog implements OnInit{
  lang='fr';

  constructor(
    public dialogRef: MatDialogRef<tutoCreateProjectdialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder) {
     
  }

  ngOnInit() {
    this.messageService.getTranslate().subscribe((data) => {
      this.lang= data;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}



@Component({
  selector: 'dashboardmemberdialog.component',
  templateUrl: 'dashboardmemberdialog.component.html',
   styleUrls: ['dashboardmemberdialog.component.scss']
})
export class dashboardmemberdialog implements OnInit{
  

  //@ViewChild('stepper') stepper: MatStepper;
  @ViewChild('addProjectForm', {static: true}) addProjectForm;
  firstFormGroup: FormGroup;
  addingMember = [];
  hasMemberAdding = false;
  projectUsers = [];
  userStatus = [];
  subjectGroup = [];
  projectId = null;
  projects: Array<any> = [];
  members: Array<any> = [];
  tags: Array<any> = [];
  projectDetails = null;
  contacts = [];
  searchContact: any;
  arrayEmailInvitation = [];
  arrayEmailInvitationWithId = [];
  arraySubject = [];
  groupselected = 0;
  public projectCtrl: FormControl = new FormControl();
  saveProject = null;
  firstLoad = false;
  newProjectSubject = '';
  currenteDate: any;
  showComfirmMessage = false;
  protected _onDestroy = new Subject<void>();
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  tagFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  createProjectMode = 1;
  dupplicatestyle = 0;
  stepstyle = 0;
  projectFilter = "0";
  typeproject = '0';
  duppselected = 1;

  constructor(
    public dialogRef: MatDialogRef<dashboarddialogCompo>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder) {

      /*this.authService.getContacts().subscribe( dataContact => {
        this.contacts = dataContact['hydra:member'];
      });*/
      this.contacts = [];
      this.projects = data.projects;

      /*
      let userStatus = []; 
        for (let index = 0; index < this.projectUsers.length; index++) {
          if(this.projectUsers[index]['user'])
          userStatus[this.projectUsers[index]['user']['id']] = this.projectUsers[index]['status'];
        }
      this.userStatus = userStatus;*/
      
    }

    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }

    ngOnInit() {
      this.arrayEmailInvitation = [];
      this.arrayEmailInvitationWithId = [];
      this.addingMember = [];
      this.createProjectMode = 1;
      this.currenteDate = new Date();
      this.firstFormGroup = this._formBuilder.group({
        projetName: ['', Validators.required],
        clickCreate: ['', [Validators.required, Validators.requiredTrue]],
        objectif: [''],
        deadLine: [''],
        sujectList: [[]]
      });
    }


  fctAddcontactByMail() {

    // stop here if form is invalid
    if (this.emailFormControl.status == 'INVALID') {
      return;
    }

    var result = this.emailFormControl.value;

 
     this.authService.getUserInfo(result).subscribe(
        res => {
          
          var arrayUser = res["hydra:member"];
          
          if (arrayUser.length > 0) {
            var newUser = arrayUser[0];
            this.addingMember.push(newUser);
            this.userStatus[newUser['id']] = 'PENDING';
            this.hasMemberAdding = true;
            this.emailFormControl.reset();
            this.arrayEmailInvitationWithId.push(result);  
          }
          else
          {
            this.arrayEmailInvitation.push(result);   
            this.hasMemberAdding = true;
            this.emailFormControl.reset();
          }
          
          this.searchContact = "";
         
        },
        (error) => {
          alert('Erreur ! : ' + error)
        }
      );

  }

  validate(){
    if (this.firstFormGroup.get('projetName').value.trim().length > 0 ){
      this.projectDetails = this.firstFormGroup.get('projetName').value;
      this.dialogRef.close(this.projectDetails);
    }
    
    
    
    
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


  nextStep(){
    this.stepstyle = 2;
  } 

  prevStep(){
    this.stepstyle = 1;
  } 

  goToProject(){
    //console.log("Project ID go : "+this.projectDetails);
     if (this.hasMemberAdding == false) {
        this.showComfirmMessage = true;
     }
     else
     {
        this.dialogRef.close(this.projectDetails);
     }
    
  }
/*
  fctAdMember(userInfo){ 
    this.addingMember.push(userInfo);
    this.userStatus[userInfo['id']] = 'PENDING';
    this.hasMemberAdding = true;
    this.searchContact = "";
  }*/


  fctAddMember(){
      
    //this.showTool = false; 
    //this.messageService.setShowCreateProjectTuto(false);
    //this.messageService.setShowCreateProjectTutoIsFalse();
    const dialogRef = this.dialog.open(addMemberCompany, {
      width: '700px',
      data: {idcompany: 1, companyUser: this.contacts}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result add membre '+result);    
    });
}


}

