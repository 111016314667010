import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/services/message/message.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio'; 
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public lang:string = 'fr';
  sendMessageForm: FormGroup;
  homeContent = '';
  particulier = false;
  professionnel = false;
  submitted = false;
  homepage:Boolean = false;
  docsUrl = "";
  seletedMedia: any;
  currentIndex = 0;
  fileList: any;
  currentMedia: any;

  constructor(public dialog: MatDialog, private authService: AuthService, private messageService: MessageService, private router: Router,  private formBuilder: FormBuilder,) {

    this.sendMessageForm = this.formBuilder.group({
        type: [''],
        objet: ['', Validators.required],
        adresse: ['', Validators.required],
        message: ['', Validators.required]
    });

    if (this.lang == 'fr') {

      this.fileList = [
        {
          file : '../../../assets/home/HomeFWp01c.jpg'
        },
        {
          file : '../../../assets/home/HomeFWp02.jpg'
        },
        {
          file : '../../../assets/home/HomeFWp03.jpg'
        },
        {
          file : '../../../assets/home/HomeFWp04.jpg'
        },
        {
          file : '../../../assets/home/HomeFWp05.jpg'
        },
        {
          file : '../../../assets/home/HomeFWp06.jpg'
        },
        {
          file : '../../../assets/home/HomeFWp07d.jpg'
        },
        {
          file : '../../../assets/home/HomeFWp08.jpg'
        },
       /* {
          file : '../../../assets/home/HomeFW-E09.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E10.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E11.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E12.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E13.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E14.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E15.jpg'
        }*/
      ];

      this.currentMedia = {
        file : '../../../assets/home/HomeFW-E15.jpg'
      };

    }
    else if (this.lang == 'en') 
    {
        this.fileList = [
        {
          file : '../../../assets/home/HomeFW-E01-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E02-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E03-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E04-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E05-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E06-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E07-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E08-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E09-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E10-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E11-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E12-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E13-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E14-Eng.jpg'
        },
        {
          file : '../../../assets/home/HomeFW-E15-Eng.jpg'
        }
      ];

      this.currentMedia = {
        file : '../../../assets/home/HomeFW-E15-Eng.jpg'
      };

    }

    for (var i = 0; i < this.fileList.length; i++) {
      if(this.fileList[i] == this.currentMedia){
        this.currentIndex = i;
        // console.log("this.currentIndex", this.currentIndex)
      }
    }

    this.seletedMedia = this.fileList[this.currentIndex];


  }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/dashboard']);
    }
    
    this.messageService.getTranslate().subscribe((data) => {
       this.lang= data;

       if (this.lang == 'fr') {

        this.fileList = [
          {
            file : '../../../assets/home/HomeFW-E01.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E02.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E03.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E04.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E05.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E06.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E07.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E08.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E09.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E10.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E11.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E12.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E13.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E14.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E15.jpg'
          }
        ];

      }
      else if (this.lang == 'en') 
      {
          this.fileList = [
          {
            file : '../../../assets/home/HomeFW-E01-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E02-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E03-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E04-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E05-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E06-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E07-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E08-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E09-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E10-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E11-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E12-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E13-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E14-Eng.jpg'
          },
          {
            file : '../../../assets/home/HomeFW-E15-Eng.jpg'
          }
        ];

      }

      this.seletedMedia = this.fileList[this.currentIndex];

    });
  }

  get f() { return this.sendMessageForm.controls; }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogVideo, {
      width: '700px',
      height: '450px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  showPage(position){
    this.homepage = true;
    setTimeout(() => {
      var element = document.getElementById(position);
      element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});  
    }, 1000);
  }

  openDialog2(): void {
    const dialogRef = this.dialog.open(DialogVideo2, {
      width: '700px',
      height: '450px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onClickPrevious(){
    if ((this.currentIndex-1) >= 0) {
      this.currentIndex -= 1; 
      this.seletedMedia = this.fileList[this.currentIndex];
    }
  }

  onClickNext(){
    if ((this.currentIndex+1) < this.fileList.length) {
      this.currentIndex += 1; 
      this.seletedMedia = this.fileList[this.currentIndex];
    }
  }

  onClicReload(){
    this.currentIndex = 0; 
    this.seletedMedia = this.fileList[this.currentIndex];
  }


  onSubmitMessage(){

    this.submitted = true;
     
    if (this.particulier == true){
      this.sendMessageForm.patchValue({
        type: 'particulier'
      });
    } 
    else if (this.professionnel == true ) {
       this.sendMessageForm.patchValue({
        type: 'professionnel'
      });
    }

    console.log("valeur formulaire => "+ JSON.stringify(this.sendMessageForm.value));

     // stop here if form is invalid
    if (this.sendMessageForm.invalid) {
      return;
    }
    
    this.authService.contactMessage(
        this.sendMessageForm.value.type,
        this.sendMessageForm.value.objet,
        this.sendMessageForm.value.email,
        this.sendMessageForm.value.message,
      )
      .subscribe(res => {
         //console.log('reponse envoi message: ',res);
      alert("Message envoyé");
    },
    error => {
       alert("oups une erreur s'est produite!");
    });

    this.sendMessageForm.reset();
    this.submitted = false;  
 
  }

  gotoSubscribe(){
    this.router.navigate(['/register']);
  }

  gotoDiscover(){
    this.router.navigate(['/discoverview']);
  }

}



@Component({
  selector: 'dialog-video',
  templateUrl: 'dialog-video.html',
})
export class DialogVideo {

  constructor(
    public dialogRef: MatDialogRef<DialogVideo>,
    @Inject(MAT_DIALOG_DATA) public data: null) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  contactUs() : void {
    this.dialogRef.close();
    setTimeout(() => {
      window.scrollTo(0,document.body.scrollHeight);
    }, 1000);
    
  }

}


@Component({
  selector: 'dialog-video2',
  templateUrl: 'dialog-video2.html',
})
export class DialogVideo2 {

  constructor(
    public dialogRef: MatDialogRef<DialogVideo>,
    @Inject(MAT_DIALOG_DATA) public data: null) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  contactUs() : void {
    this.dialogRef.close();
    setTimeout(() => {
      window.scrollTo(0,document.body.scrollHeight);
    }, 1000);
    
  }

}