import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';
import { MessageService } from 'src/app/services/message/message.service';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-entreprise-manager',
  templateUrl: './entreprise-manager.component.html',
  styleUrls: ['./entreprise-manager.component.scss']
})
export class EntrepriseManagerComponent implements OnInit {

  public paysCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  savePays = null;
  paysId = null;
  activityId = 'Réseaux de Clients';
  public areas = [
    { name: 'Réseaux de Magasins' },
    { name: 'Réseaux de Clients' },
    { name: 'Réseaux de Propriétaires' },
    { name: 'Réseaux de Crêches' },
    { name: 'Réseaux de Patients' },
    { name: 'Réseaux de points de Services' },
    { name: 'Réseaux d\'agences bancaires' },
    { name: 'Gestion de commande' },
    { name: 'Groupes' }
  ]
  public pays = [
    { name: 'Afghanistan' },
    { name: 'Albania' },
    { name: 'Algeria' },
    { name: 'Angola' },
    { name: 'Argentina' },
    { name: 'Armenia' },
    { name: 'Australia' },
    { name: 'Austria' },
    { name: 'Azerbaijan' },
    { name: 'Bahamas' },
    { name: 'Bahrain' },
    { name: 'Bangladesh' },
    { name: 'Belarus' },
    { name: 'Belgium' },
    { name: 'Belize' },
    { name: 'Benin' },
    { name: 'Bhutan' },
    { name: 'Bolivia' },
    { name: 'Bosnia' },
    { name: 'Botswana' },
    { name: 'Brazil' },
    { name: 'Brunei' },
    { name: 'Bulgaria' },
    { name: 'Burkina' },
    { name: 'Burma/Myanmar' },
    { name: 'Burundi' },
    { name: 'Cambodia' },
    { name: 'Cameroon' },
    { name: 'Canada' },
    { name: 'Cape Verde' },
    { name: 'Chad' },
    { name: 'Chile' },
    { name: 'China' },
    { name: 'Colombia' },
    { name: 'Comoros' },
    { name: 'Congo' },
    { name: 'Cook Islands' },
    { name: 'Costa Rica' },
    { name: 'Croatia' },
    { name: 'Cuba' },
    { name: 'Cyprus' },
    { name: 'Czech Republic' },
    { name: 'Denmark' },
    { name: 'Dominican Republic' },
    { name: 'Ecuador' },
    { name: 'Egypt' },
    { name: 'El Salvador' },
    { name: 'Equatorial Guinea' },
    { name: 'Eritrea' },
    { name: 'Estonia' },
    { name: 'Ethiopia' },
    { name: 'Fiji' },
    { name: 'Finland' },
    { name: 'France' },
    { name: 'Gabon' },
    { name: 'Gambia' },
    { name: 'Georgia' },
    { name: 'Germany' },
    { name: 'Ghana' },
    { name: 'Greece' },
    { name: 'Grenada' },
    { name: 'Guatemala' },
    { name: 'Guinea' },
    { name: 'Guyana' },
    { name: 'Haiti' },
    { name: 'Honduras' },
    { name: 'Hungary' },
    { name: 'Iceland' },
    { name: 'India' },
    { name: 'Indonesia' },
    { name: 'Iran' },
    { name: 'Iraq' },
    { name: 'Ireland' },
    { name: 'Israel' },
    { name: 'Italy' },
    { name: 'Ivory Coast' },
    { name: 'Jamaica' },
    { name: 'Japan' },
    { name: 'Jordan' },
    { name: 'Kazakhstan' },
    { name: 'Kenya' },
    { name: 'Kuwait' },
    { name: 'Kyrgyzstan' },
    { name: 'Laos' },
    { name: 'Latvia' },
    { name: 'Lebanon' },
    { name: 'Lesotho' },
    { name: 'Liberia' },
    { name: 'Libya' },
    { name: 'Lithuania' },
    { name: 'Luxembourg' },
    { name: 'Macedonia' },
    { name: 'Madagascar' },
    { name: 'Malawi' },
    { name: 'Malaysia' },
    { name: 'Maldives' },
    { name: 'Mali' },
    { name: 'Malta' },
    { name: 'Marshall Islands' },
    { name: 'Mauritania' },
    { name: 'Mauritius' },
    { name: 'Mexico' },
    { name: 'Micronesia' },
    { name: 'Moldova' },
    { name: 'Monaco' },
    { name: 'Mongolia' },
    { name: 'Morocco' },
    { name: 'Mozambique' },
    { name: 'Namibia' },
    { name: 'Nepal' },
    { name: 'Netherlands' },
    { name: 'New Zealand' },
    { name: 'Nicaragua' },
    { name: 'Niger' },
    { name: 'Nigeria' },
    { name: 'North Korea' },
    { name: 'Norway' },
    { name: 'Oman' },
    { name: 'Pakistan' },
    { name: 'Palau' },
    { name: 'Panama' },
    { name: 'Papua New Guinea' },
    { name: 'Paraguay' },
    { name: 'Peru' },
    { name: 'Philippines' },
    { name: 'Poland' },
    { name: 'Portugal' },
    { name: 'Qatar' },
    { name: 'Romania' },
    { name: 'Russia' },
    { name: 'Rwanda' },
    { name: 'Maldives' },
    { name: 'Saudi Arabia' },
    { name: 'Senegal' },
    { name: 'Seychelles' },
    { name: 'Sierra Leone' },
    { name: 'Singapore' },
    { name: 'Slovakia' },
    { name: 'Slovenia' },
    { name: 'Solomon Islands' },
    { name: 'Somalia' },
    { name: 'South Africa' },
    { name: 'South Korea' },
    { name: 'Spain' },
    { name: 'Sri Lanka' },
    { name: 'Sudan' },
    { name: 'Suriname' },
    { name: 'Swaziland' },
    { name: 'Sweden' },
    { name: 'Switzerland' },
    { name: 'Syria' },
    { name: 'Tajikistan' },
    { name: 'Tanzania' },
    { name: 'Thailand' },
    { name: 'Togo' },
    { name: 'Tonga' },
    { name: 'Tunisia' },
    { name: 'Turkey' },
    { name: 'Uganda' },
    { name: 'Ukraine' },
    { name: 'United Arab Emirates' },
    { name: 'United Kingdom' },
    { name: 'United States' },
    { name: 'Uruguay' },
    { name: 'Uzbekistan' },
    { name: 'Vanuatu' },
    { name: 'Venezuela' },
    { name: 'Vietnam' },
    { name: 'Samoa' },
    { name: 'Yemen' },
    { name: 'Yougoslavia' },
    { name: 'Zambia' },
    { name: 'Zimbabwe' }
  ];

 
  RaisonSociale = '';
  AdresseSiegeSocial = '';
  CodePostalSiege = '';
  VilleSiegeSocial = '';
  Pays = '';
  siret = '';
  fname = '';
  lname = '';
  jobT = '';
  email = '';
  passwd = '';
  passwd_confirm = '';
  error = '';
  message = "Inscription terminé ! un lien pour activer votre compte a été envoyé sur votre adresse e-mail, cliquez sur le lien pour activer le compte.";
  messageEn = "Registration finished ! a link to activate your account has been sent to your email address, click on the link to activate the account.";
  lang  = 'fr';
  pconerror = '';
  values = '';
  hide = true;
  mdpValues = '';
  campanyInfo: any;
  view_step = 0;

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService) { 
      this.paysCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPays();
      });
    }

    protected filterPays() {
      if(this.savePays == null){
        this.savePays = this.pays;
      }
  
      let search = this.paysCtrl.value;
      if (!search) {
        this.pays = this.savePays;
        return;
      } else {
        search = search.toLowerCase();
        this.pays = this.savePays.filter(p => p.name.toLowerCase().indexOf(search) > -1);
      }
    }

  ngOnInit() {
    this.view_step = 0;
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });
  }

  onKey(event: any) { // without type info
    this.mdpValues += event.target.value + ' | ';
  } 

  registerNu() {
    //console.log('Registering in ' + this.fname + ' ' + this.lname + ' ' + this.jobT + ' ' + this.email + ' ' + this.passwd);
      this.authService.registerCompagny(
      this.RaisonSociale,
      '',
      this.activityId,
      this.siret,
      this.AdresseSiegeSocial,
      this.CodePostalSiege,
      this.VilleSiegeSocial,
      this.paysId,
      '',
      ''
     ).subscribe(
        data => {   
          this.campanyInfo = data;
          this.registerUser();
        },
        err => {
          const error = err.error;
          const errorS = error.message;
          const errorD = error.detail;
          const errorH = error['hydra:description'];
          if (errorS === 'Bad credentials') {
            this.error = errorS;
          } else if (errorD === "L'utilisateur n'est pas encore activé. pour l'activer, vous devez cliquer sur le lien de confirmation du mail que vous avez reçu") {
            this.error = errorD;
          } else if (errorH) {
            this.error = errorH;
          }
        }
    );

  }

  registerUser(){
    this.authService.register(this.fname, this.lname, this.jobT, this.email, this.passwd, '/api/companies/'+this.campanyInfo.id)
      .pipe(first())
      .subscribe(
        data => {
          // this.router.navigate(['/']);
          this.view_step = 2; 
        },
        err => {
          const error = err.error;
          const errorS = error.message;
          const errorD = error.detail;
          const errorH = error['hydra:description'];
          if (errorS === 'Bad credentials') {
            this.error = (this.lang == 'fr' ? 'email / mot de passe incorrect' : 'incorrect email / password');
          } else if (errorD === "L'utilisateur n'est pas encore activé. pour l'activer, vous devez cliquer sur le lien de confirmation du mail que vous avez reçu") {
            this.error = errorD;
          } else if (errorH) {
            this.error = errorH;
          }
          //console.log(error);
        }
    );
  }

  nextStep(){
    if(this.activityId !== "Réseaux de Magasins" && this.activityId !== "Réseaux de Clients" && this.activityId !== "Groupes"){
      alert("Ce secteur d'activité est en cours de développement")
    } else if (this.view_step < 1) {
      this.view_step += 1; 
    }
  }

  changeActivity(){
    console.log("changed");
    if(this.activityId !== "Réseaux de Magasins" && this.activityId !== "Réseaux de Clients" && this.activityId !== "Groupes"){
      alert("Bientôt disponible")
    }
  }

  previousStep(){
    if (this.view_step > 0) {
      this.view_step -= 1;
    }
  }

}
