import { Component, OnInit, Inject, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatButton } from '@angular/material/button';
import { merge, Subject, Observable, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Location } from "@angular/common";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";
import { JournalWorkflowComponent } from '../journal-workflow/journal-workflow.component';
import { JournalKpiComponent } from '../journal-kpi/journal-kpi.component';
import { JournalServiceComponent } from '../journal-service/journal-service.component';

export interface ContactDialogData {
  name: string;
  profile: string;
  commonRef: string;
}

export interface DialogDataNotif {
  copyNotifCount: number;
  receiveNotifCount: number;
  sentNotifCount: number;
  commentNotifCount: number;
  idprojet: string;
  name: string;
}

export interface Contacts {
  '@id': string;
  '@type': string;
  familyName: string;
  fullName: string;
  gender: string;
  givenName: string;
  id: string;
  image: string;
  imageUrl: string;
  jobTitle: string;
  position: number;
}

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss']
})
export class DashboardMenuComponent implements OnInit, OnDestroy {
  name: string;
  profile: string;
  commonRef: string;
  notifAcion = null;
  // currentUser: string;
  currentUserName: string;
  subscription: Subscription;
  isOpenMenu = false;

  //notificationload = [];
  notification = [];
  contactsTemp: Contacts[];
  contacts: Contacts[];
  inviteBadge = 0;
  notifBadge = 0;
  sentNotifCount = 0;
  receiveNotifCount = 0;
  copyNotifCount = 0;
  commentNotifCount = 0;

  mettingsentNotifCount = 0;
  mettingreceiveNotifCount = 0;
  mettingcopyNotifCount = 0;
  mettingcommentNotifCount = 0;

  userEmail = '';
  userManager = false;
  dashbordbtnBackground = 'transparent';
  dashbordbtnColor = '#a386bd';
  boardBackground = 'transparent';
  boardColor = '#a386bd';
  nbToolNotif = 0;
  showTool = false;
  route: string;
  lang = 'fr';
  countActions : any
  actionLength:any
  isdashboard = false
  isBoard = false
  sentNotifCountAction : any
  receiveNotifCountAction : any
  copyNotifCountAction : any
  answerNotifCountAction :any
  commentNotifCountAction :any
  actionBadge : any;
  currentUser = JSON.parse(localStorage.getItem('currentUser'))
  notifBoard = null;
  selectedImg = null;
  docsUrl = environment.server_url;
  secteur = "";


  constructor(
    private translate: TranslateService,
    public authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    location: Location,
    private messageService: MessageService,
    protected _sanitizer: DomSanitizer,
    private httpClient: HttpClient) {

    this.sentNotifCountAction = 0;
    this.receiveNotifCountAction  = 0;
    this.copyNotifCountAction = 0;
    this.answerNotifCountAction  = 0;
    this.commentNotifCountAction = 0;

    this.router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
      } else {
        this.route = "Home";
      }
      if (this.route == '/dashboard') {
        this.isdashboard = true
        this.dashbordbtnBackground = '#05ab69';
        this.dashbordbtnColor = "#ffffff"
      }
      else {
        this.isdashboard = false
        this.dashbordbtnBackground = 'transparent';
        this.dashbordbtnColor = "#05ab69"
      }
      if(this.route == '/journal/actions'){
        this.isBoard = true
        this.boardBackground =  '#a386bd'
        this.boardColor = "#ffffff"
      }else{
        this.isBoard = false
        this.boardBackground = 'transparent'
        this.boardColor =  "#a386bd"
      }
    });


    this.messageService.getShowProjectTutoIsFalse().subscribe(() => {
      this.showTool = this.messageService.getShowNotifTuto();
    });


    this.getNotifBoard();
    this.messageService.obsReloadBoardNotif().subscribe(() => {
      this.getNotifBoard();
    });

  }

  getNotifBoard(){
    this.authService.getNotifBoard().subscribe(res => {
      if(res['total'] > 0){
        this.notifBoard = res['total'];
      }else{
        this.notifBoard = null;
      }
    }, (error) => {

    });
  }


  getAllActionsNotification(){


    //console.log('refresh des notification des actions');

    this.authService.getActionsNotifications(0, 1, 10).subscribe((res) => {
      //console.log({sentNotifCount:res})
      this.sentNotifCountAction = res['hydra:totalItems'];
      this.countActions = true
    });


    this.authService.getActionsNotifications(1, 1, 10).subscribe((res) => {
      //console.log({receiveNotifCount:res})
      this.receiveNotifCountAction = res['hydra:totalItems'];
      /*this.authService.getActionsNotifications(4, 1, 10).subscribe((res) => {
        //console.log({receiveNotifCount:res})
        this.receiveNotifCountAction += res['hydra:totalItems'];
      });*/
    });

    /*this.authService.getActionsNotifications(2, 1, 10).subscribe((res) => {
      //console.log({receiveNotifCount:res})
      this.answerNotifCountAction = res['hydra:totalItems'];
    })  */

    this.authService.getActionsNotifications(2, 1, 10).subscribe((res) => {
      //console.log({receiveNotifCount:res})
      this.copyNotifCountAction = res['hydra:totalItems'];
    });


    this.authService.getActionsNotifications(3, 1, 10).subscribe((res) => {
      console.log({commentNotifCountAction:res})
      this.commentNotifCountAction = res['hydra:totalItems'];
    });


    /*this.authService.getActionsBisNotifications().subscribe((res) => {
      this.actionBadge = res['Notif'];
    }); */

    this.authService.getAllActionsNotRead(this.currentUser.id, 1, 10, null).subscribe((res) => {
      this.actionBadge = res['hydra:totalItems'];
    });

  }


  getImg(mediaType){
      this.selectedImg = mediaType;
  }

  closeModal(){
    this.selectedImg = null;
  }


  guide() {
    this.messageService.setGuide(1);
  }

  redirectJournal(){
    this.dialog.closeAll();
    this.router.navigateByUrl('journal/actions')
  }

  gotoActionNotifBis(){
    this.dialog.closeAll();
    this.router.navigateByUrl('notifsActionsBis');
  }

  gotoJournalAction(){
    //alert("En cours de développement");
    this.dialog.closeAll();
    this.router.navigateByUrl('journalActionBis');
  }

  gotoJournalWorkflow(){
    this.dialog.closeAll();
    //alert("En cours de développement");
    this.router.navigateByUrl('JournalWorkflow');
  }

  onDevAction(){
    this.dialog.closeAll();
    alert("En cours de développement");
    //this.router.navigateByUrl('journalActionBis');
  }

  openDialog() {
    this.dialog.closeAll();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'contactpanel';
    dialogConfig.id = 'balchal717';
    dialogConfig.width = '550px';
    dialogConfig.autoFocus = false;
    dialogConfig.data = { name: this.name, profile: this.profile, commonRef: this.commonRef };
    const dialogRef = this.dialog.open(DashboardDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.profile = result;
        this.router.navigate(['/profile/' + result]);
      }
    });

  }


  openInfo() {
    const dialogRef = this.dialog.open(tutoStepdialog, {
      width: '850px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
    })
  }

  ngOnInit() {


    /*this.messageService.obsReloadPropostionNotif().subscribe(() => {
      this.getAllActionsNotification();
    });*/

    this.secteur = localStorage.getItem('secteur');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userEmail = this.currentUser['email'];
    var roles = this.currentUser['roles']; 
    if(this.currentUser['roles'].includes("ROLE_MANAGER")){
      this.userManager = true;
    }

    this.currentUserName = this.currentUser['givenName'];
    this.authService.obNotifBadge.subscribe((res) => {
      this.notification = <any>res;
      this.notifBadge = 0;
      for (var i = 0; i < this.notification.length; i++) {
        //console.log('reoutr get notif => '+ JSON.stringify(this.notification));
        this.notifBadge += this.notification[i].total;
      }
    });
    this.authService.obInviteBadge.subscribe((res) => {
      this.inviteBadge = res['hydra:totalItems'];
    });

    this.getNotification();
    this.updateNotifications();
    this.mettingUpdateNotifications();
    this.getAllActionsNotification();

    this.subscription = this.messageService.getMessage().subscribe(() => {
      this.updateNotifications();
      this.mettingUpdateNotifications();
      this.getAllActionsNotification();
    });

    this.getActionsNotifications();

    this.messageService.obsReloadNotifAction().subscribe(() => {
      /*this.authService.getActionsBisNotifications().subscribe((res) => {
        //console.log({receiveNotifCount:res})
        this.actionBadge = res['Notif'];
      })  */
     this.authService.getAllActionsNotRead(this.currentUser.id, 1, 10, null).subscribe((res) => {
        this.actionBadge = res['hydra:totalItems'];
      });

    });

    this.refreshActionNotif();
    this.messageService.obsReloadActionNotif().subscribe(() => {
      this.refreshActionNotif();
    });

  }

  refreshActionNotif(){
    /*this.authService.getNotificationJAction().subscribe(res => {
      if(res['total'] > 0){
        this.notifAcion = res['total'];
      }else{
        this.notifAcion = null;
      }
    });*/

    this.authService.getAllActions(1,1,null,null,'/api/users/'+this.currentUser.id,null,null,null,'UNDONE',null,null,
      'dateEcheance')
      .then((data) => {
        if(data["hydra:totalItems"] > 0){
          this.notifAcion = data["hydra:totalItems"];
        }else{
          this.notifAcion = null;
        }
      });

  }

  getActionsNotifications(){
    this.authService.actionsNotifications().then((res)=>{
      // return res['total']
      this.actionLength=res
      //console.log("hjk",this.actionLength)
      for(let i=0;i<this.actionLength.length;i++){
        //console.log("fazejfzjefze",this.actionLength[i])
      }
    }).catch((err)=>{
      //console.log(err)
    })
  }

  public openNavbarmobile() {
    this.isOpenMenu = !this.isOpenMenu;
    //console.log(this.isOpenMenu)
  }
  changeLang() {
    this.translate.use(this.lang);
    this.messageService.setTranslate(this.lang);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  updateNotifications() {
    this.authService.getProjectNotification(0, 1, 10).subscribe((res) => {
      this.sentNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(1, 1, 10).subscribe((res) => {
      this.receiveNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(2, 1, 10).subscribe((res) => {
      this.copyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(3, 1, 10).subscribe((res) => {
      this.commentNotifCount = res['hydra:totalItems'];
    });

  }


  mettingUpdateNotifications() {
    /*this.authService.getMettingNotification(0, 1, 10).subscribe((res) => {
      this.mettingsentNotifCount = res['hydra:totalItems'];
    });*/

    /*this.authService.getMettingNotification(1, 1, 10).subscribe((res) => {
      this.mettingreceiveNotifCount = res['hydra:totalItems'];
    });*/

    this.authService.getMettingNotification(2, 1, 10).subscribe((res) => {
      this.mettingcopyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getMettingNotification(3, 1, 10).subscribe((res) => {
      this.mettingcommentNotifCount = res['hydra:totalItems'];
    });

  }




  getNotification() {
    this.authService.getNotification().subscribe(res => {
      this.authService.obNotifBadge.next(res);
    });
    this.authService.getUserInvitation(0, 10).subscribe(res => {
      this.authService.obInviteBadge.next(res);
    });
  }

  openDialogNotif(): void {
    this.dialog.closeAll();
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    const dialogRef = this.dialog.open(DashboardDialogNotif, {
      width: '1400px',
      data: { sentNotifCount: this.sentNotifCount, receiveNotifCount: this.receiveNotifCount, copyNotifCount: this.copyNotifCount, commentNotifCount: this.commentNotifCount }
    });

    dialogRef.afterClosed().subscribe(result => {
       //console.log({result:result});
      if (result)
      {
        if (result.type == 'COMMENT')
        {
          this.router.navigate(['/project/details/' + result.id + '/' + result.number + '/' + result.type]);
        }
        else
        {
          this.router.navigate(['project/details/' + result.id + '/' + result.consult + '/' + result.type]);
        }
      }
      //this.animal = result;
    });
  }

  openDialogActionNotif(): void {
    //alert("En cours de développement");
    this.dialog.closeAll();
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    const dialogRef = this.dialog.open(DashboardActionsDialogNotif, {
      width: '1600px',
      data: { sentNotifCount: this.sentNotifCountAction, receiveNotifCount: this.receiveNotifCountAction, copyNotifCount: this.copyNotifCountAction, commentNotifCount: this.commentNotifCountAction }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result)
      {
        if (result.type == 'COMMENT' && !result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type]);
        }
        else if (result.type == 'COMMENT' && result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type + '/' + result.isProposal]);
        }
        else
        {
          this.router.navigate(['faceTofacesProposal/details/' + result.id + '/' + result.consult + '/' + result.type]);
        }
      }

    });
    
  }

  openDialogWorkflow(): void {
    //alert("En cours de développement");
    
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    const dialogRef = this.dialog.open(JournalWorkflowComponent, {
      width: '55rem',
      data: { /*sentNotifCount: this.sentNotifCountAction, receiveNotifCount: this.receiveNotifCountAction, copyNotifCount: this.copyNotifCountAction, commentNotifCount: this.commentNotifCountAction*/ },
      panelClass: 'workflowmodal'
    });

    dialogRef.afterClosed().subscribe(result => {

      /*
      if (result)
      {
        if (result.type == 'COMMENT' && !result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type]);
        }
        else if (result.type == 'COMMENT' && result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type + '/' + result.isProposal]);
        }
        else
        {
          this.router.navigate(['faceTofacesProposal/details/' + result.id + '/' + result.consult + '/' + result.type]);
        }
      }
      */

    });
    
  }


  openDialogKpi(): void {
    //alert("En cours de développement");
    
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    const dialogRef = this.dialog.open(JournalKpiComponent, {
      width: '75rem',
      data: { /*sentNotifCount: this.sentNotifCountAction, receiveNotifCount: this.receiveNotifCountAction, copyNotifCount: this.copyNotifCountAction, commentNotifCount: this.commentNotifCountAction*/ },
      panelClass: 'workflowmodal'
    });

    dialogRef.afterClosed().subscribe(result => {

      /*
      if (result)
      {
        if (result.type == 'COMMENT' && !result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type]);
        }
        else if (result.type == 'COMMENT' && result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type + '/' + result.isProposal]);
        }
        else
        {
          this.router.navigate(['faceTofacesProposal/details/' + result.id + '/' + result.consult + '/' + result.type]);
        }
      }
      */

    });
    
  }

  openDialogService(): void {
    //alert("En cours de développement");
    
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    const dialogRef = this.dialog.open(JournalServiceComponent, {
      width: '75rem',
      data: { /*sentNotifCount: this.sentNotifCountAction, receiveNotifCount: this.receiveNotifCountAction, copyNotifCount: this.copyNotifCountAction, commentNotifCount: this.commentNotifCountAction*/ },
      panelClass: 'workflowmodal'
    });

    dialogRef.afterClosed().subscribe(result => {

      /*
      if (result)
      {
        if (result.type == 'COMMENT' && !result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type]);
        }
        else if (result.type == 'COMMENT' && result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type + '/' + result.isProposal]);
        }
        else
        {
          this.router.navigate(['faceTofacesProposal/details/' + result.id + '/' + result.consult + '/' + result.type]);
        }
      }
      */

    });
    
  }

  private newMethod() {
    return '0';
  }


  logout() {
    //console.log('logging out');
    this.authService.logout();
  }

  /*getNotification() {
    this.authService.getNotification()
      .subscribe(res => {
        this.notificationload = res;
      });

    this.notificationload.forEach(dataN => {
      this.authService.getTheProject(dataN.id)
        .subscribe(response => {
          dataN.name = response['libelle'];
          this.notification.push(dataN);
        });
    });
  }*/

  showInvitation() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(InvitationDialogComponent, {
      width: '650px',
      data: {},
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if (result) {
        //console.log('/single/'+result.project.id);
        this.router.navigate(['/project/' + result.project.id]);
        this.authService.obRefreshProjet.next(true);
        this.authService.getUserInvitation(0, 10).subscribe(res => {
          this.authService.obInviteBadge.next(res);
        });
        this.messageService.messageUpdated();
      }

    });
  }


  openDialogNotifReunion(): void {

    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DashboardDialogNotifReunion, {
      width: '1400px',
      data: { sentNotifCount: this.mettingsentNotifCount, receiveNotifCount: this.mettingreceiveNotifCount, copyNotifCount: this.mettingcopyNotifCount, commentNotifCount: this.mettingcommentNotifCount }
    });

    dialogRef.afterClosed().subscribe(result => {
       //console.log({result:result});
      if (result)
      {
        if (result.type == 'COMMENT')
        {
          this.router.navigate(['/faceTofacesReunion/details/' + result.id + '/' + result.number + '/' + result.type]);
        }
        else
        {
          this.router.navigate(['faceTofacesReunion/details/' + result.id + '/' + result.consult + '/' + result.type]);
        }
      }
      //this.animal = result;
    });

  }


}


/////////////////// contact view dialog ///////////////////

@Component({
  selector: 'app-dashboard-dialog',
  templateUrl: 'dashboard-dialog.html',
  styleUrls: ['dashboard-dialog.scss']
})
export class DashboardDialogComponent {

  contacts: Array<Contacts> = [];
  searchContact: any;
  secteur = "";
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  userManager = false;

  constructor(
    public dialogRef: MatDialogRef<ContactDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: ContactDialogData,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.secteur = localStorage.getItem('secteur');
    //this.loadContact();
    if(this.currentUser['roles'].includes("ROLE_MANAGER")){
      this.userManager = true;
    }
    this.loadContactBycompany(this.currentUser.compId);
  }


  loadContact(url?: string) {
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);
      this.contacts.sort((a, b) => {
        if ( a.fullName < b.fullName){
          return -1;
        }
        if ( a.fullName > b.fullName ){
          return 1;
        }
        return 0;
      });
      //this.contacts = res['hydra:member'];
      if (res['hydra:view']['hydra:next']) {
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }

  loadContactBycompany(url?: string) {
    url = url ? url : undefined;
    this.authService.getCompagnyMember(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);
      this.contacts.sort((a, b) => {
        if ( a.fullName < b.fullName){
          return -1;
        }
        if ( a.fullName > b.fullName ){
          return 1;
        }
        return 0;
      });
      //this.contacts = res['hydra:member'];
      if (res['hydra:view']['hydra:next']) {
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }

  goToProfile(profilId) {
    this.dialogRef.close(profilId);
  }

  goToCommonGroupe(profilId) {

    const commondialogRef = this.dialog.open(commonGroupeComponent, {
      width: '500px',
      data: { id: profilId }
    });

    commondialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if (result) {
        this.onNoClick();
        this.authService.obCommonProjectChosen.next(result);
        this.router.navigate(['/project/' + result]);
      }

    });

  }

  deleteMemberAdmin(profilId) {
    var roles = ["ROLE_USER"];
    this.authService.updateUserRole(profilId, "delete").subscribe( data => {
      for (var i = 0; i < this.contacts.length; i++) {
        if(profilId ===  this.contacts[i]['id']){
          this.contacts[i]['roles'] = data['roles'];
        }
      }
       //console.log('retour saveProfile '+JSON.stringify(data));
    }, (error) => {
        //console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });
  }

  addMemberAdmin(profilId) {
    var roles = ["ROLE_USER","ROLE_MANAGER"];
    this.authService.updateUserRole(profilId, "add").subscribe( data => {
      for (var i = 0; i < this.contacts.length; i++) {
        if(profilId ===  this.contacts[i]['id']){
          this.contacts[i]['roles'] = data['roles'];
        }
      }
       //console.log('retour saveProfile '+JSON.stringify(data));
    }, (error) => {
        //console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });
  }

  getAvatarlUrl(avatarLink) {
    return environment.server_url + avatarLink;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


/////////////////// common groupe view dialog ///////////////////

export interface Project {
  '@id': string;
  '@type': string;
  imageUrl: string;
  libelle: string;
  id: string;
  dateCreated: string;
  lastmsgextrait: string;
}

@Component({
  selector: 'app-commongroupe-dialog',
  templateUrl: 'commongroupe-dialog.html',
  styleUrls: ['commongroupe-dialog.scss']
})
export class commonGroupeComponent implements AfterViewInit {

  perPage = 10;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  projectArray: Project[];
  displayedColumns: string[] = ['Project'];
  dataSource: any;
  searchContact = null;
  secteur = "";

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private router: Router
  ) {
      this.secteur = localStorage.getItem('secteur');
  }

  getAvatarlUrl(avatarLink) {
    return environment.server_url + avatarLink;
  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getCommonProjects(this.paginator.pageIndex, this.perPage, this.data.id);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems'];;
          return data['hydra:member'];;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.projectArray = data;
        this.dataSource = new MatTableDataSource(this.projectArray);
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fctprojectChosen(projectId): void {
    this.dialogRef.close(projectId);
  }

}

/////////////////// Actions notification view dialog ///////////////////
@Component({
  selector: 'dashboard-ActiondialogNotif',
  templateUrl: 'dashboard-ActiondialogNotif.html',
  styleUrls: ['dashboard-ActiondialogNotif.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class DashboardActionsDialogNotif implements OnInit {

  /*displayedColumns: string[] = ['receptiondate','sender','receiver','project', 'suject',
    'Statut','consult'];*/
  currentUser = JSON.parse(localStorage.getItem("currentUser"))
  countActions : any;
  secteur = "";
  displayedColumns: string[] =
  ['Date',
   'manager',
  'resource',
  'project',
  'suject',
  'line',
  'nbProposition',
  'deadline',
  'consult'];

  displayedColumnsCommentaire: string[] =
  [
  'Date',
  'author',
  'project',
  'suject',
  'line',
  'nbProposition',
  'consult'
  ];

  dataSource: any[];
  resultsLength = 0;
  perPage = 30;
  notifMode = 1;
  isLoadingResults = true;
  isRateLimitReached = false;
  projectArray = [];
  lastDate = new Date('01/01/4970');
  sendbtnColor = 'white';
  sendbtnBackground = '#a386bd'
  receivebtnColor = '#a386bd';
  receivebtnBackground = 'white';
  copybtnColor = '#a386bd';
  copybtnBackground = 'white';
  answerbtnColor = '#a386bd';
  answerbtnBackground = 'white';
  commentbtnColor = '#a386bd';
  commentbtnBackground = 'white';
  lang = 'fr';
  sentNotifCount = 0;
  receiveNotifCount = 0;
  copyNotifCount = 0;
  answerNotifCount = 0;
  commentNotifCount = 0;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  public sendObs = new Subject();
  public sendObs$ = this.sendObs.asObservable();

  public receiveObs = new Subject();
  public receiveObs$ = this.receiveObs.asObservable();

  public copyObs = new Subject();
  public copyObs$ = this.copyObs.asObservable();

  public answerObs = new Subject();
  public answerObs$ = this.answerObs.asObservable();

  public commentObs = new Subject();
  public commentObs$ = this.commentObs.asObservable();

  actionLength:any;
  allPropos = [];

  constructor(private authService: AuthService,
    public dialogRef: MatDialogRef<DashboardDialogNotif>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataNotif,
    private router:Router,
    private messageService: MessageService) {

    this.sentNotifCount = data.sentNotifCount;
    this.receiveNotifCount = data.receiveNotifCount;
    this.copyNotifCount = data.copyNotifCount;
    this.commentNotifCount = data.commentNotifCount;
    this.secteur = localStorage.getItem('secteur');

  }

  ngOnInit() {
    //localStorage.setItem('notifMode', '0');
    
    this.dataSource = [];
    this.projectArray = [];

    this.sendObs.subscribe((btn) => {
      this.paginator.pageIndex = 0;
      this.notifMode = 0;//notif reçus envoyé
      this.getNotification();
      this.updateNotifications();
    });

    this.receiveObs.subscribe((btn) => {
      this.paginator.pageIndex = 0;
      this.notifMode = 1;//notif reçus
      this.getNotification();
      this.updateNotifications();

    });

    this.copyObs.subscribe((btn) => {
      this.paginator.pageIndex = 0;
      this.notifMode = 2;//notif copie
      this.getNotification();
      this.updateNotifications();
    });


    this.commentObs.subscribe((btn) => {
      this.paginator.pageIndex = 0;
      this.notifMode = 3;//notif commentaire
      this.getNotification();
      this.updateNotifications();
    });


    this.sendbtnColor = 'white';
    this.sendbtnBackground = '#a386bd';
    this.receivebtnColor = '#a386bd';
    this.receivebtnBackground = 'white';
    this.copybtnColor = '#a386bd';
    this.copybtnBackground = 'white';
    this.answerbtnColor = '#a386bd';
    this.answerbtnBackground = 'white';
    this.commentbtnColor = '#a386bd';
    this.commentbtnBackground = 'white';
    this.updateNotifications();
    this.dataSource = [];
    this.projectArray = [];

    /*if (localStorage.getItem('notifModeAction')) {
      this.notifMode = parseInt(localStorage.getItem('notifModeAction'), 10);
      //console.log('notif mode ==>'+ this.notifMode);
      this.updateData(this.notifMode);
    }
    else{
      this.getDisplayActions(1,0,10);
    }*/
    var notifselected = Number(localStorage.getItem('notifModeAction'));
    if(notifselected !== 100) {
      this.notifMode = notifselected;
      this.getDisplayActions(notifselected,0,10);
    } else if (this.sentNotifCount > 0) {
      this.notifMode = 0;
      this.getDisplayActions(0,0,10);
    }
    else if (this.receiveNotifCount > 0) {
      this.notifMode = 1;
      this.getDisplayActions(1,0,10);
    }
    else if (this.copyNotifCount > 0) {
      this.notifMode = 2;
      this.getDisplayActions(2,0,10);
    }
    else if (this.commentNotifCount > 0) {
      this.notifMode = 3;
      this.getDisplayActions(3,0,10);
    }


    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });

    /*
    localStorage.setItem('notifDiscussion', "2");
    this.notifMode = Number(localStorage.getItem("notifDiscussion"));
    alert("not "+this.notifMode);
    */

  }


  getChefBack2Color(element){
    if (element.statut == 'DONE') {
      return "#01c973";
    } else if (element.statut == 'TO_VALIDATE'){
      return '#ffb35b';
    } else if(element.statut == 'WAITING')
    {
      return "#ffb35b";
    } else if(element.statut == 'REFUSED')
    {
      return "#ff0000";
    }
  }

  getChefBackColor(element){
    if (element.statut == 'DONE') {
      return "#01c973"
    } else if (element.statut == 'TO_VALIDATE'){
      if(this.currentUser.id == element.porteur.id){
        return '#ffb35b';
      } else if(this.currentUser.id == element.manager.id){
        return '#01c973';
      } 
    } else if(element.statut == 'WAITING' && !element.isProposal)
    {
      if(this.currentUser.id == element.porteur.id){
        return '#78b0fd';
      } else if(this.currentUser.id == element.manager.id){
        return '#ffb35b';
      } 
    } else if(element.statut == 'WAITING' && element.isProposal){
      if(this.currentUser.id == element.porteur.id){
        return '#78b0fd';
      } else if(this.currentUser.id == element.manager.id){
        return '#ffb35b';
      }
    } else if(element.statut == 'REFUSED'){
      if(this.currentUser.id == element.porteur.id){
        return '#ff0000';
      } else if(this.currentUser.id == element.manager.id){
        return '#ff0000';
      }
    }
  }

  expandRow(element){
    element.isOpenProp = !element.isOpenProp
  }

  updateData(notifMode){
    localStorage.setItem('notifModeAction', notifMode);
    this.notifMode = notifMode;
    this.getDisplayActions(notifMode,this.paginator.pageIndex+1,10);
  }

  getDisplayActions(notifMode,page,perPage){
    //console.log("refreshing")
    this.dataSource = []
    this.allPropos = []
    merge(this.sendObs, this.receiveObs, this.copyObs, this.answerObs, this.commentObs,this.paginator.pageIndex)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;

        if (notifMode == 0) {
          //localStorage.setItem('notifModeAction', notifMode.toString());
          this.sendbtnColor = 'white';
          this.sendbtnBackground = '#a386bd';
          this.receivebtnColor = '#a386bd';
          this.receivebtnBackground = 'white';
          this.copybtnColor = '#a386bd';
          this.copybtnBackground = 'white';
          this.answerbtnColor = '#a386bd';
          this.answerbtnBackground = 'white';
          this.commentbtnColor = '#a386bd';
          this.commentbtnBackground = 'white';
        }
        else if (notifMode == 1) {
          //localStorage.setItem('notifModeAction', notifMode.toString());
          this.sendbtnColor = '#a386bd';
          this.sendbtnBackground = 'white';
          this.receivebtnColor = 'white';
          this.receivebtnBackground = '#a386bd';
          this.copybtnColor = '#a386bd';
          this.copybtnBackground = 'white';
          this.answerbtnColor = '#a386bd';
          this.answerbtnBackground = 'white';
          this.commentbtnColor = '#a386bd';
          this.commentbtnBackground = 'white';
        }
        else if (notifMode == 2) {
          //localStorage.setItem('notifModeAction', notifMode.toString());
          this.sendbtnColor = '#a386bd';
          this.sendbtnBackground = 'white';
          this.receivebtnColor = '#a386bd';
          this.receivebtnBackground = 'white';
          this.copybtnColor = 'white';
          this.copybtnBackground = '#a386bd';
          this.answerbtnColor = '#a386bd';
          this.answerbtnBackground = 'white';
          this.commentbtnColor = '#a386bd';
          this.commentbtnBackground = 'white';
        }
        else if (notifMode == 3) {
          //localStorage.setItem('notifModeAction', notifMode.toString());
          this.sendbtnColor = '#a386bd';
          this.sendbtnBackground = 'white';
          this.receivebtnColor = '#a386bd';
          this.receivebtnBackground = 'white';
          this.copybtnColor = '#a386bd';
          this.copybtnBackground = 'white';
          this.answerbtnColor = '#a386bd';
          this.answerbtnBackground = 'white';
          this.commentbtnColor = 'white';
          this.commentbtnBackground = '#a386bd';
        }

        if(notifMode == 0){
          this.projectArray = [];
          return this.authService.getActionsNotifications(0,(this.paginator.pageIndex+1), this.perPage);
        }else if (notifMode == 1){
          this.projectArray = [];
          return this.authService.getActionsNotifications(1,(this.paginator.pageIndex+1), this.perPage);
        }else if(notifMode == 2){
          this.projectArray = [];
          return this.authService.getActionsNotifications(2,(this.paginator.pageIndex+1), this.perPage);
        }
        else if(notifMode == 3){
          this.projectArray = [];
          return this.authService.getActionsNotifications(3,(this.paginator.pageIndex+1), this.perPage);
        }
        else{
          this.projectArray = [];
          return this.authService.getTasksNotification(notifMode,this.paginator.pageIndex+1,perPage,this.currentUser.id,null)
        }


      }),
      map(data => {
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = data['hydra:totalItems'];
        return data['hydra:member'];
      }),
      catchError(() => {
        this.isLoadingResults = false;
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    ).subscribe(data => {

        this.lastDate = new Date('01/01/4970');
        //this.lastDate = new Date('01/01/2000');

        
        for (let index = 0; index < data.length; index++) {
          if (data[index]['type'] == 'COMMENT') {
            let currentProjet = data[index];
            this.projectArray.push(this.dataParse(currentProjet));
          }
          else if (data[index]["deadline"]) {
            let currentProjet = data[index];
            this.projectArray.push(this.dataParse(currentProjet));
          }
        };
        
        

        let afterIsNewDay = false;
        /*
        if(this.notifMode !== 3){
          console.log("sorted"+this.projectArray.length);
          this.projectArray.sort(function(o1,o2){
            if (o1["receptiondate"].substring(0, 10) === o2["receptiondate"].substring(0, 10)) {
              console.log("exec0")
              if (o1["deadline"].substring(0, 10) < o2["deadline"].substring(0, 10))    return -1;
              else if(o1["deadline"].substring(0, 10) > o2["deadline"].substring(0, 10)) return  1;
              else return  0;
              
            } else return  0;
          });
        }*/
        this.projectArray.reverse().forEach((element, index) => {
          if (afterIsNewDay || index == 0) {
            this.projectArray[index]["afterIsNewDay"] = true;
            afterIsNewDay = false;
            //console.log("afterIsNewDay")
          }
          if (element.isNewDay == true) {
            afterIsNewDay = true;
          }
        });
        this.dataSource = this.projectArray.reverse();
        

    });
  }

  nextPage(event) {
    //console.log(event);
    this.getDisplayActions(this.notifMode,event.pageIndex + 1,10);
  }

  redirectActionRes(action){

    //console.log({Action:action});

    var strBoardId = action.actionDetail['action']['board'];
    var splittedBoardId = strBoardId.split("/");
    var idBoard = splittedBoardId[splittedBoardId.length - 1];


    location.href = "/#/project/" + action.id + "/meet/" + idBoard + "?line=" + action.action.id;

    /*this.authService.getRiderectAction(action.actionDetail.board).then((res)=>{
      //console.log(res)
      // this.router.navigateByUrl('/meet/',res['id'],'/face2face/',)
      location.href = "/#/project/" + action['id'] + "/meet/" + res['id'] + "?line=" + action.action.id
    })*/
  }

  redirectAction(element){

    /*if (element.type == "COMMENT") {
      location.href = "/#/meet/" + element.id + "/face2face/" + element.action.id+ "?f="+Math.random()+"&type="+element.type+"&remarkId="+element.message_id;
    }
    else {
      if (element.statutbis == 'DONE') {
        location.href = "/#/meet/" + element.id + "/face2face/" + element.action.id+ "?f="+Math.random();
      }
      else
      {
        location.href = "/#/meet/" + element.id + "/face2face/" + element.action.id + "?face=" + element.message_id+"&f="+Math.random();
      }
    }*/
    this.dialogRef.close(element);


  }

  redirectTasks(action){
    //console.log(action)
    this.authService.getRiderectAction(action.actionDetail['@id'], environment.server_url).then((res)=>{
     // console.log({res1:res})
      this.authService.getRiderectAction(res['board'], environment.server_url).then((result)=>{
       // console.log({res2:result})
        location.href = "/#/project/" + action['id'] + "/meet/" + result['id'] + "?action="+res['id'] + "&task="+action.task_id
      })
      // location.href = "/#/project/" + action['id'] + "/meet/" + res['id']
    })
  }


  dataParse(data: any) {

    var strId = data['project']['@id'];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length - 1];
    var listTag = data['tag'];
    var isNewDay = null;

    if (data['type'] == 'COMMENT' && !data['isProposal']) {

      isNewDay = this.isNewDay(data["dateCreated"]);

      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateCreated'],
        'type': data['type'],
        'typeParse': data['libelle'],
        'project': data['project']['libelle'],
        'suject': (listTag ? listTag.libelle : ''),
        'author': data['author'],
        'filegeneric': '',
        'isNewDay': isNewDay,
        'deadline':data["deadline"],
        'number':data['number'],
        'consult': data['smartNumber'],
        'commentNumber':data['commentNumber'],
        'lineAction':data["line"],
        'actionDetail':data,
        'isOpenProp':true,
        'action':data.action,
        'tag': ( data['tags'][0] && data['tags'][0].libelle ? data['tags'][0].libelle : "" ),
        'message_id':data['id'],
        'statut': data['actionStatus'],
        'nbProposition':  (data['numberProposal'] ? data['numberProposal'] : 1 ),
        'lastProposal': (data['lastProposal'] ? data['lastProposal'] : null),
      };
      return rowData;

    }
    else
    {

      //isNewDay = this.isNewDay(data["dateCreated"]);
      
      isNewDay = false;

      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateCreated'],
        'type': data['type'],
        'typeParse': data['libelle'],
        'project': data['project']['libelle'],
        'suject': (listTag ? listTag.libelle : ''),
        'manager': (data['type'] == 'ANSWER' ? data['recipient'] : data['author'] ),
        'porteur': (data['type'] == 'ANSWER' ? data['author']  : data['recipient']),
        'filegeneric': '',
        'isNewDay': isNewDay,
        'deadline':data["deadline"],
        'number':data['number'],
        'consult': data['smartNumber'],
        'lineAction':data["line"],
        'actionDetail':data,
        'isOpenProp':true,
        'action':data.action,
        'tag': (data.tags[0] ? data.tags[0] :  ''),
        'message_id':data['id'],
        'statut': data['actionStatus'],
        'isProposal': data['isProposal'],
        'lastProposal': (data['lastProposal'] ? data['lastProposal'] : null),
        'nbProposition': (data['numberProposal'] ? data['numberProposal'] : 1)
      };
      return rowData;

    }


  }


  dataParseTasks(data: any) {
   // console.log({dataTasks:data})
    var strId = data['project']['@id'];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length - 1];
    var listTag = data['tag'];
    var date = data['dateCreated'];
    var isNewDay = this.isNewDay(date);


    const rowData: any = {
      'id': idProject,
      'receptiondate': data['dateCreated'],
      'type': data['@type'],
      'typeParse': data['libelle'],
      'project': data['project']['libelle'],
      'suject': (listTag ? listTag.libelle : ''),
      'sender':  data['creator']['fullName'],
      'libelle': data['description'],
      // 'filegeneric': '',
      'isNewDay': isNewDay,
      'number':data['row'],
      'actionDetail':data.action,
      'task_id':data.id,
      'document':data.document
    };
    return rowData;

  }

  onNoClick(): void {
    //console.log('data dialog close');
    this.dialogRef.close();
  }

  isNewDay(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate);
    if (currentDate < this.lastDate) {
      //console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    }
    else {
      this.lastDate = currentDate;
      return false;
    }

    /*let currentDate = new Date(messageDate.substring(0, 10));
    if (currentDate > this.lastDate) {
      this.lastDate = currentDate;
      return true;
    }
    else {
      this.lastDate = currentDate;
      return false;
    }*/

  }

  isRoundFirst(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBL";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBR";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }

  updateNotifications() {

    this.authService.getActionsNotifications(0, 1, 10).subscribe((res) => {
      //console.log({sentNotifCount:res})
      this.sentNotifCount = res['hydra:totalItems'];
    });

    this.authService.getActionsNotifications(1, 1, 10).subscribe((res) => {
      //console.log({receiveNotifCount:res})
      this.receiveNotifCount = res['hydra:totalItems'];
     /* this.authService.getActionsNotifications(4, 1, 10).subscribe((res) => {
        //console.log({receiveNotifCount:res})
        this.receiveNotifCount += res['hydra:totalItems'];
      });*/

    });

    /*this.authService.getActionsNotifications(2, 1, 10).subscribe((res) => {
      //console.log({answerNotifCount:res})
      this.answerNotifCount = res['hydra:totalItems'];
    });*/

    this.authService.getActionsNotifications(2, 1, 10).subscribe((res) => {
      //console.log({copyNotifCount:res})
      this.copyNotifCount = res['hydra:totalItems'];
    });


    this.authService.getActionsNotifications(3, 1, 10).subscribe((res) => {
      //console.log({copyNotifCount:res})
      this.commentNotifCount = res['hydra:totalItems'];
    });

    /*this.authService.getTasksNotification(3, this.paginator.pageIndex+1, 10,this.currentUser.id,null).then((res) => {
      //console.log({answerNotifCount:res})
      this.copyNotifCount = res['hydra:totalItems'];
    });*/

    /*this.authService.getTasksNotification(2, this.paginator.pageIndex+1, 10,null,this.currentUser.id).then((res) => {
      //console.log({copyNotifCount:res})
      this.copyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getTasksNotification(3, this.paginator.pageIndex+1, 10,this.currentUser.id,null).then((res) => {
      //console.log({answerNotifCount:res})
      this.answerNotifCount = res['hydra:totalItems'];
    });*/

  }


  getNotification() {
    this.authService.getActionsNotifications(0, this.paginator.pageIndex+1, 10).subscribe(res => {
    //console.log("hhhhhhhh",this.authService.obNotifBadge.next(res))
    });

    this.authService.getNotification().subscribe(res => {
      //console.log("hhhhhhhh",this.authService.obNotifBadge.next(res))
    });
  }


}
/////////////////// notification view dialog ///////////////////

@Component({
  selector: 'dashboard-dialogNotif',
  templateUrl: 'dashboard-dialogNotif.html',
  styleUrls: ['dashboard-dialogNotif.scss']
})
export class DashboardDialogNotif implements OnInit, AfterViewInit {

  /*displayedColumns: string[] = ['receptiondate','sender','receiver','project', 'suject',
    'Statut','consult'];*/

  displayedColumns: string[] = ['receptiondate', 'sender', 'receiver', 'project', 'suject', 'consult'];
  displayedColumnsbis: string[] = ['receptiondate', 'sender', 'receiver', 'project', 'suject', 'consult'];

  dataSource: any[];
  resultsLength = 0;
  perPage = 30;
  notifMode = 1;
  isLoadingResults = true;
  isRateLimitReached = false;
  projectArray: any[];
  lastDate = new Date('01/01/4970');
  sendbtnColor = 'white';
  sendbtnBackground = '#05ab69'
  receivebtnColor = '#05ab69';
  receivebtnBackground = 'white'
  copybtnColor = '#05ab69';
  copybtnBackground = 'white'
  answerbtnColor = '#05ab69';
  answerbtnBackground = 'white'

  sentNotifCount = 0;
  receiveNotifCount = 0;
  copyNotifCount = 0;
  commentNotifCount = 0;
  secteur = "";

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  public sendObs = new Subject();
  public sendObs$ = this.sendObs.asObservable();



  constructor(private authService: AuthService,
    public dialogRef: MatDialogRef<DashboardDialogNotif>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataNotif) {

    this.sentNotifCount = data.sentNotifCount;
    this.receiveNotifCount = data.receiveNotifCount;
    this.copyNotifCount = data.copyNotifCount;
    this.commentNotifCount = data.commentNotifCount;
    this.secteur = localStorage.getItem('secteur');
    var notifselected = Number(localStorage.getItem('notifMode'));
    if (notifselected !== 100) {
      this.notifMode = notifselected;
    }
    else if (this.sentNotifCount > 0) {
      this.notifMode = 0;
    }
    else if (this.receiveNotifCount > 0) {
      this.notifMode = 1;
    }
    else if (this.copyNotifCount > 0) {
      this.notifMode = 2;
    }
    else if (this.commentNotifCount > 0) {
      this.notifMode = 3;
    }

  }

  ngOnInit() {

    merge(this.sendObs, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          if (this.notifMode == 0) {
            this.sendbtnColor = 'white';
            this.sendbtnBackground = '#05ab69';
            this.receivebtnColor = '#05ab69';
            this.receivebtnBackground = 'white';
            this.copybtnColor = '#05ab69';
            this.copybtnBackground = 'white';
            this.answerbtnColor = '#05ab69';
            this.answerbtnBackground = 'white';

          }
          else if (this.notifMode == 1 ) {
            this.sendbtnColor = '#05ab69';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = 'white';
            this.receivebtnBackground = '#05ab69';
            this.copybtnColor = '#05ab69';
            this.copybtnBackground = 'white';
            this.answerbtnColor = '#05ab69';
            this.answerbtnBackground = 'white';
          }
          else if (this.notifMode == 2) {
            this.sendbtnColor = '#05ab69';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = '#05ab69';
            this.receivebtnBackground = 'white';
            this.copybtnColor = 'white';
            this.copybtnBackground = '#05ab69';
            this.answerbtnColor = '#05ab69';
            this.answerbtnBackground = 'white';
          }
          else if (this.notifMode == 3) {
            this.sendbtnColor = '#05ab69';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = '#05ab69';
            this.receivebtnBackground = 'white';
            this.copybtnColor = '#05ab69';
            this.copybtnBackground = 'white';
            this.answerbtnColor = 'white';
            this.answerbtnBackground = '#05ab69';
          }
          //console.log('dans map notifmode ==>'+this.notifMode);
          return this.authService.getProjectNotification(this.notifMode, (this.paginator.pageIndex+1), this.perPage);
        }),
        map(data => {

          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;

          this.resultsLength = data['hydra:totalItems'];

          return data['hydra:member'];

        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the  API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {

          this.projectArray = [];
          this.lastDate = new Date('01/01/4970');

          //data = data.filter(item=>!item.action);

          for (let index = 0; index < data.length; index++) {
            let currentProjet = data[index];
            this.projectArray.push(this.dataParse(currentProjet));
          };

          let afterIsNewDay = false;
          this.projectArray.reverse().forEach((element, index) => {
            if (afterIsNewDay || index == 0) {
              this.projectArray[index]["afterIsNewDay"] = true;
              afterIsNewDay = false;
              //console.log("afterIsNewDay")
            }
            if (element.isNewDay == true) {
              afterIsNewDay = true;
            }
          });

          this.dataSource = this.projectArray.reverse();

      });

  }

  updateTalbeData(type){
    this.notifMode = type;
    localStorage.setItem('notifMode', type);
    this.dataSource = [];
    this.paginator.pageIndex = 0;
    this.sendObs.next(null);
    this.getNotification();
    this.updateNotifications();
  }


  ngAfterViewInit() {
    /*if (localStorage.getItem('notifMode')) {
      this.notifMode = parseInt(localStorage.getItem('notifMode'), 10);
      setTimeout(() => {
        this.updateTalbeData(this.notifMode);
      }, 1000);
    }
    else
    {
      this.updateTalbeData(0);
    }*/
    var notifselected = Number(localStorage.getItem('notifMode'));
    if (notifselected !== 100) {
      this.updateTalbeData(notifselected);
    }
    else if (this.sentNotifCount > 0) {
      this.updateTalbeData(0);
    }
    else if (this.receiveNotifCount > 0) {
      this.updateTalbeData(1);
    }
    else if (this.copyNotifCount > 0) {
      this.updateTalbeData(2);
    }
    else if (this.commentNotifCount > 0) {
      this.updateTalbeData(3);
    }

  }


  dataParse(data: any) {

    var strId = data['project']['@id'];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length - 1];
    var listTag: any[] = data['tags'];
    var date = data['dateCreated'];
    var isNewDay = this.isNewDay(date);
    var typeMessage = 'QUESTION';

    if (data['type'] == 'QUESTION') {
      typeMessage = 'Question';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;

    }
    else if (data['type'] == 'COMMENT') {
      typeMessage = 'Commentaire';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;

    }
    else if (data['type'] == 'ANSWER') {
      typeMessage = 'Réponse';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'receiver': (data['author'] ? data['author']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;
    }
    else
    {
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;
    }

  }

  onNoClick(): void {
    //console.log('data dialog close');
    this.dialogRef.close();
  }

  isNewDay(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate);
    if (currentDate < this.lastDate) {
      //console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    }
    else {
      this.lastDate = currentDate;
      return false;
    }
  }

  isRoundFirst(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBL";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBR";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }

  updateNotifications() {
    this.authService.getProjectNotification(0, 1, 10).subscribe((res) => {
      this.sentNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(1, 1, 10).subscribe((res) => {
      this.receiveNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(2, 1, 10).subscribe((res) => {
      this.copyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(3, 1, 10).subscribe((res) => {
      this.commentNotifCount = res['hydra:totalItems'];
    });

   /* this.authService.getProjectNotification(2, 1, 10).subscribe((res) => {
      this.copyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(3, 1, 10).subscribe((res) => {
      this.answerNotifCount = res['hydra:totalItems'];
    });*/

  }


  getNotification() {
    this.authService.getNotificationJAction().subscribe(res => {
      //console.log("res", res)
    });
    this.authService.getNotification().subscribe(res => {
      this.authService.obNotifBadge.next(res);
    });
    this.authService.getUserInvitation(0, 10).subscribe(res => {
      this.authService.obInviteBadge.next(res);
    });
  }


}

/////////////////// View invitation ///////////////////

export interface invitationElement {
  projectName: string;
  givenName: string;
  familyName: string;
  projectImage: string;
  userhote: string;
  dateCreated: string;
  idProject: string;
}

@Component({
  selector: 'invitation-dialog',
  templateUrl: 'invitation-dialog.html',
  styleUrls: ['invitation-dialog.scss']
})
export class InvitationDialogComponent implements AfterViewInit {

  invitationList = null;
  isLoadingResults = true;
  isRateLimitReached = false;
  resultsLength = 0;
  perPage = 10;
  secteur = "";

  private refreshObs = new Subject();
  public refreshObs$ = this.refreshObs.asObservable();

  ELEMENT_DATA: invitationElement[] = [];

  displayedColumns: string[] = ['projectImage', 'projectName', 'idProject'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public dialogRef: MatDialogRef<InvitationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataParam: any,
    private authService: AuthService,
    private messageService: MessageService
  ) {
    this.secteur = localStorage.getItem('secteur');
    /*this.authService.getUserInvitation().subscribe( invitationData => {
        //console.log('retour liste invitation '+invitationData);
        this.invitationList = invitationData['hydra:member'];
    });*/

  }

  ngAfterViewInit() {

    merge(this.paginator.page, this.refreshObs)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getUserInvitation(this.paginator.pageIndex, this.perPage);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems'];

          return data['hydra:member'];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        //var ELEMENT_DATA: invitationElement[] = [];
        this.ELEMENT_DATA = [];
        for (var i = 0; i < data.length; i++) {
          this.ELEMENT_DATA.push(this.parseData(data[i]));
        }
        this.invitationList = new MatTableDataSource(this.ELEMENT_DATA);
      });

  }

  parseData(itemData) {
    var item: invitationElement = {
      projectName: itemData.project.libelle,
      projectImage: itemData.userhote.imageUrl,
      userhote: itemData.userhote.fullName,
      givenName: itemData.userhote.givenName,
      familyName: itemData.userhote.familyName,
      dateCreated: itemData.createdAt,
      idProject: itemData.id
    };
    return item;
  }

  applyFilter(filterValue: string) {
    this.invitationList.filter = filterValue.trim().toLowerCase();
  }


  getAvatarlUrl(avatarLink) {
    return environment.server_url + avatarLink;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickAccepter(invitationID) {
    this.authService.accepterProjet(invitationID).subscribe(dataAccepte => {
      //console.log('retour projet accepte '+JSON.stringify(dataAccepte));
      this.messageService.messageUpdated();
      this.messageService.nextReloadPropostionNotif();
      this.messageService.nextReloadNotifAction();
      this.dialogRef.close(dataAccepte);
    }, (error) => {
      //console.log('Uh-oh, an error occurred! : ' + JSON.stringify(error));
      alert('une erreur est survenue');
    });
  }

  accepteProjet(invitationID) {
    this.authService.accepterProjet(invitationID).subscribe(dataAccepte => {
      //console.log('retour projet accepte '+JSON.stringify(dataAccepte));
    }, (error) => {
      alert('une erreur est survenue');
    });
  }

  onClickRefuser(invitationID) {
    this.authService.declineProjet(invitationID).subscribe(dataDecline => {
      this.refreshObs.next();
    }, (error) => {
      //console.log('Uh-oh, an error occurred! : ' + JSON.stringify(error));
      alert('une erreur est survenue');
    });
  }

  accepteAll() {
    //console.log(' accepteAll ==> '+this.ELEMENT_DATA.length);
    for (var i = 0; i < this.ELEMENT_DATA.length; i++) {
      if (i == (this.ELEMENT_DATA.length - 1)) {
        this.onClickAccepter(this.ELEMENT_DATA[i].idProject);
      }
      else {
        this.accepteProjet(this.ELEMENT_DATA[i].idProject);
      }
    }
    this.messageService.nextReloadPropostionNotif();
    this.messageService.nextReloadNotifAction();
  }

}



@Component({
  selector: 'tutoStepdialog',
  templateUrl: 'tutoStepdialog.html',
  styleUrls: ['tutoStepdialog.scss']
})
export class tutoStepdialog implements OnInit {
  lang = 'fr';

  constructor(
    public dialogRef: MatDialogRef<tutoStepdialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });
  }



  onNoClick(): void {
    this.dialogRef.close();
  }



}

/////////////////// notification view dialog reunion ///////////////////

@Component({
  selector: 'dashboard-dialogNotifReunion',
  templateUrl: 'dashboard-dialogNotifReunion.html',
  styleUrls: ['dashboard-dialogNotifReunion.scss']
})
export class DashboardDialogNotifReunion implements OnInit, AfterViewInit {

  /*displayedColumns: string[] = ['receptiondate','sender','receiver','project', 'suject',
    'Statut','consult'];*/

 /* displayedColumns: string[] = ['receptiondate', 'sender', 'receiver', 'project', 'suject', 'consult'];
  displayedColumnsbis: string[] = ['receptiondate', 'sender', 'receiver', 'project', 'suject', 'consult'];*/

  displayedColumns: string[] = ['receptiondate', 'sender', 'receiver',  'project', 'suject',  'consult'];
  displayedColumnsbis: string[] = ['receptiondate', 'sender', 'receiver',  'project', 'suject', 'consult'];

  dataSource: any[];
  resultsLength = 0;
  perPage = 30;
  notifMode = 2;
  isLoadingResults = true;
  isRateLimitReached = false;
  projectArray: any[];
  lastDate = new Date('01/01/4970');
  sendbtnColor = 'white';
  sendbtnBackground = '#5d97eb'
  receivebtnColor = '#5d97eb';
  receivebtnBackground = 'white'
  copybtnColor = '#5d97eb';
  copybtnBackground = 'white'
  answerbtnColor = '#5d97eb';
  answerbtnBackground = 'white'

  sentNotifCount = 0;
  receiveNotifCount = 0;
  copyNotifCount = 0;
  commentNotifCount = 0;
  secteur = "";

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  public sendObs = new Subject();
  public sendObs$ = this.sendObs.asObservable();



  constructor(private authService: AuthService,
    public dialogRef: MatDialogRef<DashboardDialogNotifReunion>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataNotif) {

    this.sentNotifCount = data.sentNotifCount;
    this.receiveNotifCount = data.receiveNotifCount;
    this.copyNotifCount = data.copyNotifCount;
    this.commentNotifCount = data.commentNotifCount;
    var notifselected = Number(localStorage.getItem('notifModeReunion'));
    this.secteur = localStorage.getItem('secteur');

    if (notifselected !== 100) {
      this.notifMode = notifselected;
    }
    else if (this.copyNotifCount > 0) {
      this.notifMode = 2;
    }
    else  {
      this.notifMode = 3;
    }

  }

  ngOnInit() {

    merge(this.sendObs, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          if (this.notifMode == 0) {
            this.sendbtnColor = 'white';
            this.sendbtnBackground = '#5d97eb';
            this.receivebtnColor = '#5d97eb';
            this.receivebtnBackground = 'white';
            this.copybtnColor = '#5d97eb';
            this.copybtnBackground = 'white';
            this.answerbtnColor = '#5d97eb';
            this.answerbtnBackground = 'white';

          }
          else if (this.notifMode == 1 ) {
            this.sendbtnColor = '#5d97eb';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = 'white';
            this.receivebtnBackground = '#5d97eb';
            this.copybtnColor = '#5d97eb';
            this.copybtnBackground = 'white';
            this.answerbtnColor = '#5d97eb';
            this.answerbtnBackground = 'white';
          }
          else if (this.notifMode == 2) {
            this.sendbtnColor = '#5d97eb';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = '#5d97eb';
            this.receivebtnBackground = 'white';
            this.copybtnColor = 'white';
            this.copybtnBackground = '#5d97eb';
            this.answerbtnColor = '#5d97eb';
            this.answerbtnBackground = 'white';
          }
          else if (this.notifMode == 3) {
            this.sendbtnColor = '#5d97eb';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = '#5d97eb';
            this.receivebtnBackground = 'white';
            this.copybtnColor = '#5d97eb';
            this.copybtnBackground = 'white';
            this.answerbtnColor = 'white';
            this.answerbtnBackground = '#5d97eb';
          }
          //console.log('dans map notifmode ==>'+this.notifMode);
          return this.authService.getMettingNotification(this.notifMode, (this.paginator.pageIndex+1), this.perPage);
        }),
        map(data => {

          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;

          this.resultsLength = data['hydra:totalItems'];

          return data['hydra:member'];

        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the  API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {

          this.projectArray = [];
          this.lastDate = new Date('01/01/4970');

          //data = data.filter(item=>!item.action);

          for (let index = 0; index < data.length; index++) {
            let currentProjet = data[index];
            this.projectArray.push(this.dataParse(currentProjet));
          };

          let afterIsNewDay = false;
          this.projectArray.reverse().forEach((element, index) => {
            if (afterIsNewDay || index == 0) {
              this.projectArray[index]["afterIsNewDay"] = true;
              afterIsNewDay = false;
              //console.log("afterIsNewDay")
            }
            if (element.isNewDay == true) {
              afterIsNewDay = true;
            }
          });

          this.dataSource = this.projectArray.reverse();
          console.log("project = "+JSON.stringify(this.dataSource));

      });

  }

  updateTalbeData(type){
    this.notifMode = type;
    this.dataSource = [];
    this.paginator.pageIndex = 0;
    this.sendObs.next(null);
    this.getNotification();
    this.updateNotifications();
    localStorage.setItem('notifModeReunion', type);
  }


  ngAfterViewInit() {
    /*if (localStorage.getItem('notifMode')) {
      this.notifMode = parseInt(localStorage.getItem('notifMode'), 10);
      setTimeout(() => {
        this.updateTalbeData(this.notifMode);
      }, 1000);
    }
    else
    {
      this.updateTalbeData(0);
    }*/
    var notifselected = Number(localStorage.getItem('notifModeReunion'));
    if (notifselected !== 100) {
      this.updateTalbeData(notifselected);
    }
    else if (this.copyNotifCount > 0) {
      this.updateTalbeData(2);
    }
    else {
      this.updateTalbeData(3);
    }

  }


  dataParse(data: any) {

    var strId = data['project']['@id'];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length - 1];
    var listTag: any[] = data['tags'];
    var date = data['dateCreated'];
    var isNewDay = this.isNewDay(date);
    var typeMessage = 'QUESTION';

    if (data['type'] == 'QUESTION') {
      typeMessage = 'Question';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;

    }
    else if (data['type'] == 'COMMENT') {
      typeMessage = 'Commentaire';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;

    }
    else if (data['type'] == 'ANSWER') {
      typeMessage = 'Réponse';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'receiver': (data['author'] ? data['author']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;
    }
    else
    {
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;
    }

  }

  onNoClick(): void {
    //console.log('data dialog close');
    this.dialogRef.close();
  }

  isNewDay(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate);
    if (currentDate < this.lastDate) {
      //console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    }
    else {
      this.lastDate = currentDate;
      return false;
    }
  }

  isRoundFirst(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBL";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBR";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }

  updateNotifications() {

    /*this.authService.getMettingNotification(0, 1, 10).subscribe((res) => {
      this.sentNotifCount = res['hydra:totalItems'];
    });*/

   /* this.authService.getMettingNotification(1, 1, 10).subscribe((res) => {
      this.receiveNotifCount = res['hydra:totalItems'];
    });*/

    this.authService.getMettingNotification(2, 1, 10).subscribe((res) => {
      this.copyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getMettingNotification(3, 1, 10).subscribe((res) => {
      this.commentNotifCount = res['hydra:totalItems'];
    });

   /* this.authService.getProjectNotification(2, 1, 10).subscribe((res) => {
      this.copyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(3, 1, 10).subscribe((res) => {
      this.answerNotifCount = res['hydra:totalItems'];
    });*/

  }


  getNotification() {
    this.authService.getNotificationJAction().subscribe(res => {
      //console.log("res", res)
    });
    this.authService.getNotification().subscribe(res => {
      this.authService.obNotifBadge.next(res);
    });
    this.authService.getUserInvitation(0, 10).subscribe(res => {
      this.authService.obInviteBadge.next(res);
    });
  }


}




