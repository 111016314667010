import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';

@Component({
  selector: 'app-statistique',
  templateUrl: './statistique.component.html',
  styleUrls: ['./statistique.component.scss']
})
export class StatistiqueComponent implements OnInit {

  dataStat = [];
  dataStatUser = [];
  displayedColumns: string[] = ['libelle', 'chiffre'];
  secteur = "";
  userEmail = '';
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  contacts = [];
  todaycontacts = [];
  yesterdaycontacts = [];
  beforeyesterdaycontacts = [];
  weekcontacts = [];
  monthcontacts = [];

  dataStatUserClicked = [];
  contactsclick = [];
  todaycontactsclick = [];
  yesterdaycontactsclick = [];
  beforeyesterdaycontactsclick = [];
  weekcontactsclick = [];
  monthcontactsclick = [];

  constructor(public authService: AuthService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userEmail = this.currentUser['email'];
    this.secteur = localStorage.getItem('secteur');
  	this.getStatistique();
  }

  getStatistiqueUser(contact, j){
    if(this.monthcontacts.length > 0){
      for (let i = 0; i < this.monthcontacts.length; i++) {
        if(i == j){
          this.monthcontactsclick[i] = true;
       } else {
          this.monthcontactsclick[i] = false;
        }
      }
    }
    if(this.contacts.length > 0){
      for (let i = 0; i < this.contacts.length; i++) {
        if(i == j){
          this.contactsclick[i] = true;
       } else {
          this.contactsclick[i] = false;
        }
      }
    }
    if(this.weekcontacts.length > 0){
      for (let i = 0; i < this.contacts.length; i++) {
        if(i == j){
          this.weekcontactsclick[i] = true;
       } else {
          this.weekcontactsclick[i] = false;
        }
      }
    }
    if(this.beforeyesterdaycontacts.length > 0){
      for (let i = 0; i < this.contacts.length; i++) {
        if(i == j){
          this.beforeyesterdaycontactsclick[i] = true;
       } else {
          this.beforeyesterdaycontactsclick[i] = false;
        }
      }
    }
    if(this.yesterdaycontacts.length > 0){
      for (let i = 0; i < this.contacts.length; i++) {
        if(i == j){
          this.yesterdaycontactsclick[i] = true;
       } else {
          this.yesterdaycontactsclick[i] = false;
        }
      }
    }
    if(this.todaycontacts.length > 0){
      for (let i = 0; i < this.contacts.length; i++) {
        if(i == j){
          this.todaycontactsclick[i] = true;
       } else {
          this.todaycontactsclick[i] = false;
        }
      }
    }
    this.authService.getStatUser(contact.id).subscribe(  
    (success) => {
     
      var dataArray = success['hydra:member'];
     
      this.dataStatUser = [{
        libelle: "Groupes",
        chiffre: dataArray.totalProjectUser
      },
      {
        libelle: "Sujets",
        chiffre: dataArray.totalTagUser
      },
      {
        libelle: "Nombre de faces",
        chiffre: dataArray.totalFaceUser
      },
      {
        libelle: "Nombre de messages",
        chiffre: dataArray.totalMessageUser
      },
      {
        libelle: "Nombre de documents",
        chiffre: dataArray.totalFileUser
      },
      {
        libelle: "Poid des documents",
        chiffre: dataArray.totalSizeUser
      },
      {
        libelle: "Nom d'entreprise",
        chiffre: dataArray.companyUser
      },
    ]; 
       
    },
    (error) => {
      alert('Erreur ! : ' + error)
    });
  }

  getStatistique(){
    this.authService.getStat().subscribe(  
    (success) => {
     
      var dataArray = success['hydra:member'];
     
      this.dataStat = [{
        libelle: "Nombre d'inscrits",
        chiffre: dataArray.totalUserCompany
      },
      {
        libelle: "Groupes",
        chiffre: dataArray.totalProjectCompany
      },
      {
        libelle: "Sujets",
        chiffre: dataArray.totalTagCompany
      },
      {
        libelle: "Nombre de faces",
        chiffre: dataArray.totalFaceCompany
      },
      {
        libelle: "Nombre de messages",
        chiffre: dataArray.totalMessageCompany
      },
      {
        libelle: "Nombre de documents",
        chiffre: dataArray.totalFileCompany
      },
      {
        libelle: "Poid des documents",
        chiffre: dataArray.totalSizeCompany
      },
      {
        libelle: "24 heures",
        chiffre: dataArray.dayUser
      },
      {
        libelle: "Dernière semaine",
        chiffre: dataArray.weekUser
      },
      {
        libelle: "Ce mois ci",
        chiffre: dataArray.currentMonthUser
      },
      {
        libelle: "Dernier mois",
        chiffre: dataArray.lastMonthUser
      },
      {
        libelle: "Dernièr 30 jours",
        chiffre: dataArray.last30daysUser       //11
      },
      {
        libelle: "Tous les inscrits",
        chiffre: dataArray.allUsers
      },
      {
        libelle: "Aujourd'hui",
        chiffre: dataArray.todayUser
      },
      {
        libelle: "Hier",
        chiffre: dataArray.yesterdayUser
      },
      {
        libelle: "Avant hier",
        chiffre: dataArray.beforeYesterdayUser
      },
      {
        libelle: "Tous les entreprises",
        chiffre: dataArray.nbCompanyUser        //16
      },
      {
        libelle: "Groupes all",
        chiffre: dataArray.totalProject
      },
      {
        libelle: "Sujets all",
        chiffre: dataArray.totalTag
      },
      {
        libelle: "Nombre de faces all",
        chiffre: dataArray.totalFace
      },
      {
        libelle: "Nombre de messages all",
        chiffre: dataArray.totalMessage
      },
      {
        libelle: "Nombre de documents all",
        chiffre: dataArray.totalFile
      },
      {
        libelle: "Poid des documents all",
        chiffre: dataArray.totalSize
      },
    ]; 
       
    },
    (error) => {
      alert('Erreur ! : ' + error)
    });
  }

  loadContact(url?: string){
    url = url ? url : undefined;
    this.todaycontacts = [];
    this.yesterdaycontacts = [];
    this.beforeyesterdaycontacts = [];
    this.weekcontacts = [];
    this.monthcontacts = [];
    this.authService.getAllContacts(url).subscribe(res => {
      	this.contacts = this.contacts.concat(res['hydra:member']);/*
    	for (var i = 0; i < this.contacts.length; i++) {
    		for (let index = 0; index < this.data.companyUser.length; index++) {
    			if(this.contacts[i]['id'] == this.data.companyUser[index]['user']['id']){
    			 	this.contacts[i]['isMember'] = true;
		 		}
	 		}
    	}
		if(res['hydra:view']['hydra:next']){
			this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
		}*/
    }); 
  }

  loadTodayContact(url?: string){
    url = url ? url : undefined;
    this.contacts = [];
    this.yesterdaycontacts = [];
    this.beforeyesterdaycontacts = [];
    this.weekcontacts = [];
    this.monthcontacts = [];
    this.authService.getTodayContacts(url).subscribe(res => {
      	this.todaycontacts = this.todaycontacts.concat(res['hydra:member']);/*
    	for (var i = 0; i < this.contacts.length; i++) {
    		for (let index = 0; index < this.data.companyUser.length; index++) {
    			if(this.contacts[i]['id'] == this.data.companyUser[index]['user']['id']){
    			 	this.contacts[i]['isMember'] = true;
		 		}
	 		}
    	}
		if(res['hydra:view']['hydra:next']){
			this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
		}*/
    }); 
  }

  loadYesterdayContact(url?: string){
    url = url ? url : undefined;
    this.contacts = [];
    this.todaycontacts = [];
    this.beforeyesterdaycontacts = [];
    this.weekcontacts = [];
    this.monthcontacts = [];
    this.authService.getYesterdayContacts(url).subscribe(res => {
      	this.yesterdaycontacts = this.yesterdaycontacts.concat(res['hydra:member']);/*
    	for (var i = 0; i < this.contacts.length; i++) {
    		for (let index = 0; index < this.data.companyUser.length; index++) {
    			if(this.contacts[i]['id'] == this.data.companyUser[index]['user']['id']){
    			 	this.contacts[i]['isMember'] = true;
		 		}
	 		}
    	}
		if(res['hydra:view']['hydra:next']){
			this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
		}*/
    }); 
  }

  loadBeforeYesterdayContact(url?: string){
    url = url ? url : undefined;
    this.contacts = [];
    this.todaycontacts = [];
    this.yesterdaycontacts = [];
    this.weekcontacts = [];
    this.monthcontacts = [];
    this.authService.getBeforeYesterdayContacts(url).subscribe(res => {
      	this.beforeyesterdaycontacts = this.beforeyesterdaycontacts.concat(res['hydra:member']);/*
    	for (var i = 0; i < this.contacts.length; i++) {
    		for (let index = 0; index < this.data.companyUser.length; index++) {
    			if(this.contacts[i]['id'] == this.data.companyUser[index]['user']['id']){
    			 	this.contacts[i]['isMember'] = true;
		 		}
	 		}
    	}
		if(res['hydra:view']['hydra:next']){
			this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
		}*/
    }); 
  }

  loadWeekContact(url?: string){
    url = url ? url : undefined;
    this.contacts = [];
    this.todaycontacts = [];
    this.yesterdaycontacts = [];
    this.beforeyesterdaycontacts = [];
    this.monthcontacts = [];
    this.authService.getWeekContacts(url).subscribe(res => {
      	this.weekcontacts = this.weekcontacts.concat(res['hydra:member']);/*
    	for (var i = 0; i < this.contacts.length; i++) {
    		for (let index = 0; index < this.data.companyUser.length; index++) {
    			if(this.contacts[i]['id'] == this.data.companyUser[index]['user']['id']){
    			 	this.contacts[i]['isMember'] = true;
		 		}
	 		}
    	}
		if(res['hydra:view']['hydra:next']){
			this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
		}*/
    }); 
  }

  loadMonthContact(url?: string){
    url = url ? url : undefined;
    this.contacts = [];
    this.todaycontacts = [];
    this.yesterdaycontacts = [];
    this.beforeyesterdaycontacts = [];
    this.weekcontacts = [];
    this.authService.getMonthContacts(url).subscribe(res => {
      	this.monthcontacts = this.monthcontacts.concat(res['hydra:member']);/*
    	for (var i = 0; i < this.contacts.length; i++) {
    		for (let index = 0; index < this.data.companyUser.length; index++) {
    			if(this.contacts[i]['id'] == this.data.companyUser[index]['user']['id']){
    			 	this.contacts[i]['isMember'] = true;
		 		}
	 		}
    	}
		if(res['hydra:view']['hydra:next']){
			this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
		}*/
    }); 
  }

}	
