import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import {merge, Observable, of as observableOf, Subject} from 'rxjs';
import { environment } from '../../../environments/environment';
import { MessageService } from 'src/app/services/message/message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //uriApi = environment.api_url+'https://theface.applize.fr/api';
  uriApi = environment.api_url;
  URL = environment.URL;
  uriMediaThumbail = environment.server_url+'/media/cache/resolve/thumbnail/media/facedoc/';
  uriMediaFullSize = environment.server_url+'/media/facedoc/';
  uriMediaPADFullSize = environment.server_url+'/media/pad/';
  resp: any;
  token: any;
  jwtToken: any;
  currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
  httpOptions: any;


  public obRefreshProjet = new Subject();
  public obRefreshProjet$ = this.obRefreshProjet.asObservable();

  public obNotifBadge = new Subject();
  public obNotifBadge$ = this.obNotifBadge.asObservable();

  public obInviteBadge = new Subject();
  public obInviteBadge$ = this.obInviteBadge.asObservable();

  public obprojectChosen = new Subject();
  public obprojectChosen$ = this.obprojectChosen.asObservable();


  public obCommonProjectChosen = new Subject();
  public obCommonProjectChosen$ = this.obCommonProjectChosen.asObservable();

  constructor(private http: HttpClient, private router: Router, private messageService: MessageService) { 


  }

  public get logIn(): boolean {
    return (localStorage.getItem('currentUser') !== null);
  }

  httpOptionsR() {
    if (this.currentUser !== null) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        })
      };
    }
    return this.httpOptions;
  }


  login(email: string, password: string) {
    return this.http.post(this.uriApi + '/pub/login', { email: email, plainPassword: password })
      .pipe(map(resp => {
        if (resp) {
          //console.log(' retour connexion => '+JSON.stringify(resp));
          localStorage.setItem('currentUser', JSON.stringify(resp));
          localStorage.setItem('notifDiscussion', "2");
          localStorage.setItem('notifReport', "1");
          localStorage.setItem('notifAtions', "1");
          localStorage.setItem('notifMode', "100");
          localStorage.setItem('notifModeAction', "100");
          localStorage.setItem('notifModeReunion', "100");
          this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
          var secteur  = this.currentUser.comType;
          if(secteur === "Réseaux de Magasins"){
            secteur = "magasins";
          } else if(secteur === "Réseaux de Clients"){
            secteur = "clients";
          } else {
            secteur = "groupes";
          }
          localStorage.setItem('secteur', secteur);
          this.router.navigate(['/dashboard']);
          this.messageService.setShowCreateProjectTuto(true);
          this.messageService.setShowProjectTuto(true);
          this.messageService.setShowProjectTutobis(true);
          this.messageService.setShowNotifTuto(true);
        }
        return resp;
      }));
  }

  loginbis(email: string, password: string) {
    return this.http.post(this.uriApi + '/pub/login', { email: email, plainPassword: password })
      .pipe(map(resp => {
        if (resp) {
          //console.log(' retour connexion => '+JSON.stringify(resp));
          localStorage.setItem('currentUser', JSON.stringify(resp));
          this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
        return resp;
      }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUser = null;
    //this.router.navigate(['/']);
    window.location.href = 'https://faceretails.com/';
    this.messageService.setShowCreateProjectTuto(false);
    this.messageService.setShowProjectTuto(false);
    this.messageService.setShowProjectTutobis(false);
    this.messageService.setShowNotifTuto(false);
    this.messageService.setListProject([]);
  }

  register(fname: string, lname: string, jobT: string, email: string, passwd: string, company_id: string) {
    // tslint:disable-next-line:max-line-length
    if (company_id) {
      return this.http.post(this.uriApi + '/pub/register', { email: email, givenName: fname, familyName: lname, jobTitle: jobT, plainPassword: passwd, company: company_id})
      .pipe(map(resp => {
        if (resp) {
          //console.log(resp);
        }
        return resp;
      }));
    }
    else
    {
      return this.http.post(this.uriApi + '/pub/register', { email: email, givenName: fname, familyName: lname, jobTitle: jobT, plainPassword: passwd})
      .pipe(map(resp => {
        if (resp) {
          //console.log(resp);
        }
        return resp;
      }));
    }
  }

  recoverPw(email: string) {
    return this.http.post(this.uriApi + '/pub/forgot-password-request', { email: email })
      .pipe(map(resp => {
        return resp;
      }));
  }

  setNew(password: string, token: string) {
    //console.log('{ passwordResetToken: ' + token + ', plainPassword: ' + password + ' }');
    return this.http.post(this.uriApi + '/pub/reset-password-confirmation', { passwordResetToken: token, plainPassword: password })
      .pipe(map(resp => {
        return resp;
      }));
  }

  confirm(token: string) {
    return this.http.post(this.uriApi + '/pub/user-confirmation', { confirmationToken: token })
      .pipe(map(resp => {
        return resp;
      }));
  }

  renewConf(email: string) {
    return this.http.post(this.uriApi + '/pub/renew-user-confirmation', { email: email })
      .pipe(map(resp => {
        return resp;
      }));
  }

  getProjects(uri?: string) {
    const httpOptionsG = this.httpOptionsR(); 
    uri = uri ? uri : '/projects?perPage=100&order[motionAt]=desc';
    //uri = uri ? uri : '/projects?perPage=100&order[dateCreated]=desc';
    return this.http.get(this.uriApi + uri, httpOptionsG)
      .pipe(map(resp => {
        return resp;
      })); 
  }

  getDataDuplicated(id?: string) {
    const httpOptionsG = this.httpOptionsR(); 
    const uri = '/projects/'+id+'/payload';
    //uri = uri ? uri : '/projects?perPage=100&order[dateCreated]=desc';
    return this.http.get(this.uriApi + uri, httpOptionsG)
      .pipe(map(resp => {
        return resp;
      })); 
  }

  existProject(libelle?: string) {
    const httpOptionsG = this.httpOptionsR(); 
    const uri = '/project-exists?libelle='+libelle;
    //uri = uri ? uri : '/projects?perPage=100&order[dateCreated]=desc';
    return this.http.get(this.uriApi + uri, httpOptionsG)
      .pipe(map(resp => {
        return resp;
      })); 
  }

  getCommonProjects(pageIndex: any, perPage: any, withuser_id: any) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get<any>(this.uriApi + '/projects?withuser_id='+withuser_id+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc', httpOptionsG);  
  }

  getTheProject(ID: any) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/projects/' + ID, httpOptionsG)
      .pipe(map(resp => {
        return resp;
      }));
  }

  getContactsPorteur() {
    const httpOptionsG = this.httpOptionsR();
    //let uri = manager_id ? '/porteurs?manager='+manager_id+'&status[]=WAITING&status[]=REFUSED&perPage=100' : '/porteurs?perPage=100';
    return this.http.get(this.uriApi + '/user-porters', httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getContactsManager() {
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/managers', httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getContactsByManager(manager_id) {
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/user-porters?manager='+manager_id, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getNotifBoard() {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/meeting-nofif', httpOptionsG).pipe(map(resp => {
        return resp;
    }));
  }

  offNotifBoard() {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/meeting-nofif?offNotif=true', httpOptionsG).pipe(map(resp => {
        return resp;
    }));
  }

  getAllMessages(pageIndex: any, perPage: any, searchValues: any) {
    
    const httpOptionsG = this.httpOptionsR();
    if(searchValues)
    {

      var param = '/messages?page='+(pageIndex+1)+'&type=QUESTION&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false&isActionMessage=false&isMettingMessage=false';
      if (searchValues.transmitter) {
        param+='&author='+searchValues.transmitter;
      }
      if (searchValues.recipient) {
        param+='&recipient='+searchValues.recipient;
      }
      if (searchValues.projectId) {
        param+='&project='+searchValues.projectId;
      }
      if (searchValues.tag) {
        param+='&tags.id='+searchValues.tag;
      }

      if (searchValues.keyWord && !searchValues.type) {
        param+='&orSearch_text='+searchValues.keyWord;
      }

      if (!searchValues.keyWord && (searchValues.type == 'http')) {
        param+='&orSearch_text='+searchValues.type;
      }

      if (searchValues.keyWord && (searchValues.type == 'http')) {
        param+='&orSearch_text='+searchValues.keyWord+' '+searchValues.type;
      }

      if (searchValues.smartNumber) {
        param+='&smartNumber='+searchValues.smartNumber;
      }

      if ((searchValues.type == 'pdf') || (searchValues.type == 'video') || (searchValues.type == 'image') || (searchValues.type == 'excel') || (searchValues.type == 'presentation')){
        param+='&filegeneric.extension='+searchValues.type;
      }
      
      if (searchValues.statut) {
        param+='&type=QUESTION&isMessageDisabled=false&isMessageAnswered='+searchValues.statut;
      }

      if (searchValues.dateBefore) {
        var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
        param+='&dateCreated[before]='+ strDate.toLocaleString( );
      }
      if (searchValues.dateAfter) {
        var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
        param+='&dateCreated[after]='+ strDate.toLocaleString( );
      }
      //console.log({param})
      return this.http.get<any>(this.uriApi + param, httpOptionsG);
    }
    else
    {
      return this.http.get<any>(this.uriApi + '/messages?page='+(pageIndex+1)+'&type=QUESTION&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false&isActionMessage=false&isMettingMessage=false', httpOptionsG);
    }
  }

  getDashboardActions(pageIndex: any, perPage: any, searchValues: any) {
    
    const httpOptionsG = this.httpOptionsR();
    if(searchValues)
    {

      var param = '/messages?page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc';
      if (searchValues.transmitter) {
        param+='&author='+searchValues.transmitter;
      }
      if (searchValues.recipient) {
        param+='&recipient='+searchValues.recipient;
      }
      if (searchValues.projectId) {
        param+='&project='+searchValues.projectId;
      }
      if (searchValues.tag) {
        param+='&tags.id='+searchValues.tag;
      }
      if (searchValues.keyWord) {
        param+='&orSearch_text='+searchValues.keyWord;
      }
      if (searchValues.smartNumber) {
        param+='&smartNumber='+searchValues.smartNumber;
      }

      if ((searchValues.type == 'pdf') || (searchValues.type == 'video') || (searchValues.type == 'image') || (searchValues.type == 'excel') || (searchValues.type == 'presentation')){
        param+='&filegeneric.extension='+searchValues.type;
      }
      
      if (searchValues.statut) {
        param+='&isMessageAnswered='+searchValues.statut;
      }

      if (searchValues.dateBefore) {
        var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
        param+='&dateCreated[before]='+ strDate.toLocaleString( );
      }
      if (searchValues.dateAfter) {
        var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
        param+='&dateCreated[after]='+ strDate.toLocaleString( );
      }

      return this.http.get<any>(this.uriApi + param, httpOptionsG);
    }
    else
    {
      return this.http.get<any>(this.uriApi + '/messages?exists[action]=true&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc', httpOptionsG);
    }
  }

  getProjectMessages(ID: any) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/journal?project=' + ID, httpOptionsG)
      .pipe(map(res => {
        //console.log(res);
        return res['hydra:member'];
      }));
  }


  getContacts(uri?: string) {
    const httpOptionsG = this.httpOptionsR();
     uri = uri ? uri : '/contacts?perPage=100&order[familyName]=ASC';
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getAllContacts(uri?: string) {
    const httpOptionsG = this.httpOptionsR();
     //uri = uri ? uri : '/allcontacts?perPage=100&order[familyName]=ASC';
     uri = uri ? uri : '/allcontacts?perPage=1000&order[familyName]=ASC';
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getTodayContacts(uri?: string) {
    const httpOptionsG = this.httpOptionsR();
     //uri = uri ? uri : '/allcontacts?perPage=100&order[familyName]=ASC';
     uri = uri ? uri : '/todaycontacts?perPage=1000&order[familyName]=ASC';
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getYesterdayContacts(uri?: string) {
    const httpOptionsG = this.httpOptionsR();
     //uri = uri ? uri : '/allcontacts?perPage=100&order[familyName]=ASC';
     uri = uri ? uri : '/yesterdaycontacts?perPage=1000&order[familyName]=ASC';
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getBeforeYesterdayContacts(uri?: string) {
    const httpOptionsG = this.httpOptionsR();
     //uri = uri ? uri : '/allcontacts?perPage=100&order[familyName]=ASC';
     uri = uri ? uri : '/beforeyesterdaycontacts?perPage=1000&order[familyName]=ASC';
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getWeekContacts(uri?: string) {
    const httpOptionsG = this.httpOptionsR();
     //uri = uri ? uri : '/allcontacts?perPage=100&order[familyName]=ASC';
     uri = uri ? uri : '/weekcontacts?perPage=1000&order[familyName]=ASC';
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getMonthContacts(uri?: string) {
    const httpOptionsG = this.httpOptionsR();
     //uri = uri ? uri : '/allcontacts?perPage=100&order[familyName]=ASC';
     uri = uri ? uri : '/monthcontacts?perPage=1000&order[familyName]=ASC';
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  sendQuestion(
    textMessage: any,
    theTags: any,
    /*newTag: boolean,*/
    theProject: any,
    recepeant: any,
    copie: any,
    isPrivate: boolean,
    isCommentActive: boolean,
    deadline: any,
    relanceOne: any,
    relanceTwo: any,
    relanceTree: any,
    file: any, 
    action,
    isActionMessage,
    author
    ) {
     

        var strDate = null;
        var strDateOne = null;
        var strDateTwo = null;
        var strDateTree = null;
        
        if(deadline){
          deadline = moment(deadline).format('DD-MM-YYYY');
          strDate = deadline.toLocaleString( );
        }

        if(relanceOne){
          relanceOne = moment(relanceOne).format('DD-MM-YYYY');
          strDateOne = relanceOne.toLocaleString( );
        }

        if(relanceTwo){
          relanceTwo = moment(relanceTwo).format('DD-MM-YYYY');
          strDateTwo = relanceTwo.toLocaleString( );
        }

        if(relanceTree){
          relanceTree = moment(relanceTree).format('DD-MM-YYYY');
          strDateTree = relanceTree.toLocaleString( );
        }


        //console.log(' dans envoi dans un media ');

        let formData:FormData = new FormData();
        formData.append('type', 'QUESTION');
        formData.append('text', textMessage);
        
        if(strDate){
          formData.append('deadline', strDate);
        }
        
        if(strDateOne){
          formData.append('relance1', strDateOne);
        }

        if(strDateTwo){
          formData.append('relance2', strDateTwo);
        }

        if(strDateTree){
          formData.append('relance3', strDateTree);
        }

        formData.append('isPrivate',(isPrivate == true ? 'true' : 'false'));
        formData.append('project',theProject);
        //console.log(file)
        if(file){
          for (var indexFile = 0; indexFile < file.length; indexFile++) {
            formData.append('filegeneric['+indexFile+'][type]','FILE');
            formData.append('filegeneric['+indexFile+'][file]',file[indexFile]);
          }
        }
        
        if (copie) {
          for (let indexCopy = 0; indexCopy < copie.length; indexCopy++) {
            // if (copie[indexCopy] != 0) {
              formData.append('copy['+indexCopy+'][project]',theProject);
              formData.append('copy['+indexCopy+'][user]','/api/users/'+copie[indexCopy]);
            // }
          }
        }
        
        formData.append('recipient','/api/users/'+recepeant);

        if (author) {
          formData.append('author','/api/users/'+author);
        }
        
        formData.append('tags[0][libelle]',theTags.libelle);
        formData.append('tags[0][project]',theProject);
        formData.append('tags[0][tagId]','/api/tags/'+theTags.id);

        if(action){
          formData.append('action',action)
          formData.append('exists[action]','true')
          formData.append('actionStatus', 'WAITING');
        }

        if(isActionMessage){
          formData.append('isActionMessage','true');
        }
        else
        {
          formData.append('isActionMessage','false');
        }

        /*formData.append('tags[0][libelle]',(newTag == false ? theTags.libelle : theTags));
        formData.append('tags[0][project]',theProject);
        formData.append('tags[0][tagId]',(newTag == false ? '/api/tags/'+theTags.id : ''));*/

        let options = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
          })
        };
 
        return this.http.post(this.uriApi + '/questions', formData, options)
        .pipe(map(res => {
          return res;
        }));

  }

  sendAnswer(
    text: any,
    theTags: any,
    isPrivate: boolean,
    project: any,
    question: any,
    file: any,
    stat,
    action,
    isProposal
  ) {

    //console.log({question})

      //console.log(' le file --> '+ file);

      let formData:FormData = new FormData();
      formData.append('type', 'ANSWER');
      formData.append('text', text);
      formData.append('isPrivate',(isPrivate == true ? 'true' : 'false'));
      formData.append('project',project);
      
      for (var indexFile = 0; indexFile < file.length; indexFile++) {
        formData.append('filegeneric['+indexFile+'][type]','FILE');
        formData.append('filegeneric['+indexFile+'][file]',file[indexFile]);
      }

      formData.append('tags[0][libelle]',theTags.libelle);
      formData.append('tags[0][project]',project);
      formData.append('tags[0][tagId]',theTags.id);
      formData.append('question','/api/messages/' + question);

      if(stat){
        formData.append('actionStatus',stat);
        formData.append('exists[action]','true');
        formData.append('action',action);
        formData.append('isMessageAnswered','true');
      }

      if(isProposal){
        formData.append('isActionMessage','true');
        formData.append('isProposal','true');
      }

      let options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        })
      };

      return this.http.post(this.uriApi + '/answers', formData, options)
      .pipe(map(res => {
        return res;
      }));
 
  }

  sendComment(
    text: any,
    theTags: any,
    isPrivate: boolean,
    project: any,
    question: any,
    file: any,
    isProposal: any,
    isActionMessage: any,
    numberProposal: any
  ) {
    
 
      let formData:FormData = new FormData();
      formData.append('type', 'COMMENT');
      formData.append('text', text);
      formData.append('isPrivate',(isPrivate == true ? 'true' : 'false'));
      formData.append('project',project);
      
      for (var indexFile = 0; indexFile < file.length; indexFile++) {
        formData.append('filegeneric['+indexFile+'][type]','FILE');
        formData.append('filegeneric['+indexFile+'][file]',file[indexFile]);
      }

   
       
      formData.append('tags[0][libelle]',theTags.libelle);
      formData.append('tags[0][project]',project);
      formData.append('tags[0][tagId]',theTags.id);
     

      formData.append('parentItem','/api/messages/' + question);
      
      if(isProposal === true){
        formData.append('isProposal','true');
      }

      if(isActionMessage === true){
        formData.append('isActionMessage','true');
        if(!numberProposal){
          console.log("dans commente propo 1");
          formData.append('commentProposition', '1');
        }
        else
        {
          console.log("dans commente propo supérieur à 1");
          formData.append('commentProposition', numberProposal);
        }
      }


      let options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        })
      };

      return this.http.post(this.uriApi + '/comments', formData, options)
      .pipe(map(res => {
        return res;
      }));

    
  }


  saveProfile(
    image: string,
    email: string,
    gender: string,
    givenName: string,
    familyName: string,
    birthDate: string,
    jobTitle: string,
    description: string,
    telephone: string,
    country: string,
    plainPassword: string
  ) {
    return this.http.post(this.uriApi + '/users', {
      image: image,
      email: email,
      gender: gender,
      givenName: givenName,
      familyName: familyName,
      birthDate: birthDate,
      jobTitle: jobTitle,
      description: description,
      telephone: telephone,
      country: country,
      plainPassword: plainPassword
    })
    .pipe(map(resp => {
       return resp;
    }));

  }

  

  getNotification() {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/notifications', httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
  }

  getNotificationJAction() {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/actions-journal-notifications', httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
  }

  getProjectNotification(notifMode, pageIndex: any, perPage: any) {
   const httpOptionsG = this.httpOptionsR();
   /*if (notifMode === 0) {
     return this.http.get<any>(this.uriApi+'/messages?type=QUESTION&author='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageAnswered=false&isMessageDisabled=false&page='+pageIndex+'&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false', httpOptionsG);
   }else if (notifMode === 1) {
     return this.http.get<any>(this.uriApi+'/messages?type=QUESTION&recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageDisabled=false&isMessageAnswered=false&page='+pageIndex+'&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false', httpOptionsG);
   }
  else if (notifMode === 2) {
     return this.http.get<any>(this.uriApi+'/messages?copy.user='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageDisabled=false&copy.isRead=false&page='+pageIndex+'&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false', httpOptionsG);
   }
   else{
    return this.http.get<any>(this.uriApi+'/messages?type=ANSWER&recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageDisabled=false&isMessageAnswered=false&isRecipientRead=false&page='+pageIndex+'&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false', httpOptionsG);
   }*/
   if (notifMode === 0) {
     return this.http.get<any>(this.uriApi+'/messages?type=QUESTION&author='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageAnswered=false&isMessageDisabled=false&page='+pageIndex+'&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false&isActionMessage=false&isMettingMessage=false', httpOptionsG);
   }else if (notifMode === 1) {
     return this.http.get<any>(this.uriApi+'/messages?recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageDisabled=false&orSimple_answer=false&page='+pageIndex+'&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false&isActionMessage=false&isMettingMessage=false', httpOptionsG);
   }else if (notifMode === 2) {
     return this.http.get<any>(this.uriApi+'/messages?copy.user='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageDisabled=false&type[]=ANSWER&type[]=QUESTION&copy.isRead=false&page='+pageIndex+'&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false&isActionMessage=false&isMettingMessage=false', httpOptionsG);
   }else{
     return this.http.get<any>(this.uriApi+'/messages?type=COMMENT&copy.user='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&copy.isRead=false&isMessageDisabled=false&page='+pageIndex+'&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false&isActionMessage=false&isMettingMessage=false', httpOptionsG);
   }
 } 

 getActionsNotifications(notifMode, pageIndex: any, perPage: any){
    const httpOptionsG = this.httpOptionsR();
   if (notifMode === 0) {
    return  this.http.get<any>(this.uriApi+'/messages?filter_action=send_'+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageDisabled=false&page='+pageIndex+'&perPage='+perPage+'&order[deadline]=asc&exists[action]=false&exists[numberAction]=false&isActionMessage=true', httpOptionsG);
   }else if (notifMode === 1) {
    return  this.http.get<any>(this.uriApi+'/messages?isMessageDisabled=false&filter_action=recv_'+JSON.parse(localStorage.getItem('currentUser'))['id']+'&page='+pageIndex+'&perPage='+perPage+'&order[deadline]=asc&exists[action]=false&exists[numberAction]=false&isActionMessage=true', httpOptionsG);
   }else if (notifMode === 2) {
    return this.http.get<any>(this.uriApi+'/messages?copy.user='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageDisabled=false&copy.isRead=false&filter_action=copy_'+JSON.parse(localStorage.getItem('currentUser'))['id']+'&page='+pageIndex+'&perPage='+perPage+'&order[deadline]=asc&exists[action]=false&exists[numberAction]=false&isActionMessage=true', httpOptionsG);
   }
   else{
    return this.http.get<any>(this.uriApi+'/messages?type=COMMENT&isProposal=false&copy.user='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&copy.isRead=false&isMessageDisabled=false&page='+pageIndex+'&perPage='+perPage+'&order[dateCreated]=desc&exists[action]=false&exists[numberAction]=false&isActionMessage=true', httpOptionsG);
   }
 }

  createProject(libelle, description, deadline, tags, userProject, typeproject, parentid) {
    
    const httpOptionsG = this.httpOptionsR();

    if (deadline) {
      var strDate = moment(deadline).format('DD-MM-YYYY');    
      var dateString = strDate.toLocaleString( );
    }
    var parent = null;
    typeproject = "PARENT";
    /*
    if (typeproject === "CHILD"){
      parent = '/api/projects/' + parentid;
    }
    */

    return this.http.post(this.uriApi + '/projects',{
      "libelle": libelle,
      "description": description,
      "type": typeproject,
      "deadline": strDate,
      "parentItem": parent,
      "tags": tags,
      "userProject": userProject
    }, httpOptionsG)
    .pipe(map(res => {
      return res;
    }));
  
  }

  projectImage(projectId, newImage) {


    //console.log('newImage =>'+ JSON.stringify(newImage))

    let formData:FormData = new FormData();
    formData.append('imageFile', newImage);
    
    let options = {
      headers: new HttpHeaders({
        //'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      })
    };

    return this.http.post(this.uriApi + '/projects/'+projectId+'/image', formData, options).pipe(map(res => {
      return res;
    }));

  }

  addUserProject(project, userProject, email) {
    const httpOptionsG = this.httpOptionsR();
    if(email != null) 
    {
      return this.http.post(this.uriApi + '/user-projects',{
        "email": userProject,
        "project": project
      }, httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
    }
    else
    {
      return this.http.post(this.uriApi + '/user-projects',{
        "user": '/api/users/'+userProject,
        "project": project
      }, httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
    }
  }


  getFiles(ID: any, pageIndex: any, perPage: any, specMsg: any, searchValues: any) {
    const httpOptionsG = this.httpOptionsR();
    
    //console.log(' searchValues me voila!!!'+JSON.stringify(searchValues));
    
    var param = '/filegenerics?page='+(pageIndex+1)+'&perPage='+perPage+'&order[createdAt]=desc&exists[message.action]=false';
    
    if(ID) param += '&message.project='+ID;

    if (searchValues.transmitter) {
      param+='&message.author='+searchValues.transmitter;
    }
    if (searchValues.recipient) {
      param+='&message.recipient='+searchValues.recipient;
    }
    if (searchValues.tag) {
      param+='&message.tags.id='+searchValues.tag;
    }
    if (searchValues.keyWord) {
      param+='&message.text='+searchValues.keyWord;
    }
    if (searchValues.smartNumber) {
      param+='&message.smartNumber='+searchValues.smartNumber;
    }
    if (searchValues.dateBefore) {
      var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
      param+='&message.dateCreated[before]='+ strDate.toLocaleString( );
    }
    if (searchValues.dateAfter) {
      var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
      param+='&message.dateCreated[after]='+ strDate.toLocaleString( );
    }
    
    if ((searchValues.type == 'pdf') || (searchValues.type == 'video') || (searchValues.type == 'image') || (searchValues.type == 'excel') || (searchValues.type == 'presentation')){
      param+='&extension='+searchValues.type;
    }
    
    if (searchValues.isActionMessage && (searchValues.isActionMessage == true) ) {
      param+='&message.isActionMessage=true';
    }
    else
    {
      param+='&message.isActionMessage=false';
    }

    if (searchValues.isMettingMessage && (searchValues.isMettingMessage == true) ) {
      param+='&message.isMettingMessage=true';
    }
    else
    {
      param+='&message.isMettingMessage=false';
    }

    //param+='&exists[message.action]='+isPad;
    //param+='&exists[message.action]=false&message.isActionMessage='+isPad;
    return this.http.get<any>(this.uriApi + param, httpOptionsG);
  }



  getProjectHisMessages(ID: any, pageIndex: any, perPage: any, specMsg: any, specMsgType: any, searchValues: any) {
    const httpOptionsG = this.httpOptionsR();
    if(specMsg)
    {
      if(specMsgType && specMsgType == 'COMMENT')
      {
         return this.http.get<any>(this.uriApi +'/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc&isActionMessage=false&isMettingMessage=false&orSearch_number='+specMsg, httpOptionsG);
      }
      else
      {
        return this.http.get<any>(this.uriApi +'/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc&isActionMessage=false&isMettingMessage=false&smartNumber='+specMsg, httpOptionsG);
      }
    }
    else if(searchValues)
    {
      
      //console.log(' searchValues me voila!!!'+JSON.stringify(searchValues));

      var param = '/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false&isMettingMessage=false&isActionMessage=false';

      if (searchValues.transmitter) {
        param+='&author='+searchValues.transmitter;
      }
      if (searchValues.recipient) {
        param+='&recipient='+searchValues.recipient;
      }
      if (searchValues.tag) {
        param+='&tags.id='+searchValues.tag;
      }
      

      if (searchValues.keyWord && !searchValues.type) {
        param+='&orSearch_text='+searchValues.keyWord;
      }

      if (!searchValues.keyWord && (searchValues.type == 'http')) {
        param+='&orSearch_text='+searchValues.type;
      }

      if (searchValues.keyWord && (searchValues.type == 'http')) {
        param+='&orSearch_text='+searchValues.keyWord+' '+searchValues.type;
      }

      /*if (searchValues.keyWord) {
        param+='&orSearch_text='+searchValues.keyWord;
      }*/

      if (searchValues.smartNumber) {
        param+='&smartNumber='+searchValues.smartNumber;
      }
      
      if ((searchValues.type == 'pdf') || (searchValues.type == 'video') || (searchValues.type == 'image') || (searchValues.type == 'excel') || (searchValues.type == 'presentation')){
        param+='&filegeneric.extension='+searchValues.type;
      }
      
      if (searchValues.statut) {
        param+='&isMessageAnswered='+searchValues.statut;
      }

      if (searchValues.dateBefore) {
        var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
        param+='&dateCreated[before]='+ strDate.toLocaleString( );
      }
      if (searchValues.dateAfter) {
        var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
        param+='&dateCreated[after]='+ strDate.toLocaleString( );
      }

      return this.http.get<any>(this.uriApi + param, httpOptionsG);

    }
    else
    {
      return this.http.get<any>(this.uriApi +'/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc&exists[action]=false&exists[numberAction]=false&isMettingMessage=false&isActionMessage=false', httpOptionsG);
    }

  }

  getMediaData(mediaUrl: any) {
     return this.uriMediaFullSize+mediaUrl;
  }

  getMediaDataPAD(mediaUrl: any) {
     return this.uriMediaPADFullSize+mediaUrl;
  }

  getMediaThumbail(mediaUrl: any) {
     return this.uriMediaThumbail+mediaUrl;
  }

  getUserInvitation(pageIndex: any, perPage: any){
     const httpOptionsG = this.httpOptionsR();
     return this.http.get<any>(this.uriApi + '/user-projects?status=PENDING&order[createdAt]=desc&page='+(pageIndex+1)+'&perPage='+perPage, httpOptionsG);
    /*return this.http.get(this.uriApi + '/user-projects?status=PENDING&order[createdAt]=desc', httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));*/
  }

  accepterProjet(idInvitation){
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/user-projects/'+idInvitation,{
      "status": "ACCEPTED"
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  declineProjet(idInvitation){
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/user-projects/'+idInvitation,{
      "status": "REFUSED"
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }


  updateUserProject(item, values){
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + item['@id'].replace('/api', ''), values, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  deleteUserProject(item){
    const httpOptionsG = this.httpOptionsR();
    return this.http.delete(this.uriApi + item['@id'].replace('/api', ''), httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  markAsRead(messageInfo){
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.put(this.uriApi + '/messages/' + messageInfo.id,{
      "isRecipientRead": true
    }, httpOptionsG).pipe(map(res => {
      return res;
    }));
  }

  markAsReadCopy(idCopy) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/copies/' + idCopy,{
      "isRead": true
    }, httpOptionsG).pipe(map(res => {
      return res;
    }));
  }

  getInfoContact(idContact) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/users/'+ idContact, httpOptionsG)
    .pipe(map(resp => {
      return resp;
    })); 
  }

  udapteUser(givenName, familyName, birthDate, jobTitle, newemail, gender, description, telephone, oldPassword, plainPassword) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/users/'+JSON.parse(localStorage.getItem('currentUser'))['id'],{
      "givenName": givenName,
      "familyName": familyName,
      "birthDate": birthDate,
      "jobTitle": jobTitle,
      "newemail": newemail,
      "gender": gender,
      "description": description,
      "telephone": telephone,
      "oldPassword": oldPassword,
      "plainPassword": plainPassword
    }, httpOptionsG).pipe( map ( res => {
      if (res) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.currentUser.givenName = res['givenName'];
        this.currentUser.familyName = res['familyName'];
        this.currentUser.email = res['email'];
        this.currentUser.gender = res['gender'];
        this.currentUser.birthDate = res['birthDate'];
        this.currentUser.description = res['description'];
        this.currentUser.telephone = res['telephone'];
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      }
      return res;
    }));
  }

  updateUserRole(idprofil, method) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.post(this.uriApi + '/users/updateroles',{
      "iduser": idprofil,
      "method": method
    }, httpOptionsG).pipe( map ( res => {
      if (res) {
      }
      return res;
    }));
  }


  udapteUserImage(imageFile) {
  
    let formData:FormData = new FormData();
    formData.append('imageFile', imageFile);  

    let options = {
      headers: new HttpHeaders({
        //'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      })
    };

    return this.http.post(this.uriApi +'/users/'+JSON.parse(localStorage.getItem('currentUser'))['id']+'/profil', formData, options)
    .pipe(map(res => {
       if (res) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.currentUser.imageUrl = res['imageUrl'];
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      }
      return res;
    }));


  }

  sendQuestionFormContact(
    givenName: any,
    lastName: any,
    email: any,
    messageToSend: any,
  ) {
 
      const httpOptionsG = this.httpOptionsR();
   
      return this.http.post(this.uriApi + '/pub/contactus',{
        "givenName": givenName,
        "lastName": lastName,
        "email": email,
        "message": messageToSend,
        "objet":''
      }, httpOptionsG)
      .pipe(map(res => {
        return res;
      }));

  }

  transfertByMail(
    customerData: any,
    messageData: any
  ) {
 
      const httpOptionsG = this.httpOptionsR();
  
      return this.http.post(this.uriApi + '/messages/'+messageData.id+'/transfert',{
        "recipient": customerData.Destinataire,
        "copies": customerData.EnCopie,
        "message": customerData.message,
      }, httpOptionsG)
      .pipe(map(res => {
        return res;
      })); 
    
  }

  disableMessage(
    messageData: any
  ) {
 
    const httpOptionsG = this.httpOptionsR();

    return this.http.post(this.uriApi + '/messages/'+messageData.id+'/disable',{}, httpOptionsG)
    .pipe(map(res => {
      return res;
    })); 
    
  }

  recalls(
    messageData: any
  ) {
 
    const httpOptionsG = this.httpOptionsR();

    //console.log('message id recalls '+messageData['@id']);
    return this.http.post(this.uriApi + '/recalls',{
      "message":messageData['@id'] 
    }, httpOptionsG)
    .pipe(map(res => {
      return res;
    }));  
    
  }
 
   recallAction(
    actionId: any
  ) {
 
    const httpOptionsG = this.httpOptionsR();

    return this.http.post(this.uriApi + '/recalls',{
      "action": '/api/actions/'+actionId 
    }, httpOptionsG)
    .pipe(map(res => {
      return res;
    }));  
    
  }

  getLastFaceNumber(
    idProject: any
  ) {
      
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/messages?project='+idProject+'&order[smartNumber]=DESC&perPage=1', httpOptionsG)
    .pipe(map(resp => {
      return resp;
    })); 

  }

  getAnswerListe() {
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/quickanswers', httpOptionsG)
    .pipe(map(resp => {
      return resp;
    })); 
  }

  sendQuickAnswer(
    text: any,
    theTags: any,
    isPrivate: boolean,
    project: any,
    question: any
  ) {

      //console.log(' le tag --> '+ JSON.stringify(theTags));
      let formData:FormData = new FormData();
      formData.append('type', 'ANSWER');
      formData.append('text', text);
      formData.append('isPrivate',(isPrivate == true ? 'true' : 'false'));
      formData.append('project',project);
      formData.append('isQuickanswer','true');
      formData.append('tags[0][libelle]',theTags.libelle);
      formData.append('tags[0][project]',project);
      formData.append('tags[0][tagId]',theTags.id);
      formData.append('question','/api/messages/' + question);

      let options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        })
      };

      return this.http.post(this.uriApi + '/answers', formData, options)
      .pipe(map(res => {
        return res;
      }));
 
  }


  changeProjetName(libelle, projectId){
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/projects/'+projectId,{
      "libelle": libelle
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  changeSubjectName(libelle, tagsId){
   const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/tags/'+tagsId,{
      "libelle": libelle
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  deleteProject(projectId){
    const httpOptionsG = this.httpOptionsR();
    return this.http.delete(this.uriApi + '/projects/'+projectId, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }


  addNewSubject(libelle, project) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.post(this.uriApi + '/tags',{
      "libelle": libelle,
      "project": "/api/projects/"+project
    }, httpOptionsG)
    .pipe(map(res => {
      return res;
    }));
  }

  getStat() {   
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/stats', httpOptionsG).pipe(map(resp => {
      return resp;
    })); 
  }

  getStatUser(id) {   
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/stats?id='+id, httpOptionsG).pipe(map(resp => {
      return resp;
    })); 
  }

  getUserInfo(userEmail) { 
    var resEmail = userEmail.toLowerCase();
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/users?email='+resEmail, httpOptionsG).pipe(map(resp => {
      return resp;
    })); 
  }
  
   contactMessage(type, objet, email, message) {
      const httpOptionsG = this.httpOptionsR();
      return this.http.post(this.uriApi + '/pub/contactus?email='+email+'&message='+message+'&type='+type+'&object='+objet, {}, httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
  }



  getAllActions(perPage,pageIndex,project,responsable,manager,start,end,documents,statusFilter,line,tag,trieType){

    const httpOptionsG = this.httpOptionsR();
    console.log("triie "+trieType);
    var params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&type=QUESTION';

    if (statusFilter == 'DONE') {
      params += '&order[dateModified]=desc';
    }
    else if (trieType == 'dateEcheance') {
      params +='&order[deadline]=asc';
    }
    else
    {
      params += '&order[motionAt]=desc';
    }

    if (manager) {
      params+='&author='+manager;
    }
    if (responsable) {
      params+='&recipient='+responsable;
    }
    if (project) {
      params+='&project='+project;
    }
    if (tag) {
      params+='&tags.id='+tag;
    }

    if (end) {
      var strDate = moment(end).format('DD-MM-YYYY');    
      params+='&dateCreated[before]='+ strDate.toLocaleString( );
    }

    if (start) {
      var strDate = moment(start).format('DD-MM-YYYY');    
      params+='&dateCreated[after]='+ strDate.toLocaleString( );
    }

    if(statusFilter){
      if (statusFilter == 'myTodoList') {
       // params+='&actionStatus[]=WAITING&actionStatus[]=REFUSED';  
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&order[deadline]=asc&type=QUESTION&recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=WAITING&actionStatus[]=TO_VALIDATE&actionStatus[]=REFUSED&isRecipientRead=false';
      }
      else if (statusFilter == 'myCheckList') {
        //params+='&messageValidated=TRUE';
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&type=QUESTION&order[deadline]=asc&author='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=WAITING&actionStatus[]=TO_VALIDATE&actionStatus[]=REFUSED'; 
      }
      else if (statusFilter == 'ALL_MANAGER') {
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&type=QUESTION&order[deadline]=asc&author='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=WAITING&actionStatus[]=TO_VALIDATE&actionStatus[]=REFUSED';
      }
      else if (statusFilter == 'WAITING_MANAGER') {
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&type=QUESTION&order[deadline]=asc&author='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=WAITING';
      }
      else if (statusFilter == 'TO_VALIDATE_MANAGER') {
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&type=QUESTION&order[deadline]=asc&author='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=TO_VALIDATE';
      }
      else if (statusFilter == 'REFUSED_MANAGER') {
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&type=QUESTION&order[deadline]=asc&author='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=REFUSED';
      }
      else if (statusFilter == 'DONE_MANAGER') {
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&type=QUESTION&order[deadline]=asc&author='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=DONE';
      }
      else if (statusFilter == 'WAITING_PORTEUR') {
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&order[deadline]=asc&type=QUESTION&recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=WAITING';
      }
      else if (statusFilter == 'WAITING_BIS_PORTEUR') {
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&order[deadline]=asc&type=QUESTION&recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=TO_VALIDATE';
      }
      else if (statusFilter == 'REFUSED_PORTEUR') {
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&order[deadline]=asc&type=QUESTION&recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=REFUSED';
      }
      else if (statusFilter == 'DONE_BIS_PORTUER') {
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&order[deadline]=asc&type=QUESTION&recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=DONE&isRecipientRead=false';
      }
      else if (statusFilter == 'DONE_PORTEUR') {
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&order[deadline]=asc&type=QUESTION&recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=DONE&isRecipientRead=true';
      }
      else if (statusFilter == 'ALL_PORTEUR') {
        params = '/messages?page='+pageIndex+'&perPage='+perPage+'&isMessageDisabled=false&isActionMessage=true&order[deadline]=asc&type=QUESTION&recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&actionStatus[]=WAITING&actionStatus[]=TO_VALIDATE&actionStatus[]=REFUSED&isRecipientRead=false';
      }
      else {
        params+='&actionStatus='+statusFilter;  
      }  
    } 

    //console.log({param})
    return this.http.get<any>(this.uriApi + params, httpOptionsG).toPromise();
  }

  getActionsByProject(project_id, pageIndex, perPage, dateBefore, dateAfter){

    if (dateBefore && dateAfter) {

      var strDateBefore = moment(dateBefore).format('DD-MM-YYYY');    
      var strDateAfter = moment(dateAfter).format('DD-MM-YYYY');    
      
      let options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        }),
        params : new HttpParams()
        .set('project',project_id)
        .set('pageIndex',pageIndex) 
        .set('perPage',perPage) 
        .set('dateCreated[before]',strDateBefore.toLocaleString())  
        .set('dateCreated[after]', strDateAfter.toLocaleString()) 
      };
      return this.http.get(this.URL+"/boards",options).toPromise()
    }
    else if (dateBefore && !dateAfter) {

      var strDateBefore = moment(dateBefore).format('DD-MM-YYYY');    

      let options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        }),
        params : new HttpParams()
        .set('project',project_id)
        .set('pageIndex',pageIndex) 
        .set('perPage',perPage) 
        .set('dateCreated[before]',strDateBefore.toLocaleString()) 
      };
      return this.http.get(this.URL+"/boards",options).toPromise()
    }
    else if (!dateBefore && dateAfter) {

      var strDateAfter = moment(dateAfter).format('DD-MM-YYYY');          

      let options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        }),
        params : new HttpParams()
        .set('project',project_id)
        .set('pageIndex',pageIndex) 
        .set('perPage',perPage) 
        .set('dateCreated[after]',strDateAfter.toLocaleString()) 
      };
      return this.http.get(this.URL+"/boards",options).toPromise()
    }
    else
    {
       let options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        }),
        params : new HttpParams()
        .set('project',project_id)
        .set('pageIndex',pageIndex) 
        .set('perPage',perPage) 
      };
      return this.http.get(this.URL+"/boards",options).toPromise()
    }
  }

  getboardsNotifications(project_id){   
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      }),
      // +'&order[dateCreated]=desc'
      params : new HttpParams().set('idproject',project_id)
    };
    return this.http.get(this.URL+"/boards-notifications",options).toPromise()
  }

  getBoardDetails(project_id, board_id, perPage, pageIndex){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      }),
      params : new HttpParams().set('project',project_id)
      .set('board',board_id)
      //.set('order[dateCreated]','DESC')
      .set('order[deadline]','ASC')
      //.set('order[priority]','ASC')
      .set('pageIndex',pageIndex) 
      .set('perPage',perPage) 
    };
    return this.http.get(this.URL+"/actions",options).toPromise()
  }



  getBoardbyId(board_id){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      }),
    };
    //return this.http.get(this.URL+"/boards/"+board_id,options).toPromise()
    return this.http.get(this.URL+"/boards/"+board_id,options)
  }
 

 

  addBoard(data){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
        // 'Content-Type':'multipart/form-data'
      }),
    };
    return this.http.post(this.URL+"/boards",data,options).toPromise()
  }
  
  updateBoard(board_id,data){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
        // 'Content-Type':'multipart/form-data'
      }),
    };
    return this.http.put(this.URL+"/boards/"+board_id,data,options).toPromise()
  }

  appendDocumentBoard(board_id,data){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
        // 'Content-Type':'multipart/form-data'
      }),
    };
    return this.http.post(this.URL+"/boards/"+board_id+"/document",data,options).toPromise()
  }
  appendDocumentAction(action_id,data){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
        // 'Content-Type':'multipart/form-data'
      }),
    };
    return this.http.post(this.URL+"/actions/"+action_id+"/document",data,options).toPromise()
  }
  appendDocumentTask(task_id,data){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
        // 'Content-Type':'multipart/form-data'
      }),
    };
    return this.http.post(this.URL+"/tasks/"+task_id+"/document",data,options).toPromise()
  }

  postDocument(formData){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
        'Content-Type':'multipart/form-data'
      }),
    };
    return this.http.post(this.URL+"/filegenerics",formData,options).toPromise()
  }

  addAction(data){
    //console.log('addAction => '+data);
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      })
    };
    return this.http.post(this.URL+"/actions",data,options).toPromise()
  }

  deleteBoard(board_id){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      })
    };
    return this.http.delete(this.URL+"/boards/"+board_id,options).toPromise()
  }

  actionsNotifications(){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      }),
      params : new HttpParams().set('exists[action]','true')
    };
    return this.http.get(this.URL+"/messages",options).toPromise()
  }

  getActionTasks(action_id){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      }),
      params : new HttpParams()
      .set('action',action_id)
      .set('perPage','100')
      .set('order[dateCreated]','DESC')
    };
    return this.http.get(this.URL+"/tasks",options).toPromise()
  }

  addTask(taskBody){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      }),
    };
    return this.http.post(this.URL+"/tasks",taskBody,options).toPromise()
  }

  deleteTask(taskId){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      }),
    };
    return this.http.delete(this.URL+"/tasks/"+taskId,options).toPromise()
  }

  updateTask(taskId,taskBody){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      }),
    };
    return this.http.put(this.URL+"/tasks/"+taskId,taskBody,options).toPromise()
  }

  updateAction(action_id,actionData){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      }),
    };
    return this.http.put(this.URL+"/actions/"+action_id,actionData,options).toPromise()
  }

  deleteAction(action_id){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      }),
    };
    return this.http.delete(this.URL+"/actions/"+action_id,options).toPromise()
  }

  deleteTaskDocument(action_id){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      }),
    };
    return this.http.put(this.URL+"/tasks/"+action_id,{document:null},options).toPromise()
  }


  deleteActionDocument(action_id){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      }),
    };
    return this.http.put(this.URL+"/actions/"+action_id,{document:null},options).toPromise()
  }

  getAllTags(){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      })
    };
    return this.http.get(this.URL+"/tags",options).toPromise()
  }

  getProjectInfo(project_id){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      })
    };
    return this.http.get(this.URL+"/projects/"+project_id,options).toPromise()
  }

  addTagToProject(tag){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      })
    };
    return this.http.post(this.URL+"/tags",tag,options).toPromise()
  }

  deleteTag(tag_id){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      })
    };
    return this.http.delete(this.URL+"/tags/"+tag_id,options).toPromise()
  }

  updateTag(tag_id,new_tag){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      })
    };
    return this.http.put(this.URL+"/tags/"+tag_id,new_tag,options).toPromise()
  }

  getActionDetails(action_id){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      })
    };
    return this.http.get(this.URL+"/actions/"+action_id,options).toPromise()
  }
  
  getProposition(author,recipient,action){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      }),
      params : new HttpParams()
      .set('exists[action]','true')
      .set('author',author)
      .set('recipient',recipient)
      .set('perPage','100')
    };
    return this.http.get(this.URL+"/messages",options).toPromise()
  }

  getNbPropositionAction(actionId){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      }),
      params : new HttpParams()
      .set('action',actionId)
      .set('perPage','1')
      .set('type','QUESTION')
    };
    return this.http.get(this.URL+"/messages",options).toPromise()
  }

  getSingleMessage(message){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      })
    };
    return this.http.get(this.URL+'/messages/'+message,options).toPromise()
  }

  updateActionStatus(action_id,ansewer){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
      })
    };
    return this.http.put(this.URL+'/actions/'+action_id,{status:ansewer},options).toPromise()
  }

  // getAnsewerByQuestion(){
  //   let options = {
  //     headers: new HttpHeaders({
  //       'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token'],
  //     })
  //   };
  //   return this.http.get(this.URL+"")
  // }

  getAllAction(notifMode,pageIndex,perPage,responsable,manager){
    let params = "/actions?perPage="+perPage+"&page="+pageIndex+ '&order[dateCreated]=DESC'
    if(responsable){
      params+='&resource='+responsable
    }
    if(manager){
      params+='&manager='+manager
    }
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      }),
    };
    return this.http.get(this.URL+params,options).toPromise()
  }
  

  getTasksNotification(notifMode,pageIndex,perPage,responsable,manager){
    let params = "/tasks?perPage="+perPage+"&page="+pageIndex
    if(responsable){
      params+='&creator='+responsable
    }
    if(manager){
      params+='&action.manager='+manager
    }
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      }),
    };
    return this.http.get(this.URL+params,options).toPromise()
  }

  getRiderectAction(action, appLink='https://api.neoface.app'){
    let options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      }),
    };
    return this.http.get(appLink+action,options).toPromise()
  }
 
  getActionsBisNotifications() {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/read-notifications?action=read', httpOptionsG).pipe(map(resp => {
      return resp;
    })); 
  } 

  takeActionsBisNotificationsOff() {  
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/read-notifications?action=off', httpOptionsG).pipe(map(resp => {
      return resp;
    })); 
  }

  getAllActionsNotRead(userId: any, pageIndex: any, perPage: any, searchValues: any) {
    
    const httpOptionsG = this.httpOptionsR();
    return this.http.get<any>(this.uriApi + '/actions?page='+(pageIndex+1)+'&perPage='+perPage+'&order[deadline]=ASC&resource='+userId+'&status[]=WAITING&status[]=REFUSED', httpOptionsG); 
 
  } 

   
  getAllCompteRendu(pageIndex: any, perPage: any, searchValues: any) {

      const httpOptionsG = this.httpOptionsR();
      if(searchValues)
      {
 
        var param = '/boards?type=MEETING&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc';
        if (searchValues.transmitter) {
          param+='&creator='+searchValues.transmitter;
        }

        if (searchValues.projectId) {
          param+='&project='+searchValues.projectId;
        }

         if (searchValues.createDateAfter) {
          var strDate = moment(searchValues.createDateAfter).format('DD-MM-YYYY');    
          param+='&dateCreated[after]='+ strDate.toLocaleString( );
        }

        if (searchValues.createDateBefore) {
          var strDate = moment(searchValues.createDateBefore).format('DD-MM-YYYY');    
          param+='&dateCreated[before]='+ strDate.toLocaleString( );
        }
        
        if (searchValues.dateAfter) {
          var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
          param+='&dateMeeting[after]='+ strDate.toLocaleString( );
        }

        if (searchValues.dateBefore) {
          var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
          param+='&dateMeeting[before]='+ strDate.toLocaleString( );
        }
      
        return this.http.get<any>(this.uriApi + param, httpOptionsG);
      }
      else
      { 
        return this.http.get<any>(this.uriApi + '/boards?type=MEETING&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc', httpOptionsG);
      }
 
  } 


  getAllPropositionAction(idProject,idAction){
    const httpOptionsG = this.httpOptionsR();
    return this.http.get<any>(this.uriApi+'/journal?project='+idProject+'&perPage=100&order[dateCreated]=desc&action='+idAction,httpOptionsG);
  }

  getNotificationSetting(){
    const httpOptionsG = this.httpOptionsR();
    return this.http.get<any>(this.uriApi+'/configurations?perPage=50',httpOptionsG);
  }

  notifEnable(value, propertyID) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.post(this.uriApi + '/configurations',{
      "propertyID": propertyID,
      "value": value
    }, httpOptionsG)
    .pipe(map(res => {
      return res;
    }));
  }

  sendCommentProposition(
    text: any,
    theTags: any,
    isPrivate: boolean,
    project: any,
    question: any,
    file: any,
    action: any
  ) {
  
    
      let formData:FormData = new FormData();
      formData.append('type', 'COMMENT');
      formData.append('text', text);
      formData.append('isPrivate',(isPrivate == true ? 'true' : 'false'));
      formData.append('project',project);
      
      for (var indexFile = 0; indexFile < file.length; indexFile++) {
        formData.append('filegeneric['+indexFile+'][type]','FILE');
        formData.append('filegeneric['+indexFile+'][file]',file[indexFile]);
      }

      formData.append('tags[0][libelle]',theTags.libelle);
      formData.append('tags[0][project]',project);
      formData.append('tags[0][tagId]',theTags.id);

      formData.append('parentItem','/api/messages/' + question);
      formData.append('action',action)
      

      let options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        })
      };

      return this.http.post(this.uriApi + '/comments', formData, options)
      .pipe(map(res => {
        return res;
      }));

  }

  getContactsExp() {
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/user-transmitters', httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
    /*
    return this.http.get(this.uriApi + '/expeditors', httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
    */
  }

  getContactsRecip() {
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/user-receiver', httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
    /*
    return this.http.get(this.uriApi + '/recipients', httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));*/
  }


  getContactsRecipID(ID) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/user-receiver?project='+ID, httpOptionsG).pipe(map(resp => {
        return resp;
    }));
    /*
    return this.http.get(this.uriApi + '/users-used?project='+ID+'&UserWithMessage_id=2', httpOptionsG).pipe(map(resp => {
        return resp;
    }));*/
  }

  getRecipientsBySender(ID, manager) {
    const httpOptionsG = this.httpOptionsR();
      return this.http.get(this.uriApi + '/user-receiver?project='+ID+'&manager='+manager, httpOptionsG).pipe(map(resp => {
          return resp;
    }));
  }

  getAllRecipientsBySender(manager) {
    const httpOptionsG = this.httpOptionsR();
      return this.http.get(this.uriApi + '/user-receiver?manager='+manager, httpOptionsG).pipe(map(resp => {
          return resp;
    }));
  }

  getContactsRedactors() {
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/user-redactors', httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getContactsCollaborators() {
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/user-collaborators', httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getAllCollaboratorsByRedactor(manager) {
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/user-collaborators?manager='+manager, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }


  getContactsExpID(ID) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/user-transmitters?project='+ID, httpOptionsG).pipe(map(resp => {
      return resp;
    }));
    /*
    return this.http.get(this.uriApi + '/users-used?project='+ID+'&UserWithMessage_id=1', httpOptionsG).pipe(map(resp => {
        return resp;
    }));*/
  }

  getContactsRedactorsProj(ID) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/user-redactors?project='+ID, httpOptionsG).pipe(map(resp => {
      return resp;
    }));
  }

  getContactsCollaboratorsProj(ID) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/user-collaborators?project='+ID, httpOptionsG).pipe(map(resp => {
      return resp;
    }));
  }

  getCollaboratorsByRedactor(ID, manager) {
    const httpOptionsG = this.httpOptionsR();
      return this.http.get(this.uriApi + '/user-collaborators?project='+ID+'&manager='+manager, httpOptionsG).pipe(map(resp => {
          return resp;
    }));
  }


  registerCompagny(name: any, logo: any, category: any, siret: any, adresse: any, zip: any, city: any, country: any, legalFrom: any, user: any) {
      const httpOptionsG = this.httpOptionsR();
      return this.http.post(this.uriApi + '/pub/create-company',{
        "name": name,
        //"logo": logo,
        "siret": siret,  
       "category" : category,
        "address": adresse,
        "zip": zip,
        "city": city,
        "country": country
        //"legalForm": legalFrom,
        //"user": '/api/users/'+user
      }, httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
  }

  udapteCompagny(name: any, logo: any, category: any, siret: any, adresse: any, zip: any, city: any, country: any, legalFrom: any, companie_id: any) {
      const httpOptionsG = this.httpOptionsR();
      return this.http.put(this.uriApi + '/companies/'+companie_id,{
        "name": name,
        "logo": logo,
        "siret": siret,  
        "category" : category,
        "address": adresse,
        "zip": zip,
        "city": city,
        "country": country,
        "legalForm": legalFrom
      }, httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
  }

  getCompagny(id) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/companies/'+id, httpOptionsG).pipe(map(resp => {
        return resp;
    }));
  }

  deleteCompagny(id) {
    const httpOptionsG = this.httpOptionsR();
      return this.http.delete(this.uriApi + '/companies/'+id, httpOptionsG).pipe(map(res => {
        return res;
    }));
  }

  getCompagnyMember(id: any) {
    const httpOptionsG = this.httpOptionsR(); 
    //https://api.face2faces.fr/api/users?company=4d4ddabc-a4cc-4559-8d3d-59a70b528224
    //return this.http.get(this.uriApi + '/user-companies?company='+id, httpOptionsG)
    return this.http.get(this.uriApi + '/users?company='+id, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  getCompagnyMemberNotRegister() {
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/users-invited', httpOptionsG)
    .pipe(map(resp => {
      return resp;
    })); 
  }

  addEmployeeCompany(email) {
    const httpOptionsG = this.httpOptionsR();
      return this.http.post(this.uriApi + '/users/adduser'+'?email='+email,{
        "email": email
      }, httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
  }

  addUserCompany(company_id, id_user, email) {
    const httpOptionsG = this.httpOptionsR();
    if(email != null) 
    {
      return this.http.post(this.uriApi + '/user-companies',{
        "email": id_user,
        "isProject": true,// sert a autorisé la creation de projet
        "isAdmin": false,
        "company":'/api/companies/'+company_id
      }, httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
    }
    else
    {
      return this.http.post(this.uriApi + '/user-companies',{
        "isProject": true,
        "isAdmin": false,
        "user": '/api/users/'+id_user,
        "company":'/api/companies/'+company_id
      }, httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
    }
  }

  deleteUserCompany(id_user_in_company){
    const httpOptionsG = this.httpOptionsR();
    return this.http.delete(this.uriApi + '/user-companies/'+id_user_in_company, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  getProjectHisProposal(ID: any, pageIndex: any, perPage: any, specMsg: any, specMsgType: any, searchValues: any) {
    const httpOptionsG = this.httpOptionsR();
    if(specMsg)
    {
      if(specMsgType && specMsgType == 'COMMENT')
      {
        //console.log("getProjectHisProposal type comment spec message");
        return this.http.get<any>(this.uriApi +'/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc&isActionMessage=true&orSearch_number='+specMsg, httpOptionsG);
      }
      else
      {
        return this.http.get<any>(this.uriApi +'/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc&isActionMessage=true&smartNumber='+specMsg, httpOptionsG);
      }
    }
    else if(searchValues)
    {
      
      //console.log(' searchValues me voila!!!'+JSON.stringify(searchValues));

      var param = '/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc&isActionMessage=true';

      if (searchValues.transmitter) {
        param+='&author='+searchValues.transmitter;
      }
      if (searchValues.recipient) {
        param+='&recipient='+searchValues.recipient;
      }
      if (searchValues.tag) {
        param+='&tags.id='+searchValues.tag;
      }
      

      if (searchValues.keyWord && !searchValues.type) {
        param+='&orSearch_text='+searchValues.keyWord;
      }

      if (!searchValues.keyWord && (searchValues.type == 'http')) {
        param+='&orSearch_text='+searchValues.type;
      }

      if (searchValues.keyWord && (searchValues.type == 'http')) {
        param+='&orSearch_text='+searchValues.keyWord+' '+searchValues.type;
      }


      if (searchValues.smartNumber) {
        param+='&smartNumber='+searchValues.smartNumber;
      }
      
      if ((searchValues.type == 'pdf') || (searchValues.type == 'video') || (searchValues.type == 'image') || (searchValues.type == 'excel') || (searchValues.type == 'presentation')){
        param+='&filegeneric.extension='+searchValues.type;
      }
      
      if (searchValues.statut) {
       // param+='&isMessageAnswered='+searchValues.statut;
        param+='&actionStatus='+searchValues.statut; 
      }

      if (searchValues.dateBefore) {
        var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
        param+='&dateCreated[before]='+ strDate.toLocaleString( );
      }
      if (searchValues.dateAfter) {
        var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
        param+='&dateCreated[after]='+ strDate.toLocaleString( );
      }

      return this.http.get<any>(this.uriApi + param, httpOptionsG);

    }
    else
    {
      return this.http.get<any>(this.uriApi +'/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc&isActionMessage=true', httpOptionsG);// 'motionAt' pour trie par ordre d'actualisation
    }

  }

  udapteStatusActionMessage(idMessage, statut){
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.put(this.uriApi + '/messages/' + idMessage,{
      "actionStatus": statut
    }, httpOptionsG).pipe(map(res => {
      return res;
    }));
  }

  getProjectHisReunion(ID: any, pageIndex: any, perPage: any, specMsg: any, specMsgType: any, searchValues: any) {
    const httpOptionsG = this.httpOptionsR();
    if(specMsg)
    {
      if(specMsgType && specMsgType == 'COMMENT')
      {
         return this.http.get<any>(this.uriApi +'/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc&orSearch_number='+specMsg+'&isMessageDisabled=true&isMettingMessage=true', httpOptionsG);
      }
      else
      {
        return this.http.get<any>(this.uriApi +'/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc&smartNumber='+specMsg+'&isMessageDisabled=true&isMettingMessage=true', httpOptionsG);
      }
    }
    else if(searchValues)
    {
      
      //console.log(' searchValues me voila!!!'+JSON.stringify(searchValues));

      var param = '/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc&isMessageDisabled=true&isMettingMessage=true';

      if (searchValues.transmitter) {
        param+='&author='+searchValues.transmitter;
      }
      
      if (searchValues.recipient) {
        param+='&copy.user='+searchValues.recipient;
      }

      if (searchValues.tag) {
        param+='&tags.id='+searchValues.tag;
      }
      

      if (searchValues.keyWord && !searchValues.type) {
        param+='&orSearch_text='+searchValues.keyWord;
      }

      if (!searchValues.keyWord && (searchValues.type == 'http')) {
        param+='&orSearch_text='+searchValues.type;
      }

      if (searchValues.keyWord && (searchValues.type == 'http')) {
        param+='&orSearch_text='+searchValues.keyWord+' '+searchValues.type;
      }


      if (searchValues.smartNumber) {
        param+='&smartNumber='+searchValues.smartNumber;
      }
      
      if ((searchValues.type == 'pdf') || (searchValues.type == 'video') || (searchValues.type == 'image') || (searchValues.type == 'excel') || (searchValues.type == 'presentation')){
        param+='&filegeneric.extension='+searchValues.type;
      }
      
      if (searchValues.statut) {
        param+='&isMessageAnswered='+searchValues.statut;
      }

      if (searchValues.dateBefore) {
        var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
        param+='&dateCreated[before]='+ strDate.toLocaleString( );
      }
      if (searchValues.dateAfter) {
        var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
        param+='&dateCreated[after]='+ strDate.toLocaleString( );
      }

      return this.http.get<any>(this.uriApi + param, httpOptionsG);

    }
    else
    {
      return this.http.get<any>(this.uriApi +'/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc&isMessageDisabled=true&isMettingMessage=true', httpOptionsG);
    }
    
  }


  sendReunion(
    textMessage: any,
    theTags: any,
    /*newTag: boolean,*/
    theProject: any,
    recepeant: any,
    copie: any,
    isPrivate: boolean,
    isCommentActive: boolean,
    deadline: any,
    relanceOne: any,
    relanceTwo: any,
    relanceTree: any,
    file: any, 
    action,
    isActionMessage,
    author
    ) {
     

        let formData:FormData = new FormData();
        formData.append('type', 'QUESTION');
        formData.append('text', textMessage);

        formData.append('isPrivate', 'false');
        formData.append('project',theProject);
        //console.log(file)
        if(file){
          for (var indexFile = 0; indexFile < file.length; indexFile++) {
            formData.append('filegeneric['+indexFile+'][type]','FILE');
            formData.append('filegeneric['+indexFile+'][file]',file[indexFile]);
          }
        }
        
        if (copie) {
          for (let indexCopy = 0; indexCopy < copie.length; indexCopy++) {
            // if (copie[indexCopy] != 0) {
              formData.append('copy['+indexCopy+'][project]',theProject);
              formData.append('copy['+indexCopy+'][user]','/api/users/'+copie[indexCopy]);
            // }
          }
        }
        
        formData.append('recipient','/api/users/'+recepeant);
        formData.append('author','/api/users/'+author);
         
        
        /*
        formData.append('tags[0][libelle]','');
        formData.append('tags[0][project]',theProject);
        formData.append('tags[0][tagId]','');
        */


  
        formData.append('isActionMessage','false');
        formData.append('isMettingMessage','true');
        formData.append('isMessageDisabled','true');

        formData.append('tags[0][libelle]',theTags.libelle);
        formData.append('tags[0][project]',theProject);
        formData.append('tags[0][tagId]','/api/tags/'+theTags.id);

        /*formData.append('tags[0][libelle]',(newTag == false ? theTags.libelle : theTags));
        formData.append('tags[0][project]',theProject);
        formData.append('tags[0][tagId]',(newTag == false ? '/api/tags/'+theTags.id : ''));*/

        let options = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
          })
        };
 
        return this.http.post(this.uriApi + '/questions', formData, options)
        .pipe(map(res => {
          return res;
        }));

  }

  sendCommentaireReunion(
    text: any,
    theTags: any,
    isPrivate: boolean,
    project: any,
    question: any,
    file: any
  ) {
    
 
      let formData:FormData = new FormData();
      formData.append('type', 'COMMENT');
      formData.append('text', text);
      formData.append('isPrivate',(isPrivate == true ? 'true' : 'false'));
      formData.append('project',project);
      
      for (var indexFile = 0; indexFile < file.length; indexFile++) {
        formData.append('filegeneric['+indexFile+'][type]','FILE');
        formData.append('filegeneric['+indexFile+'][file]',file[indexFile]);
      }

 
     /* formData.append('tags[0][libelle]',theTags.libelle);
      formData.append('tags[0][project]',project);
      formData.append('tags[0][tagId]',theTags.id);*/
      
      formData.append('isMettingMessage','true');
      formData.append('parentItem','/api/messages/' + question);

 
      let options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        })
      };

      return this.http.post(this.uriApi + '/comments', formData, options)
      .pipe(map(res => {
        return res;
      }));

    
  }


  getMettingNotification(notifMode, pageIndex: any, perPage: any) {
     const httpOptionsG = this.httpOptionsR();
     if (notifMode === 0) {
       return this.http.get<any>(this.uriApi+'/messages?type=QUESTION&author='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageAnswered=false&isMessageDisabled=true&page='+pageIndex+'&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false&isMettingMessage=true', httpOptionsG);
     }else if (notifMode === 1) {
       return this.http.get<any>(this.uriApi+'/messages?recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageDisabled=true&orSimple_answer=false&page='+pageIndex+'&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false&isMettingMessage=true', httpOptionsG);
     }else if (notifMode === 2) {
       return this.http.get<any>(this.uriApi+'/messages?copy.user='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&type[]=ANSWER&type[]=QUESTION&copy.isRead=false&page='+pageIndex+'&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false&isMettingMessage=true', httpOptionsG);
     } else{
      return this.http.get<any>(this.uriApi+'/messages?type=COMMENT&copy.user='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&copy.isRead=false&page='+pageIndex+'&perPage='+perPage+'&order[motionAt]=desc&isMettingMessage=true', httpOptionsG);
     }
  } 

  getAllMessagesReunion(pageIndex: any, perPage: any, searchValues: any) {
    
    const httpOptionsG = this.httpOptionsR();
    if(searchValues)
    {

      var param = '/messages?page='+(pageIndex+1)+'&type[]=QUESTION&type[]=COMMENT&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false&isMettingMessage=true';
      if (searchValues.transmitter) {
        param+='&author='+searchValues.transmitter;
      }
      if (searchValues.recipient) {
        param+='&copy.user='+searchValues.recipient;
      }
      if (searchValues.projectId) {
        param+='&project='+searchValues.projectId;
      }
      if (searchValues.tag) {
        param+='&tags.id='+searchValues.tag;
      }

      if (searchValues.keyWord && !searchValues.type) {
        param+='&orSearch_text='+searchValues.keyWord;
      }

      if (!searchValues.keyWord && (searchValues.type == 'http')) {
        param+='&orSearch_text='+searchValues.type;
      }

      if (searchValues.keyWord && (searchValues.type == 'http')) {
        param+='&orSearch_text='+searchValues.keyWord+' '+searchValues.type;
      }

      if (searchValues.smartNumber) {
        param+='&smartNumber='+searchValues.smartNumber;
      }

      if ((searchValues.type == 'pdf') || (searchValues.type == 'video') || (searchValues.type == 'image') || (searchValues.type == 'excel') || (searchValues.type == 'presentation')){
        param+='&filegeneric.extension='+searchValues.type;
      }
      
      if (searchValues.statut) {
        param+='&type=QUESTION&isMessageDisabled=false&isMessageAnswered='+searchValues.statut;
      }

      if (searchValues.dateBefore) {
        var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
        param+='&dateCreated[before]='+ strDate.toLocaleString( );
      }
      if (searchValues.dateAfter) {
        var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
        param+='&dateCreated[after]='+ strDate.toLocaleString( );
      }
      //console.log({param})
      return this.http.get<any>(this.uriApi + param, httpOptionsG);
    }
    else
    {
      return this.http.get<any>(this.uriApi + '/messages?page='+(pageIndex+1)+'&type[]=QUESTION&type[]=COMMENT&perPage='+perPage+'&order[motionAt]=desc&exists[action]=false&exists[numberAction]=false&isMettingMessage=true', httpOptionsG);
    }
  }

  addMultipEmploye(file) {

    const httpOptionsG = this.httpOptionsR();

    let formData:FormData = new FormData();
    formData.append('file', file);
    
    let options = {
      headers: new HttpHeaders({
        //'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      })
    };

    return this.http.post(this.uriApi + '/users/import', formData, options).pipe(map(res => {
      return res;
    }));

  } 


  getPorteurByProject(ID) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/user-porters?project='+ID, httpOptionsG).pipe(map(resp => {
        return resp;
    }));
  }

  getManagerByProject(ID) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/user-managers?project='+ID, httpOptionsG).pipe(map(resp => {
        return resp;
    }));
  }


  getContactsByManagerInProjet(project_id, manager_id) {
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/user-porters?project='+project_id+'&manager='+manager_id, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }


}

