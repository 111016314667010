import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {
  email = '';
  error = '';
  passIsRecover = false;
  lang  = 'fr';

  constructor( private authService: AuthService, private router: Router, private messageService: MessageService
   ) { }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });
  }

  recoverPass() {
    //console.log( 'recovering password for ' + this.email );
    this.authService.recoverPw(this.email)
      .pipe(first())
      .subscribe(
        data => {
          this.error = '';
          this.passIsRecover = true;
        },
        err => {
          const error = err.error;
          const errorS = error.message;
          this.error = error['hydra:description'];
        }
      );
  }

}
