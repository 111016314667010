

/*
 export const environment = {
    production: false,
    api_url: 'http://localhost:9090/api',
    server_url: 'http://localhost:9090',
    URL : 'http://localhost:9090/api',
    SERVER_URL : 'https://socket.facework.fr/'
 };
*/


/*
export const environment = {
   production: true,
   api_url: 'https://api.face2faces.fr/api',
   server_url: 'https://api.face2faces.fr',
   URL : 'https://api.face2faces.fr/api',
   SERVER_URL : 'https://socket.facework.fr/'
   //SERVER_URL : 'https://socket.face2faces.fr/'
 };
 */

/*
 export const environment = {
   production: true,
   api_url: 'https://api.faceaccountant.com/api',
   server_url: 'https://api.faceaccountant.com',
   URL : 'https://api.faceaccountant.com/api',
   SERVER_URL : 'https://socket.facework.fr/'
   //SERVER_URL : 'https://socket.face2faces.fr/'
 };
 */

 export const environment = {
   production: true,
   api_url: 'https://api.faceretails.com/api',
   server_url: 'https://api.faceretails.com',
   URL : 'https://api.faceretails.com/api',
   SERVER_URL : 'https://socket.facework.fr/'
   //SERVER_URL : 'https://socket.face2faces.fr/'
 };
 
 

/*
export const environment = {
   production: true,
   api_url: 'https://api.neoface.app/api',
   server_url: 'https://api.neoface.app',
   URL : 'https://api.neoface.app/api'
};
*/

