import { Component, ViewEncapsulation, Input, AfterViewInit, ElementRef, OnInit } from "@angular/core";


@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReadMoreComponent implements OnInit{

  @Input() dataLength: number;
  @Input() text: string;
  formatedText = null;
  textWithLink = null;
  public isCollapsed: boolean = true;
  public isCollapsable: boolean = false;
  
  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(){
    this.formatedText = this.truncate(this.text, this.dataLength);
    this.textWithLink = this.fullTextWithLink(this.text);
  }


  truncate(value: string, limit = 25, completeWords = false, ellipsis = '...') {

    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }

    // Finds starting and ending positions of quoted text
    // in double or single quotes with escape char support like \" \'
    var match;
    var patt = /(http?s:\/\/[^\s]+)/g;

    while (match = patt.exec(value)) {
      console.log(match.index + ' ' + patt.lastIndex);
      limit += patt.lastIndex - match.index;
    }


    let val =  value.length > limit ? value.substr(0, limit) + ellipsis : value;
    return val.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");

  }

  fullTextWithLink(value: string) {
    return value.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");
  }

}
