import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MessageService } from 'src/app/services/message/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Location } from '@angular/common';
import * as introJs from 'intro.js/intro.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'facewebapp';
  espaceProject:boolean = false;
  showTool:boolean = false;
  introJS = introJs();
  showbtn = false;

  constructor(
    location: Location,
    private translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router
  ) {

    translate.setDefaultLang('fr');

    this.matIconRegistry.addSvgIcon(
      "icon_calendar",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/calendar.svg")
    );

    router.events.subscribe((val) => {
      if(location.path().includes('project/')){
        this.espaceProject = true;
        this.showbtn = true;
      }else{
        this.espaceProject = false;
        this.showbtn = false;
      }
    });

    this.showTool = false;

    this.messageService.getShowCreateProjectTutoIsFalse().subscribe(( ) =>{
        this.showTool = this.messageService.getShowProjectTuto();
    });

    this.messageService.setCloseWriteMessage().subscribe( (val) => {
        this.showbtn = true;
    });

    this.messageService.getWriteMessageFace().subscribe( (val) => {
        this.fctEcrire();
    });

    this.messageService.getMessageIsSend().subscribe(() => {
       this.showTool = false;
       this.messageService.setShowProjectTuto(false);
    });

  }


  fctEcrire(){
    if (this.showTool == true) {
      this.messageService.setShowProjectTuto(false);
      this.showTool = false;
    }
    this.messageService.setWriteMessage(true);
    this.showbtn = false;
  }

  ngOnInit() {
    //console.log('valeur de this.show ==> '+this.showTool);
  }


}
