import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { CoreServiceService } from 'src/app/services/core/core-service.service';
import { MessageService } from 'src/app/services/message/message.service';
import {
    trigger,
    state,
    style,
    animate,
    transition,
} from '@angular/animations';
import { MatDialog } from '@angular/material';
import { DialogVideo } from 'src/app/pages/home/home.component';
/** @title Responsive sidenav */
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        trigger('hamburguerX', [
            state('hamburguer', style({})),
            state('topX', style({
                transform: 'rotate(45deg)',
                transformOrigin: 'left',
                margin: '6px',
                position: 'relative',
                top: '-5px'
            })),
            state('hide', style({
                opacity: 0
            })),
            state('bottomX', style({
                transform: 'rotate(-45deg)',
                transformOrigin: 'left',
                margin: '6px',
                width: '30px'
            })),
            transition('* => *', [
                animate('0.2s')
            ]),
        ]),
    ],
})
export class HeaderComponent implements OnInit {
    hideNav = 0;
    isHamburguer = true;
    lang = 'fr';
    isOpenMenu = false;
    constructor(private translate: TranslateService,
        private messageService: MessageService,
        public authService: AuthService,
        public dialog: MatDialog,
        private coreService: CoreServiceService) {
    }

    ngOnInit() {
        this.messageService.getTranslate()
            .subscribe(
                (data) => {
                    this.lang = data;
                }
            )
    }
    changeLang() {
        this.translate.use(this.lang);
        this.messageService.setTranslate(this.lang);
    }
    useLanguage(language: string) {
        this.translate.use(language);
        this.messageService.setTranslate(language);
    }

    logout() {
        //console.log('logging out');
        this.authService.logout();
    }

    public openSidebar() {
        this.hideNav = this.hideNav == 0 ? 1 : 0;
        this.coreService.toggleSidebar.emit(this.hideNav);
        this.isHamburguer = !this.isHamburguer;
    }
    public openNavbarmobile() {
        this.isOpenMenu = !this.isOpenMenu;
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(DialogVideo, {
          width: '700px',
          height: '450px',
          data: {}
        });
    
        dialogRef.afterClosed().subscribe(result => {
          //console.log('The dialog was closed');
        });
    }
}
